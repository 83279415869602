import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { Badge, Box, Flex, IconButton, Spacer } from "@chakra-ui/react";
import { RolodexServiceProvider, ServiceProviderType } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { useEffect, useMemo } from "react";
import { EMPTY, NOT_AVAILABLE } from "../../../../constants/common";
import { useRolodexBranchRepRelation } from "../../../../hooks/rolodexBranchRepRelation.hooks";
import { useRolodexConfiguration } from "../../../../hooks/rolodexConfiguration.hooks";
import { useServiceProviderHooks } from "../../../../hooks/rolodexServiceProvider.hooks";
import Tree from "../../../tree/Tree";
import useTree from "../../../tree/tree.hooks";

import {
  SERVICE_PROVIDER_STATUS_BG_COLOR,
  SERVICE_PROVIDER_STATUS_COLOR_TEXT
} from "../list-card/card/utils.ts/card.utils";
import {
  ServiceProviderNode,
  ServiceProviderTreeNode,
  buildTree
} from "./serviceProviderTree.utils";

type ServiceProviderTreeProps = {
  selected: RolodexServiceProvider;
  onSelectHighlighted: (node: ServiceProviderNode) => void;
  onAddClick: (node: ServiceProviderNode) => void;
  onDeleteClick: (node: ServiceProviderNode) => void;
};
export const ServiceProviderTree = (props: ServiceProviderTreeProps) => {
  const { selected, onSelectHighlighted, onAddClick, onDeleteClick } = props;
  const { serviceProviderState, getServiceProviderTreeHandler } =
    useServiceProviderHooks();
  const { branchRepState } = useRolodexBranchRepRelation();

  const initialState = useMemo(() => {
    const newTree = {
      initialState: buildTree({
        relations,
        selected,
        serviceProviderState
      })
    };
    return newTree;
  }, []);

  const { treeState, elphiTreeOperations, setTreeState } =
    useTree<ServiceProviderNode>(initialState);

  useEffect(() => {
    if (selected.id) {
      elphiTreeOperations.setSelectedNode(null);
      getServiceProviderTreeHandler({ serviceProviderId: selected.id });
    }
  }, [selected?.id]);

  useEffect(() => {
    const newTree = buildTree({
      relations,
      selected,
      serviceProviderState
    });
    setTreeState(newTree);
  }, [selected, serviceProviderState.entities, branchRepState.entities]);

  const relations = useMemo(() => {
    if (!selected) {
      return [];
    }
    const { id, type } = selected;
    const filterMap = {
      [ServiceProviderType.Company]: (x) => x?.companyId === id,
      [ServiceProviderType.Branch]: (x) => x?.branchId === id,
      [ServiceProviderType.Representative]: (x) => x?.representativeId === id
    };

    const filterFunc = filterMap[type];
    if (!filterFunc) {
      return [];
    }

    return Object.values(branchRepState.entities)
      .filter(filterFunc)
      .filter(removeEmpty)
      .map((x) => x);
  }, [selected, branchRepState.entities]);

  return (
    <Tree
      handleNodeSelect={(s) => s.data && onSelectHighlighted(s.data)}
      treeState={treeState}
      elphiTreeOperations={elphiTreeOperations}
      nodeActions={[]}
      customComponent={(node: ServiceProviderTreeNode) => {
        return (
          <TreeCard
            node={node}
            onAddClick={onAddClick}
            onDeleteClick={onDeleteClick}
          />
        );
      }}
    />
  );
};

type TreeCardProps = {
  node: ServiceProviderTreeNode;
} & Pick<ServiceProviderTreeProps, "onAddClick" | "onDeleteClick">;

const TreeCard = (props: TreeCardProps) => {
  const { node, onAddClick, onDeleteClick } = props;
  const { data } = node;
  if (!data) {
    return <></>;
  }
  const { configurationState } = useRolodexConfiguration();
  const currentDomainConf =
    configurationState?.entities[data.domainConfigurationId || EMPTY];

  return (
    <Box px={4} pt={4} pb={2}>
      <Flex overflowY={"auto"} direction={"column"}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Box fontSize={"14px"}>{node.label}</Box>
          <Spacer />
          <Box>
            <Badge
              fontSize={"12px"}
              fontWeight={"700"}
              color={SERVICE_PROVIDER_STATUS_COLOR_TEXT[data.status || EMPTY]}
              bgColor={SERVICE_PROVIDER_STATUS_BG_COLOR[data.status || EMPTY]}
            >
              {data.status || NOT_AVAILABLE}
            </Badge>
          </Box>
        </Flex>
        <Flex justifyContent={"space-between"} alignItems={"center"} mt={2}>
          <Box fontSize={"14px"}>
            {`${node?.sublabel || NOT_AVAILABLE} | ${
              currentDomainConf?.name || NOT_AVAILABLE
            }`}
          </Box>
          <Spacer />
          <Flex justifyContent={"space-between"} alignItems={"center"} ml={2}>
            {data.type == ServiceProviderType.Representative && (
              <IconButton
                onClick={() => {
                  onDeleteClick(data);
                }}
                color={"gray.500"}
                size={"md"}
                bgColor={"white"}
                aria-label={"delete-node-button"}
                icon={<DeleteIcon />}
              />
            )}
            <Spacer />
            {data.type !== ServiceProviderType.Representative && (
              <IconButton
                onClick={() => {
                  onAddClick(data);
                }}
                ml={2}
                color={"gray.500"}
                size={"md"}
                bgColor={"white"}
                aria-label="add-node-button"
                icon={<AddIcon />}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
