import { TObject, TProperties, TSchema, Type } from "@sinclair/typebox";
import {
  DealPartyRelationType,
  LoanPartyEntityRoleType,
  LoanPartyIndividualRoleType
} from "../entities";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import { ArrayField, EnumField, StringField } from "./utils.typebox";

export const BaseDealPartyRelationFieldsSchema = <TType extends TSchema>(
  type: TType
) =>
  Type.Object({
    type: type,
    dealId: StringField,
    partyId: StringField
  });

export const BaseDealPartyRelationSchema = <
  TType extends DealPartyRelationType
>(
  type: TType
) =>
  Type.Intersect([
    BaseEntitySchema,
    BaseDealPartyRelationFieldsSchema(Type.Literal(type))
  ]);

export const DealIndividualSchema = Type.Intersect([
  BaseDealPartyRelationSchema(DealPartyRelationType.DealIndividual),
  Type.Object({
    relationRoleType: ArrayField(EnumField(LoanPartyIndividualRoleType))
  })
]);
export const DealEntitySchema = Type.Intersect([
  BaseDealPartyRelationSchema(DealPartyRelationType.DealEntity),
  Type.Object({
    relationRoleType: ArrayField(EnumField(LoanPartyEntityRoleType))
  })
]);

export const DealPartyRoleSchema = Type.Union([
  DealIndividualSchema,
  DealEntitySchema
]);

export const DealIndividualUpdate = OmitUpdateSchema(DealIndividualSchema);
export const DealEntityUpdate = OmitUpdateSchema(DealEntitySchema);

export const DealIndividualCreate = OmitCreateSchema(DealIndividualSchema);
export const DealEntityCreate = OmitCreateSchema(DealEntitySchema);

export const DealPartyUpdate = Type.Union([
  DealIndividualUpdate,
  DealEntityUpdate
]);
export const getOperationDealPartyRelationSchemaFromType = (
  op: "create" | "update",
  relationType?: DealPartyRelationType
): TObject<TProperties> => {
  if (relationType === DealPartyRelationType.DealEntity) {
    return op === "create" ? DealEntityCreate : DealEntityUpdate;
  } else if (relationType === DealPartyRelationType.DealIndividual) {
    return op === "create" ? DealIndividualCreate : DealIndividualUpdate;
  } else {
    throw `invalid deal-party-relation type: "${relationType}", expected one of: ${DealPartyRelationType.DealEntity} | ${DealPartyRelationType.DealIndividual}`;
  }
};
