import { Box, Button, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useIdleTimerContext } from "react-idle-timer";
import elphiTheme from "../../../../assets/themes/elphi.theme.default";
import ModalContainer from "../../../../components/modal-container/ModalContainer";
import { EMPTY } from "../../../../constants/common";

export type ActivityIdleNotifierProps = {
  showNotifier: boolean;
  onStayActive: Function;
  onSignOut: Function;
};

const formatTime = (durationInMillis: number) => {
  const minutes = Math.floor(durationInMillis / 60000);
  const seconds = ((durationInMillis % 60000) / 1000) | 0;
  return `${minutes < 10 ? 0 : EMPTY}${minutes}:${
    seconds < 10 ? 0 : EMPTY
  }${seconds} `;
};

export const ActivityIdleNotifier = (props: ActivityIdleNotifierProps) => {
  const [remaining, setRemaining] = useState<number>(0);
  const { activate, getRemainingTime } = useIdleTimerContext();

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleOnClose = () => {
    activate();
    props.onStayActive();
  };

  const handleOnSubmit = () => {
    props.onSignOut();
  };

  return (
    <ModalContainer
      isShow={props.showNotifier}
      onCloseModal={handleOnClose}
      header={"Session Timeout"}
      body={
        <>
          <Box>
            <Text>You have been inactive for a while. For your security, </Text>
            <Text>
              we will automatically sign you out in approximately{" "}
              <Text as={"span"} fontWeight="bold">
                {formatTime(remaining)}
              </Text>{" "}
              minutes.
            </Text>
            <Text>
              Choose "Stay Signed In" to continue, or "Sign Out" if you are
              done.
            </Text>
          </Box>
        </>
      }
      footer={
        <>
          <Button
            {...elphiTheme.components.light.button.primary}
            mr={"15px"}
            onClick={handleOnClose}
          >
            Stay Signed In
          </Button>

          <Button
            {...elphiTheme.components.light.button.secondary}
            onClick={handleOnSubmit}
          >
            Sign Out
          </Button>
        </>
      }
    />
  );
};
