import { ThirdPartyAggregationField } from "@elphi/types";
import {
  EntityFormFieldSpecsHelper,
  InputBuilderFieldSpecs
} from "../fields.types";

export const getFocusedAggregationSpecs = (r: {
  focused?: "override" | "calculated" | "thirdParty" | undefined;
  spec: EntityFormFieldSpecsHelper<ThirdPartyAggregationField | undefined, any>;
}): InputBuilderFieldSpecs<any> => {
  return r.focused === "calculated" && r.spec?.calculated
    ? r.spec!.calculated!
    : r.focused === "thirdParty" && r.spec?.thirdParty
    ? r.spec!.thirdParty
    : r.spec!.override!;
};
