import {
  BasePartyRelation,
  EntityToEntityRelation,
  EntityToEntityRelationRoleType,
  EntityToIndividualRelation,
  EntityToIndividualRelationRoleType,
  PartyRelationType
} from "@elphi/types";

import { createOptionsFromEnum } from "../../../utils/formUtils";
import { FieldType } from "../../fieldFormat.types";
import { baseEntityFormFieldSpecs } from "../baseEntity.fields";
import { EntityFormFieldSpecs, SpecsBaseOmit } from "../fields.types";
import { createSpecWithFieldMeta } from "../utils/fieldMeta.utils";

export type BasePartyRelationFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<BasePartyRelation<PartyRelationType>>
>;
export type EntityToEntityRelationFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<EntityToEntityRelation>
>;

export type EntityToIndividualRelationFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<EntityToIndividualRelation>
>;

export const basePartyRelationFieldSpecs: BasePartyRelationFieldSpecs = {
  ...baseEntityFormFieldSpecs,
  type: {
    fieldKey: ["type"],
    fieldType: FieldType.SingleSelect,
    options: createOptionsFromEnum(PartyRelationType),
    label: "Party Relation Type"
  },
  parentId: {
    fieldKey: ["parentId"],
    fieldType: FieldType.String,
    label: "Parent ID"
  },
  childId: {
    fieldKey: ["childId"],
    fieldType: FieldType.String,
    label: "Child ID"
  },
  ownershipPercentage: {
    fieldKey: ["ownershipPercentage"],
    fieldType: FieldType.Percentage,
    label: "Ownership Percentage"
  }
};

export const entityToEntityRelationFieldBaseSpecs: EntityToEntityRelationFieldSpecs =
  {
    ...basePartyRelationFieldSpecs,
    relationRoleType: {
      fieldKey: ["relationRoleType"],
      fieldType: FieldType.MultiSelect,
      options: createOptionsFromEnum(EntityToEntityRelationRoleType),
      label: "Relation Roles"
    }
  };

export const entityToIndividualRelationFieldBaseSpecs: EntityToIndividualRelationFieldSpecs =
  {
    ...basePartyRelationFieldSpecs,
    relationRoleType: {
      fieldKey: ["relationRoleType"],
      fieldType: FieldType.MultiSelect,
      options: createOptionsFromEnum(EntityToIndividualRelationRoleType),
      label: "Relation Roles"
    }
  };

export const entityToEntityRelationFieldSpecs = createSpecWithFieldMeta({
  spec: entityToEntityRelationFieldBaseSpecs
});
export const entityToIndividualRelationFieldSpecs = createSpecWithFieldMeta({
  spec: entityToIndividualRelationFieldBaseSpecs
});
