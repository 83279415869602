import { DealCalendarType } from "@elphi/types";
import { Update } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { DateRange } from "../common-components/calendar/Calendar";
import { RootState, store } from "../redux/store";
import { dealCalendarApi, dealCalendarSlice } from "../redux/v2/deal-calendar";
import { CallBackActions } from "../redux/v2/types/action.types";
import { DateType } from "../shared/types/deal-calendar.types";
export const useDealCalendarHooks = () => {
  const [getDateRangeApi, getDateRangeResponse] =
    dealCalendarApi.useLazyDateRangeQuery();

  const dealCalendarState = useSelector(
    (state: RootState) => state.dealCalendar
  );

  const dates = useSelector((state: RootState) => state.dealCalendar.dates);

  const selectedCalendarViewDate = useSelector(
    (state: RootState) => state.dealCalendar.selectedCalendarViewDate
  );

  const setSelectedCalendarViewDate = ({
    month,
    year
  }: {
    month: number;
    year: number;
  }) =>
    store.dispatch(
      dealCalendarSlice.actions.setSelectedCalendarViewDate({ month, year })
    );

  const selectedDateType = useSelector(
    (state: RootState) => state.dealCalendar.selectedDateType
  );

  const setSelectedDateType = ({ dateType }: { dateType: DateType }) =>
    store.dispatch(dealCalendarSlice.actions.setSelectedDateType({ dateType }));

  const hoverDate = useSelector(
    (state: RootState) => state.dealCalendar.hoverDate
  );

  const setHoverDate = ({ hoverDate }: { hoverDate?: Date }) =>
    store.dispatch(dealCalendarSlice.actions.setHoverDate({ hoverDate }));

  const isToolTipLoaded = useSelector(
    (state: RootState) => state.dealCalendar.isToolTipLoaded
  );

  const setIsToolTipLoaded = ({
    isToolTipLoaded
  }: {
    isToolTipLoaded?: boolean;
  }) =>
    store.dispatch(
      dealCalendarSlice.actions.setIsToolTipLoaded({ isToolTipLoaded })
    );

  const enableCloseClickOutsideSidebar = useSelector(
    (state: RootState) => state.dealCalendar.enableCloseClickOutsideSidebar
  );

  const setEnableCloseClickOutsideSidebar = (
    enableCloseClickOutsideSidebar?: boolean
  ) =>
    store.dispatch(
      dealCalendarSlice.actions.setEnableCloseClickOutsideSidebar({
        enableCloseClickOutsideSidebar
      })
    );

  const sideBarDealCalendar = useSelector(
    (state: RootState) => state.dealCalendar.sideBarDealCalendar
  );

  const setSideBarDealCalendar = (params?: {
    dealCalendarId?: string;
    date?: Date;
  }) =>
    store.dispatch(dealCalendarSlice.actions.setSideBarDealCalendar(params));

  const calendarCurrentViewDates = useSelector(
    (state: RootState) => state.dealCalendar.calendarCurrentViewDates
  );

  const setCalendarCurrentViewDates = ({ startDate, endDate }: DateRange) =>
    store.dispatch(
      dealCalendarSlice.actions.setCalendarCurrentViewDates({
        startDate,
        endDate
      })
    );

  const dealCalendarCallBack: CallBackActions<DealCalendarType> = {
    add: (dealCalendars: DealCalendarType[]) => {
      store.dispatch(
        dealCalendarSlice.actions.updateDates({
          updatedDealCalendars: dealCalendars as DealCalendarType[]
        })
      );
    },
    update: (dc: Update<DealCalendarType>[]) => {
      const dealCalendars = dc.map((item) => item.changes);
      store.dispatch(
        dealCalendarSlice.actions.updateDates({
          updatedDealCalendars: dealCalendars as DealCalendarType[]
        })
      );
    }
  };

  return {
    getDateRangeApi,
    getDateRangeResponse,
    dates,
    sideBarDealCalendar,
    setSideBarDealCalendar,
    isToolTipLoaded,
    setIsToolTipLoaded,
    dealCalendarState,
    selectedCalendarViewDate,
    setSelectedCalendarViewDate,
    selectedDateType,
    setSelectedDateType,
    calendarCurrentViewDates,
    setCalendarCurrentViewDates,
    dealCalendarCallBack,
    hoverDate,
    setHoverDate,
    enableCloseClickOutsideSidebar,
    setEnableCloseClickOutsideSidebar
  };
};
