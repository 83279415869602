import { Statement } from "@elphi/types";
import {
  OnChangeInput,
  Section
} from "../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../components/form-builder/field-specs/fields.types";
import { buildSection } from "../../utils/formBuilder.utils";
import {
  percentConsidered,
  statementBalance,
  statementEnd,
  statementStart,
  unverifiedDeposits
} from "../inputs/statement.inputs";

const createStatementSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Statement>;
    onChange: (v: OnChangeInput) => void;
    options: {
      header?: string;
      hideAttachedComponent: boolean;
    };
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: r.options.header || "",
      inputs: [
        statementStart,
        statementEnd,
        statementBalance,
        unverifiedDeposits,
        percentConsidered
      ],
      hideAttachedComponent: r.options.hideAttachedComponent,
      fieldSpecs
    });

export const statementSectionBuilders = { createStatementSection };
