import { AggregationType } from "@elphi/types";
import {
  booleanValidation,
  dateValidation,
  emailValidation,
  moneyValidation,
  multiSelectValidation,
  numberValidation,
  percentValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { baseElphiFieldsSpecs } from "../spec-files/baseElphiFieldsSpecs";

export const loanProgramType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.LoanProgramType?.focused
      ?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.ThirdParty
});

export const loanPurposeType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LoanPurposeType.fieldKey,
  validation: singleSelectValidation
});

export const loanName = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LoanName.fieldKey,
  validation: singleSelectValidation
});

export const portfolioLoanIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PortfolioLoanIndicator.fieldKey,
  validation: booleanValidation
});

export const estimatedClosingDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.EstimatedClosingDate
      ?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.ThirdParty
});

export const channel = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.Channel.fieldKey,
  validation: singleSelectValidation
});

export const employeeLoanIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.EmployeeLoanIndicator.fieldKey,
  validation: booleanValidation
});

export const chargentTransactionIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ChargentTransactionIdentifier
      ?.fieldKey,
  validation: stringValidation
});

export const chargentGatewayIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ChargentGatewayIdentifier?.fieldKey,
  validation: stringValidation
});

export const chargentCollectionAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ChargentCollectionAmount?.fieldKey,
  validation: moneyValidation
});

export const valuationFeesCollectedBySalesIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .ValuationFeesCollectedBySalesIndicator?.fieldKey,
  validation: booleanValidation
});

export const appraisalNotes = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.AppraisalNotes?.fieldKey,
  validation: stringValidation
});

export const crmId = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.CRMId.fieldKey,
  validation: stringValidation
});

export const operationsDepartment = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.OperationsDepartment.fieldKey,
  validation: singleSelectValidation
});

export const lenderIdentifier = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LenderIdentifier.fieldKey,
  validation: singleSelectValidation
});

export const totalPurchasePriceAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalPurchasePriceAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalBudgetAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalBudgetAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalAsIsAppraisedValueAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalAsIsAppraisedValueAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalSubjectToAppraisedValueAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalSubjectToAppraisedValueAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalLTVRatePercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalLTVRatePercent
      ?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.Aggregation
});

export const totalLTARVRatePercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalLTARVRatePercent
      ?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.Aggregation
});

export const totalTaxAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalTaxAmount?.focused
      ?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalHOAFeesAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalHOAFeesAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalPropertyInsurancePremiumAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalPropertyInsurancePremiumAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalFloodInsurancePremiumAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalFloodInsurancePremiumAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalCostAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalCostAmount?.focused
      ?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const requestedLoanAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.RequestedLoanAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const initialLTVRatePercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.InitialLTVRatePercent?.fieldKey,
  validation: percentValidation
});

export const totalOutstandingLoanPayoffAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalOutstandingLoanPayoffAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const initialAdvancedFundAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.InitialAdvancedFundAmount.fieldKey,
  validation: moneyValidation
});

export const totalLoanFeesAndClosingCostAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TotalLoanFeesAndClosingCostAmount
      .fieldKey,
  validation: moneyValidation
});

export const debtServiceCoverageRatioPercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .DebtServiceCoverageRatioPercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const totalValuationOrPurchasePriceAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .TotalValuationOrPurchasePriceAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const lesserOfLotOrPurchase = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .LesserOfLotOrPurchase?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalNumberOfProperties = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalNumberOfProperties
      ?.focused?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.Aggregation
});

export const totalAdjustedMonthlyRentAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalAdjustedMonthlyRentAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalAnnualCapitalExpenditure = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalAnnualCapitalExpenditure?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const monthlyPaymentOfCapitalExpenditure = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .MonthlyPaymentOfCapitalExpenditure?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const cashFromBorrowerAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CashFromBorrowerAmount.fieldKey,
  validation: moneyValidation
});

export const cashToBorrowerAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CashToBorrowerAmount.fieldKey,
  validation: moneyValidation
});

export const loanProductType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.LoanProductType?.focused
      ?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.ThirdParty
});

export const amortizationType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.AmortizationType.fieldKey,
  validation: singleSelectValidation
});

export const noteRatePercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.NoteRatePercent
      ?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const rateLockStartDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.RateLockStartDate.fieldKey,
  validation: dateValidation
});

export const rateLockEndDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.RateLockEndDate.fieldKey,
  validation: dateValidation
});

export const wireReleaseDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.WireReleaseDate.fieldKey,
  validation: dateValidation
});

export const scheduledFirstPaymentDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .ScheduledFirstPaymentDate?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.Aggregation
});

export const paymentDueDay = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.PaymentDueDay.fieldKey,
  validation: singleSelectValidation
});

export const lastPaymentDueDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LastPaymentDueDate.fieldKey,
  validation: dateValidation
});

export const lastDayOfClosingMonth = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.LastDayOfClosingMonth
      ?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.Aggregation
});

export const loanMaturityDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.LoanMaturityDate
      ?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.Aggregation
});

export const loanTermPeriodMonthCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanTermPeriodMonthCount.fieldKey,
  validation: numberValidation
});

export const loanAmortizationPeriodMonthCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanAmortizationPeriodMonthCount
      .fieldKey,
  validation: numberValidation
});

export const loanInterestOnlyPeriodMonthCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanInterestOnlyPeriodMonthCount
      .fieldKey,
  validation: numberValidation
});

export const loanAfterInterestOnlyPeriodMonthCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanAfterInterestOnlyPeriodMonthCount
      .fieldKey,
  validation: numberValidation
});

export const interestAccrualMethodType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.InterestAccrualMethodType.fieldKey,
  validation: singleSelectValidation
});

export const loanPaymentType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LoanPaymentType.fieldKey,
  validation: singleSelectValidation
});

export const totalMonthlyPITIPaymentAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .TotalMonthlyPITIPaymentAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const monthlyPrincipalInterestPaymentAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .MonthlyPrincipalInterestPaymentAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const initialMonthlyInterestOnlyPaymentAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .InitialMonthlyInterestOnlyPaymentAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const fullyDrawnInterestOnlyPayment = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .FullyDrawnInterestOnlyPayment?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const monthlyTaxInsurancePaymentAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .MonthlyTaxInsurancePaymentAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const monthlyHomeownersAssociationAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .MonthlyHomeownersAssociationAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const executionType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.ExecutionType.fieldKey,
  validation: singleSelectValidation
});

export const prepaymentPenaltyType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .PrepaymentPenaltyType?.focused?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.ThirdParty
});

export const prepaymentPenaltyExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .PrepaymentPenaltyExpirationDate?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.Aggregation
});

export const recourseType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.RecourseType.fieldKey,
  validation: singleSelectValidation
});

export const firstRateChangeDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.FirstRateChangeDate
      ?.focused?.fieldKey,
  validation: dateValidation,
  calculationType: AggregationType.Aggregation
});

export const indexSourceType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.IndexSourceType.fieldKey,
  validation: singleSelectValidation
});

export const capitalStructureType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CapitalStructureType.fieldKey,
  validation: singleSelectValidation
});

export const armRepriceRoundingRatePercentType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ARMRepriceRoundingRatePercentType
      .fieldKey,
  validation: singleSelectValidation
});

export const armRepriceRoundingMethodType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ARMRepriceRoundingMethodType
      .fieldKey,
  validation: singleSelectValidation
});

export const armRepriceLookbackType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ARMRepriceLookbackType.fieldKey,
  validation: singleSelectValidation
});

export const armInitialFixedTermMonthCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .ARMInitialFixedTermMonthCount?.focused?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.Aggregation
});

export const armFinalAdjustableRateTermMonthCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .ARMFinalAdjustableRateTermMonthCount?.focused?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.Aggregation
});

export const armRepriceFrequencyType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.ARMRepriceFrequencyType
      ?.focused?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.Aggregation
});

export const margin = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.Margin?.focused
      ?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.ThirdParty
});

export const floorPercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.FloorPercent
      ?.focused?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.ThirdParty
});

export const lifetimeCapPercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.LifetimeCapPercent
      ?.focused?.fieldKey,
  validation: singleSelectValidation,
  calculationType: AggregationType.ThirdParty
});
export const uccFiledIndicator = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.UCCFiledIndicator.fieldKey,
  validation: booleanValidation
});

export const uccExpirationDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.UCCExpirationDate.fieldKey,
  validation: dateValidation
});

export const lateFeePercent = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LateFeePercent.fieldKey,
  validation: percentValidation
});

export const grossSpreadPercent = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.GrossSpreadPercent.fieldKey,
  validation: percentValidation
});

export const letterOfIntentSignedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LetterOfIntentSignedDate.fieldKey,
  validation: dateValidation
});

export const propertyManagementFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyManagement.FullName.fieldKey,
  validation: stringValidation
});

export const propertyManagementRepresentativeFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyManagement
      .RepresentativeFullName.fieldKey,
  validation: stringValidation
});

export const propertyManagementFullAddressName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyManagement.FullAddressName
      .fieldKey,
  validation: stringValidation
});

export const propertyManagementContactPointTelephoneValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyManagement
      .ContactPointTelephoneValue.fieldKey,
  validation: stringValidation
});

export const propertyManagementContactPointEmailValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyManagement
      .ContactPointEmailValue.fieldKey,
  validation: emailValidation
});

export const propertyManagementComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyManagement
      .PropertyManagementComments.fieldKey,
  validation: stringValidation
});

export const underwriterApprovalStatus = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.UnderwriterApprovalStatus.fieldKey,
  validation: singleSelectValidation
});

export const underwriterApprovalComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.UnderwriterApprovalComments.fieldKey,
  validation: stringValidation
});

export const programDirectorApprovalIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ProgramDirectorApprovalIndicator
      .fieldKey,
  validation: stringValidation
});

export const programDirectorComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ProgramDirectorComments.fieldKey,
  validation: stringValidation
});

export const submissionNotes = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.SubmissionNotes.fieldKey,
  validation: stringValidation
});

export const creditMemoDraftedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CreditReviewCommittee
      .creditMemoDraftedDate.fieldKey,
  validation: dateValidation
});

export const creditMemoApprovedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CreditReviewCommittee
      .creditMemoApprovedDate.fieldKey,
  validation: dateValidation
});

export const internalSubmissionDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CreditReviewCommittee
      .internalSubmissionDate.fieldKey,
  validation: dateValidation
});

export const internalApprovalDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CreditReviewCommittee
      .internalApprovalDate.fieldKey,
  validation: dateValidation
});

export const externalSubmissionDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CreditReviewCommittee
      .externalSubmissionDate.fieldKey,
  validation: dateValidation
});

export const externalApprovalDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CreditReviewCommittee
      .externalApprovalDate.fieldKey,
  validation: dateValidation
});

export const lOIIssuedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CreditReviewCommittee.lOIIssuedDate
      .fieldKey,
  validation: dateValidation
});

export const lOIAcceptedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CreditReviewCommittee.lOIAcceptedDate
      .fieldKey,
  validation: dateValidation
});

export const quoteId = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.QuoteId?.focused
      ?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const validationErrors = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.ValidationErrors
      ?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const ltvEligibility = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.LtvEligibility
      ?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineWarnings = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineWarnings?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineOverrides = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineOverrides?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const feeLLPAs = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.FeeLLPAs?.focused
      ?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const rateLLPAs = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.RateLLPAs?.focused
      ?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const internalL1CRefinanceIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .InternalL1CRefinanceIndicator?.focused?.fieldKey,
  validation: booleanValidation,
  calculationType: AggregationType.Aggregation
});

export const limaRateToBorrowerPercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .LimaRateToBorrowerPercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const limaOriginationFeeToBorrowerPercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .LimaOriginationFeeToBorrowerPercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineTotalLoanToAfterRepairValueOverridePercent =
  buildInput({
    fieldKey:
      baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
        .PricingEngineTotalLoanToAfterRepairValueOverridePercent?.focused
        ?.fieldKey,
    validation: percentValidation,
    calculationType: AggregationType.ThirdParty
  });

export const pricingEngineTotalBlendedLoanToCostOverridePercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineTotalBlendedLoanToCostOverridePercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineTotalPurchaseLoanToCostOverridePercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineTotalPurchaseLoanToCostOverridePercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineNoteRateOverridePercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineNoteRateOverridePercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineMaxLoanToAfterRepairValuePercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineMaxLoanToAfterRepairValuePercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineMaxBlendedLoanToCostPercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineMaxBlendedLoanToCostPercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const pricingEngineMaxPurchaseLoanToCostPercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .PricingEngineMaxPurchaseLoanToCostPercent?.focused?.fieldKey,
  validation: percentValidation,
  calculationType: AggregationType.ThirdParty
});

export const blendedLTCAdjuster = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.BlendedLTCAdjuster
      ?.focused?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.ThirdParty
});

export const purchaseLTCAdjuster = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .PurchaseLTCAdjuster?.focused?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.ThirdParty
});

export const arvAdjuster = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.ARVAdjuster
      ?.focused?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.ThirdParty
});

export const interestReserveEscrowOverrideMonthCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .InterestReserveEscrowOverrideMonthCount?.fieldKey,
  validation: numberValidation
});

export const interestReserveEscrowOverrideAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.InterestReserveEscrowOverrideAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const processingFeeOverride = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ProcessingFeeOverride?.fieldKey,
  validation: moneyValidation
});

export const exceptionType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Exceptions.ExceptionType?.fieldKey,
  validation: multiSelectValidation
});

export const policySection = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Exceptions.PolicySection?.fieldKey,
  validation: multiSelectValidation
});

export const exceptionCommentary = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Exceptions.ExceptionCommentary
      ?.fieldKey,
  validation: stringValidation
});

export const exceptionApprovedBy = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Exceptions.ExceptionApprovedBy
      ?.fieldKey,
  validation: singleSelectValidation
});

export const exitStrategy = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.ExitStrategy?.fieldKey,
  validation: stringValidation
});

export const targetCloseDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.TargetCloseDate?.fieldKey,
  validation: dateValidation
});
export const applicationRequestedLoanAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ApplicationRequestedLoanAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const projectAnticipatedCompletionTimeline = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ProjectAnticipatedCompletionTimeline
      ?.fieldKey,
  validation: stringValidation
});

export const constructionBudgetRemainingAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ConstructionBudgetRemainingAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const verifiableConstructionCompletedAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.VerifiableConstructionCompletedAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const lienDollarAmount = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LienDollarAmount?.fieldKey,
  validation: moneyValidation
});

export const propertyTypeChangingIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyTypeChangingIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const propertyZoningChangesIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyZoningChangesIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const lenderPropertyInsuranceProviderIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .LenderPropertyInsuranceProviderIndicator?.fieldKey,
  validation: booleanValidation
});

export const lenderTitleProviderIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LenderTitleProviderIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const hoaContactName = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.HOAContactName?.fieldKey,
  validation: stringValidation
});

export const hoaContactPhone = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.HOAContactPhone?.fieldKey,
  validation: stringValidation
});

export const hoaContactEmail = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.HOAContactEmail?.fieldKey,
  validation: emailValidation
});

export const occupancy = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.Occupancy?.fieldKey,
  validation: percentValidation
});

export const subdividePropertyPartialReleasesIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .SubdividePropertyPartialReleasesIndicator?.fieldKey,
  validation: booleanValidation
});

export const propertyRentReadyConditionIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyRentReadyConditionIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const propertyLeasePurchaseOptionIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyLeasePurchaseOptionIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const occupationIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.OccupationIndicator?.fieldKey,
  validation: booleanValidation
});

export const sellerRelationshipIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.SellerRelationshipIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const associatedPropertiesDescription = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.AssociatedPropertiesDescription
      ?.fieldKey,
  validation: stringValidation
});

export const developmentStrategy = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.DevelopmentStrategy?.fieldKey,
  validation: stringValidation
});

export const lotStatus = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.LotStatus?.fieldKey,
  validation: stringValidation
});

export const currentLotMarketValueAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.CurrentLotMarketValueAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const lotZonedParcelIdIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LotZonedParcelIdIndicator?.fieldKey,
  validation: booleanValidation
});

export const buildingPermitsIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.BuildingPermitsIndicator?.fieldKey,
  validation: booleanValidation
});

export const buildingPermitsExpectedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.BuildingPermitsExpectedDate
      ?.fieldKey,
  validation: dateValidation
});

export const lotUtilitiesIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LotUtilitiesIndicator?.fieldKey,
  validation: booleanValidation
});

export const buildingEnvelopeExpandedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.BuildingEnvelopeExpandedIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const loadBearingWallsRemoveIntendedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .LoadBearingWallsRemoveIntendedIndicator?.fieldKey,
  validation: booleanValidation
});

export const squareFootageAddedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.SquareFootageAddedIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const titleInsuranceUnderwriter = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany
      .TitleInsuranceUnderwriter?.fieldKey,
  validation: stringValidation
});

export const titleCompanyName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.FullName?.fieldKey,
  validation: stringValidation
});

export const titleCompanyClosingAgentFirstName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.ClosingAgentFirstName
      ?.fieldKey,
  validation: stringValidation
});

export const titleCompanyClosingAgentLastName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.ClosingAgentLastName
      ?.fieldKey,
  validation: stringValidation
});

export const titleCompanyClosingAgentContactPointTelephone = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany
      .ClosingAgentContactPointTelephoneValue?.fieldKey,
  validation: stringValidation
});

export const titleCompanyClosingAgentContactPointEmail = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany
      .ClosingAgentContactPointEmailValue?.fieldKey,
  validation: emailValidation
});

export const titleCompanyAddressLineText = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.AddressLineText
      ?.fieldKey,
  validation: stringValidation
});

export const titleCompanyAddressUnitIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.AddressUnitIdentifier
      ?.fieldKey,
  validation: stringValidation
});

export const titleCompanyCityName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.CityName?.fieldKey,
  validation: stringValidation
});

export const titleCompanyStateCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.StateCode?.fieldKey,
  validation: singleSelectValidation
});

export const titleCompanyPostalCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.PostalCode?.fieldKey,
  validation: stringValidation
});

export const generalContractorFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.GeneralContractor.FullName?.fieldKey,
  validation: stringValidation
});

export const generalContractorRepresentativeFirstName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.GeneralContractor
      .RepresentativeFirstName?.fieldKey,
  validation: stringValidation
});

export const generalContractorRepresentativeLastName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.GeneralContractor
      .RepresentativeLastName?.fieldKey,
  validation: stringValidation
});

export const generalContractorContactPointTelephone = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.GeneralContractor
      .ContactPointTelephoneValue?.fieldKey,
  validation: stringValidation
});

export const generalContractorContactPointEmail = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.GeneralContractor
      .ContactPointEmailValue?.fieldKey,
  validation: emailValidation
});

export const brokerRepresentativeFirstName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Broker.RepresentativeFirstName
      .fieldKey,
  validation: stringValidation
});

export const brokerRepresentativeLastName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Broker.RepresentativeLastName
      .fieldKey,
  validation: stringValidation
});

export const brokerFullName = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.Broker.FullName.fieldKey,
  validation: stringValidation
});

export const brokerContactPointEmailValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Broker.ContactPointEmailValue
      .fieldKey,
  validation: emailValidation
});

export const brokerContactPointTelephone = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Broker.ContactPointTelephoneValue
      .fieldKey,
  validation: stringValidation
});

export const nmlsIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Broker.NMLSIdentifier.fieldKey,
  validation: stringValidation
});

export const brokerWiringInstructionsIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Broker
      .BrokerWiringInstructionsIndicator.fieldKey,
  validation: booleanValidation
});

export const lenderFinancePartnerFirstName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LenderFinance.PartnerFirstName
      .fieldKey,
  validation: stringValidation
});

export const lenderFinancePartnerLastName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LenderFinance.PartnerLastName
      .fieldKey,
  validation: stringValidation
});

export const lenderFinancePartnerInitialTermSheetApprovalStatusIndicator =
  buildInput({
    fieldKey:
      baseElphiFieldsSpecs.deal?.entitySpecs.LenderFinance
        .PartnerInitialTermSheetApprovalStatusIndicator.fieldKey,
    validation: booleanValidation
  });

export const lenderFinancePartnerInitialTermSheetApprovalStatusDate =
  buildInput({
    fieldKey:
      baseElphiFieldsSpecs.deal?.entitySpecs.LenderFinance
        .PartnerInitialTermSheetApprovalStatusDate.fieldKey,
    validation: dateValidation
  });

export const lenderFinancePartnerFinalTermSheetApprovalStatusDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LenderFinance
      .PartnerFinalTermSheetApprovalStatusDate.fieldKey,
  validation: dateValidation
});

export const lenderFinanceBorrowerFinalTermSheetApprovalStatusDate = buildInput(
  {
    fieldKey:
      baseElphiFieldsSpecs.deal?.entitySpecs.LenderFinance
        .BorrowerFinalTermSheetApprovalStatusDate.fieldKey,
    validation: dateValidation
  }
);

export const defaultInterestRatePercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.DefaultInterestRatePercent.fieldKey,
  validation: percentValidation
});

export const releasePercent = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.ReleasePercent.fieldKey,
  validation: percentValidation
});

export const gracePeriodDays = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.GracePeriodDays.fieldKey,
  validation: numberValidation
});

export const downPaymentAmount = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.DownPaymentAmount.fieldKey,
  validation: moneyValidation
});

export const originationFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .OriginationFeeAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const deferredOriginationFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .DeferredOriginationFeeAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const creditReportFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Ledger.CreditReportFeeAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const processingFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .ProcessingFeeAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const lenderCounselFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Ledger.LenderCounselFeeAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const buyDownFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.BuyDownFeeAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const buyUpFeeReductionAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .BuyUpFeeReductionAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const brokerOriginationFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .BrokerOriginationFeeAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const brokerProcessingFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .BrokerProcessingFeeAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const totalBrokerFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.TotalBrokerFeeAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const contractorReviewFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Ledger.ContractorReviewFeeAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const feasibilityReviewFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Ledger.FeasibilityReviewFeeAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const condoCertificationFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Ledger.CondoCertificationFeeAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const interestReserveEscrow = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .InterestReserveEscrow?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const newYorkProcessingFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.Ledger.NewYorkProcessingFeeAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const prepaidInterestAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.PrepaidInterestAmount
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const perDiemPrepaidInterestAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .PerDiemPrepaidInterestAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const servicingSetupFee = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.ServicingSetupFee
      ?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const marketingPromotionCreditAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.MarketingPromotionCreditAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const marketingPromotionCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.MarketingPromotionCode?.fieldKey,
  validation: stringValidation
});

export const lenderFinanceProcessingFee = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .LenderFinanceProcessingFee?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const lenderFinanceApplicationFee = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .LenderFinanceApplicationFee?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const lenderFinanceDocumentFee = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .LenderFinanceDocumentFee?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const lenderFinanceAppraisalFee = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .LenderFinanceAppraisalFee?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.ThirdParty
});

export const propertyInsurancePremiumPaymentType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyInsurancePremiumPaymentType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const totalPropertyInsurancePremiumOutstandingAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalPropertyInsurancePremiumOutstandingAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const propertyInsuranceAgencyFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PropertyInsuranceAgency.FullName
      ?.fieldKey,
  validation: stringValidation
});

export const floodInsurancePremiumPaymentType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.FloodInsurancePremiumPaymentType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const totalFloodInsurancePremiumOutstandingAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalFloodInsurancePremiumOutstandingAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const floodInsuranceAgencyFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.FloodInsuranceAgency.FullName
      ?.fieldKey,
  validation: stringValidation
});

export const totalInitialInsuranceEscrowAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalInitialInsuranceEscrowAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const totalInitialTaxEscrowAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations
      .TotalInitialTaxEscrowAmount?.focused?.fieldKey,
  validation: moneyValidation,
  calculationType: AggregationType.Aggregation
});

export const interestReserveDepositAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.InterestReserveDepositAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const deferredMaintenanceAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.DeferredMaintenanceAmount?.fieldKey,
  validation: moneyValidation
});

export const titleCommitmentTitleEffectiveDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment.TitleEffectiveDate
      ?.fieldKey,
  validation: dateValidation
});
export const titleCommitmentTitleExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment.TitleExpirationDate
      ?.fieldKey,
  validation: dateValidation
});

export const titleCommitmentOwnership = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment
      .PropertyRightsOwnershipType?.fieldKey,
  validation: singleSelectValidation
});

export const titleCommitmentTitlePolicyAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment.TitlePolicyAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const titleCommitmentTitleVestingType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment.TitleVestingType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const titleCommitmentAllSubjectPropertiesIncludedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment
      .AllSubjectPropertiesIncludedIndicator?.fieldKey,
  validation: booleanValidation
});

export const titleCommitmentAllPayoffsLiensIncludedInSection1RequirementsIndicator =
  buildInput({
    fieldKey:
      baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment
        .AllPayoffsLiensIncludedInSection1RequirementsIndicator?.fieldKey,
    validation: booleanValidation
  });

export const titleCommitmentRequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator =
  buildInput({
    fieldKey:
      baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment
        .RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator?.fieldKey,
    validation: booleanValidation
  });

export const titleCommitmentTitleInternationalExecutionIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment
      .TitleInternationalExecutionIndicator?.fieldKey,
  validation: booleanValidation
});

export const titleCommitmentLeaseholdEndorsementIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment
      .LeaseholdEndorsementIndicator?.fieldKey,
  validation: booleanValidation
});

export const titleCommitmentComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCommitment
      .TitleCommitmentComments?.fieldKey,
  validation: stringValidation
});

export const totalLiquidAssetAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TotalLiquidAssetAmount?.fieldKey,
  validation: moneyValidation
});

export const settlementStatementMatchLedgerIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .SettlementStatementMatchLedgerIndicator?.fieldKey,
  validation: booleanValidation
});

export const loanAmountVerifiedMatchIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanAmountVerifiedMatchIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const constructionBudgetVerifiedMatchIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .ConstructionBudgetVerifiedMatchIndicator?.fieldKey,
  validation: booleanValidation
});

export const settlementStatementComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.SettlementStatementComments
      ?.fieldKey,
  validation: stringValidation
});

export const eoExpirationDate = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.EOExpirationDate?.fieldKey,
  validation: dateValidation
});

export const eoNamedInsuredMatchIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.EONamedInsuredMatchIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const wireABARoutingNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.WireABARoutingNumber?.fieldKey,
  validation: stringValidation
});

export const wireAccountNumber = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.WireAccountNumber?.fieldKey,
  validation: stringValidation
});

export const wireBankName = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.WireBankName?.fieldKey,
  validation: stringValidation
});

export const wireAccountName = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.WireAccountName?.fieldKey,
  validation: stringValidation
});

export const wireInsuranceMatchDealIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.WireInsuranceMatchDealIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const titleFormsComments = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.TitleFormsComments?.fieldKey,
  validation: stringValidation
});

export const netWireAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.aggregations.NetWireAmount?.focused
      ?.fieldKey,
  calculationType: AggregationType.Aggregation,
  validation: moneyValidation
});

export const warehouseBankType = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.WarehouseBankType?.fieldKey,
  validation: singleSelectValidation
});

export const warehouseBankStatusType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.WarehouseBankStatusType?.fieldKey,
  validation: singleSelectValidation
});

export const fundingShieldStatusType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.FundingShieldStatusType?.fieldKey,
  validation: singleSelectValidation
});

export const haircutAmount = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.HaircutAmount?.fieldKey,
  validation: moneyValidation
});

export const postClosingNotes = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.PostClosingNotes?.fieldKey,
  validation: stringValidation
});

export const mailAwayIndicator = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.mailAwayIndicator?.fieldKey,
  validation: booleanValidation
});

export const closingAgentFirstName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.ClosingAgentFirstName
      ?.fieldKey,
  validation: stringValidation
});

export const closingAgentLastName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitleCompany.ClosingAgentLastName
      ?.fieldKey,
  validation: stringValidation
});

export const promissoryNoteComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PromissoryNoteComments?.fieldKey,
  validation: stringValidation
});

export const shippingCompanyType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ShippingCompanyType?.fieldKey,
  validation: singleSelectValidation
});

export const settlementStatementFileNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.SettlementStatementFileNumber
      ?.fieldKey,
  validation: stringValidation
});

export const personalGuarantorsCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PersonalGuarantorsCount?.fieldKey,
  validation: numberValidation
});

export const powerOfAttorneyFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PowerOfAttorneyFullName?.fieldKey,
  validation: stringValidation
});

export const loanPackageNoteTrackingNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanPackageNoteTrackingNumber
      ?.fieldKey,
  validation: stringValidation
});

export const promissoryNoteRequestedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PromissoryNoteRequestedIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const promissoryNoteShippedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PromissoryNoteShippedIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const promissoryNoteTrackingNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PromissoryNoteTrackingNumber
      ?.fieldKey,
  validation: stringValidation
});

export const allongeAssignmentSentToWarehouseIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .AllongeAssignmentSentToWarehouseIndicator?.fieldKey,
  validation: booleanValidation
});

export const allongeAssignmentTrackingNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.AllongeAssignmentTrackingNumber
      ?.fieldKey,
  validation: stringValidation
});

export const loanPackageTrackingNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanPackageTrackingNumber?.fieldKey,
  validation: stringValidation
});

export const fundingAndDisbursementApprovalIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .FundingAndDisbursementApprovalIndicator?.fieldKey,
  validation: booleanValidation
});

export const loanPackageInternationalExecutionIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .LoanPackageInternationalExecutionIndicator?.fieldKey,
  validation: booleanValidation
});

export const achElectedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ACHElectedIndicator?.fieldKey,
  validation: booleanValidation
});

export const achRequiredIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations
      .AchRequiredIndicator?.focused?.fieldKey,
  validation: booleanValidation,
  calculationType: AggregationType.ThirdParty
});

export const achExemptionStatus = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.quote.aggregations.AchExemptionStatus
      ?.focused?.fieldKey,
  validation: booleanValidation,
  calculationType: AggregationType.ThirdParty
});

export const sellerHUDAddedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.SellerHUDAddedIndicator?.fieldKey,
  validation: booleanValidation
});

export const purchaserWarrantyDeedAddedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.PurchaserWarrantyDeedAddedIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const fundingAgentEmailAddress = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.fundingAgentEmailAddress?.fieldKey,
  validation: emailValidation
});

export const loanPackageNoteReceivedByLenderIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs
      .LoanPackageNoteReceivedByLenderIndicator?.fieldKey,
  validation: booleanValidation
});

export const loanPackageChecksReceivedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanPackageChecksReceivedIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const loanPackageCheckNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanPackageCheckNumber?.fieldKey,
  validation: stringValidation
});

export const loanPackageCheckAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.LoanPackageCheckAmount?.fieldKey,
  validation: moneyValidation
});

export const recordedMortgageReceivedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.RecordedMortgageReceivedDate
      ?.fieldKey,
  validation: dateValidation
});

export const titlePolicyReceivedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.TitlePolicyReceivedDate?.fieldKey,
  validation: dateValidation
});

export const recordedWarrantyDeedReceivedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.RecordedWarrantyDeedReceivedDate
      ?.fieldKey,
  validation: dateValidation
});

export const notaryExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.NotaryExpirationDate?.fieldKey,
  validation: dateValidation
});

export const closingAnalystUserId = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.deal?.entitySpecs.ClosingAnalystUserId?.fieldKey,
  validation: stringValidation
});

export const notaryUserId = buildInput({
  fieldKey: baseElphiFieldsSpecs.deal?.entitySpecs.NotaryUserId?.fieldKey,
  validation: stringValidation
});
