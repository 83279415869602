// Need to use the React-specific entry point to allow generating React hooks
import { PartyRelation } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";

import { RootState } from "../../store";
import serviceBuilder from "../builders/api.builder";
import { partyRelationEntityAdapter } from "./partyRelation.adapter";

export const partyRelationSelector =
  partyRelationEntityAdapter.getSelectors<RootState>(
    (state) => state.partyRelation
  );

// Define a service using a base URL and expected endpoints
export const partyRelationBaseApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "partyRelationApi",
  tagTypes: ["Party-Relation", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "party-relation"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: partyRelationEntityAdapter
  })
});

export const partyRelationApi = partyRelationBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    children: builder.query<EntityState<PartyRelation>, string>({
      query: (id) => {
        return {
          url: `/children/${id}`,
          method: "GET"
        };
      },
      transformResponse: (response: PartyRelation[]) => {
        const adapter = partyRelationEntityAdapter.addMany(
          partyRelationEntityAdapter.getInitialState(),
          response
        );
        return adapter;
      }
    }),
    batchUpdate: builder.mutation<
      { batch: string[] },
      { relations: ({ id: string } & Partial<PartyRelation>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    })
  })
});
