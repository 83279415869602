import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  IconButton,
  Spacer,
  Text,
  TextProps,
  Tooltip
} from "@chakra-ui/react";
import { FieldType } from "@elphi/types";
import { get, isArray, isEqual, isUndefined, uniqWith } from "lodash";
import { ReactElement, useEffect, useState } from "react";
import {
  primary200,
  universalWhitefff
} from "../../assets/redesign/color-palette";
import { NOT_AVAILABLE } from "../../constants/common";
import StyledInputBuilder, {
  ValueContainer
} from "../form-builder/InputBuilder";
import { CellFilterStateKeys, CellsFilterStateFlat } from "./hooks/filter.hook";

export const basicFilterOptionsFormatter = (
  v: string[] | string,
  _: any
): FormatterResponseType => {
  if (typeof v === "string") {
    return [{ label: v, value: v }];
  } else if (Array.isArray(v)) {
    return v.map((item: string) => {
      return {
        label: item,
        value: item
      };
    });
  } else {
    return [{ label: NOT_AVAILABLE, value: NOT_AVAILABLE }];
  }
};

export type CustomStyles = {
  titleContainer?: BoxProps;
  title?: TextProps;
  inputContainer?: BoxProps;
  container?: FlexProps;
};

export type FormatterResponseType =
  | { label: string | ReactElement; value: string }[]
  | { label: string | ReactElement; value: string };

export const FilterCellComponent = <T,>(props: {
  data: T[];
  disabled?: boolean;
  state: CellsFilterStateFlat<T>;
  fieldKey: keyof CellsFilterStateFlat<T>;
  setter: React.Dispatch<React.SetStateAction<CellsFilterStateFlat<T>>>;
  inputSetter?: (
    newInput: string,
    fieldKey: keyof CellsFilterStateFlat<T>
  ) => void;
  selectedOrderState?: { selectedKey: keyof CellFilterStateKeys<T> };
  selectedOrderSetter: React.Dispatch<
    React.SetStateAction<{ selectedKey: keyof CellFilterStateKeys<T> }>
  >;
  formatter?: (v: string[] | string, t: T) => FormatterResponseType;
  adapter?: (data: T) => string | string[];
  tName?: string;
  baseField?: string;
  showSelectAll?: boolean;
  showColumnTitle?: boolean;
  showOrderState?: boolean;
  fieldType?: FieldType;
  children?: React.ReactNode;
  inputFlexDir?: "column" | "row";
  selectedValue?: string | string[];
  customStyles?: CustomStyles;
  tooltipLabel?: string;
}) => {
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const {
    showSelectAll = true,
    showColumnTitle = true,
    showOrderState = true,
    baseField,
    inputFlexDir,
    fieldType = FieldType.MultiSelect,
    selectedValue,
    disabled,
    tooltipLabel
  } = props;
  useEffect(() => {
    setOptions(
      uniqWith(
        props.data
          .map((t) => {
            //TODO: type fieldValue
            let fieldValue;
            if (props?.adapter) {
              const adapted = props.adapter(t);
              fieldValue = adapted;
            } else
              fieldValue =
                t[props.fieldKey] ||
                (!!baseField &&
                  get(t, [baseField, props.fieldKey], undefined)) ||
                undefined;
            const fieldKeyValue = !!baseField
              ? get(t, [baseField, props.fieldKey], undefined)
              : t[props.fieldKey];
            const value = props.formatter
              ? props.formatter(
                  fieldValue || (Array.isArray(fieldValue) ? [] : undefined),
                  t
                )
              : {
                  label: fieldKeyValue || "Created",
                  value: fieldKeyValue || undefined
                };
            return value;
          })
          .flat(),
        isEqual
      )
    );
  }, [props.data]);

  const onSelectedOrderSetter = () => {
    let newState = { ...props.state };
    const fieldKey = props.fieldKey;
    newState[fieldKey] = {
      ...newState[fieldKey],
      orderBy: newState[fieldKey]?.orderBy === "asc" ? "desc" : "asc"
    };
    props.selectedOrderSetter({ selectedKey: props.fieldKey });
    props.setter(newState);
  };
  const currentValues = props.state[props.fieldKey].query;

  const onChange = (value) => {
    const newState: CellsFilterStateFlat<T> = {
      ...props.state,
      [props.fieldKey]: {
        ...props.state[props.fieldKey],
        query: value || []
      }
    };
    props.setter(newState);
  };

  useEffect(() => {
    !isUndefined(selectedValue) &&
      onChange(
        selectedValue
          ? isArray(selectedValue)
            ? selectedValue
            : [selectedValue]
          : []
      );
  }, [selectedValue]);

  return (
    <Flex w="100%" flexDir={"column"} {...props.customStyles?.container}>
      <Flex w="100%" flexDir={"row"}>
        {showColumnTitle && (
          <Box h="100%" pt="10px" {...props.customStyles?.titleContainer}>
            <Text h="100%" {...props.customStyles?.title}>
              {props.state[props.fieldKey].column}
            </Text>
          </Box>
        )}
        {showOrderState && (
          <Box pl="10px" pt="2px">
            <IconButton
              size="xs"
              _hover={{
                opacity: 0.5
              }}
              bgColor={
                props.fieldKey == props.selectedOrderState?.selectedKey
                  ? "purple.200"
                  : "gray.100"
              }
              aria-label={`order-by-filter-${props.tName ?? "no-name"}-table`}
              icon={
                props.state[props.fieldKey]?.orderBy === "asc" ? (
                  <ArrowUpIcon boxSize={"5"} color={universalWhitefff} />
                ) : props.state[props.fieldKey]?.orderBy === "desc" ? (
                  <ArrowDownIcon boxSize={"5"} color={universalWhitefff} />
                ) : (
                  <ArrowDownIcon boxSize={"5"} />
                )
              }
              onClick={onSelectedOrderSetter}
            />
          </Box>
        )}
        <Spacer />
        {showSelectAll && (
          <Box pl="10px" pt="2px">
            <Button
              size="xs"
              onClick={() => {
                props.state[props.fieldKey].query = options.map((v) => v.value);
                props.setter({ ...props.state });
              }}
            >
              Select All
            </Button>
          </Box>
        )}
      </Flex>
      <Tooltip label={tooltipLabel} placement="auto">
        <Box w="100%" {...props.customStyles?.inputContainer}>
          {props.children ? (
            props.children
          ) : (
            <StyledInputBuilder
              isDisabled={disabled}
              label={"filter..."}
              fieldType={fieldType}
              currentValue={currentValues}
              options={options}
              isValid
              customComponent={{ ValueContainer: ValueContainer }}
              customFlexDir={inputFlexDir}
              chakraStyles={{
                valueContainer: (provided, _) => ({
                  ...provided,
                  p: 0,
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                  textOverflow: "ellipsis"
                }),
                control: (provided, _) => ({
                  ...provided,
                  background:
                    currentValues.length > 0 ? primary200 : universalWhitefff
                })
              }}
              onInputChange={(newInput: string) => {
                props?.inputSetter?.(newInput, props.fieldKey);
              }}
              hideSelectedOptions={false}
              onChange={(e) => onChange(e.target.value)}
            />
          )}
        </Box>
      </Tooltip>
    </Flex>
  );
};
