import { TObject, TProperties, TSchema, Type } from "@sinclair/typebox";
import {
  EntityToEntityRelationRoleType,
  EntityToIndividualRelationRoleType,
  PartyRelationType
} from "../entities";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import {
  ArrayField,
  EnumField,
  PercentField,
  StringField
} from "./utils.typebox";

export const BasePartyRelationFieldsSchema = <TType extends TSchema>(
  type: TType
) =>
  Type.Object({
    type: type,
    parentId: StringField,
    childId: StringField,
    ownershipPercentage: PercentField
  });

export const BasePartyRelationSchema = <TType extends PartyRelationType>(
  type: TType
) =>
  Type.Intersect([
    BaseEntitySchema,
    BasePartyRelationFieldsSchema(Type.Literal(type))
  ]);

export const EntityToEntityRelationSchema = Type.Intersect([
  BasePartyRelationSchema(PartyRelationType.EntityToEntity),
  Type.Object({
    relationRoleType: ArrayField(EnumField(EntityToEntityRelationRoleType))
  })
]);

export const EntityToIndividualRelationSchema = Type.Intersect([
  BasePartyRelationSchema(PartyRelationType.EntityToIndividual),
  Type.Object({
    relationRoleType: ArrayField(EnumField(EntityToIndividualRelationRoleType))
  })
]);

export const PartyRelationSchema = Type.Union([
  EntityToEntityRelationSchema,
  EntityToIndividualRelationSchema
]);

export const EntityToEntityPartyRelationUpdate = OmitUpdateSchema(
  EntityToEntityRelationSchema
);

export const EntityToEntityRelationCreate = OmitCreateSchema(
  EntityToEntityRelationSchema
);

export const EntityToIndividualPartyRelationUpdate = OmitUpdateSchema(
  EntityToIndividualRelationSchema
);

export const EntityToIndividualPartyRelationCreate = OmitCreateSchema(
  EntityToIndividualRelationSchema
);

export const PartyRelationUpdate = Type.Union([
  EntityToEntityPartyRelationUpdate,
  EntityToIndividualPartyRelationUpdate
]);

export const PartyRelationCreate = Type.Union([
  EntityToEntityRelationCreate,
  EntityToIndividualPartyRelationCreate
]);

export const getOperationPartyRelationSchemaFromType = (
  op: "create" | "update",
  partyRelationType?: PartyRelationType
): TObject<TProperties> => {
  if (partyRelationType === PartyRelationType.EntityToIndividual) {
    return op === "create"
      ? EntityToIndividualPartyRelationCreate
      : EntityToIndividualPartyRelationUpdate;
  } else if (partyRelationType === PartyRelationType.EntityToEntity) {
    return op === "create"
      ? EntityToEntityRelationCreate
      : EntityToEntityPartyRelationUpdate;
  } else {
    throw `invalid party-relation type: "${partyRelationType}", expected one of: ${PartyRelationType.EntityToEntity} | ${PartyRelationType.EntityToIndividual}`;
  }
};
