import { Deal, Property } from "@elphi/types";
import {
  hideBridgePlus,
  hideFixNFlip,
  hideFixNFlipNoBridge,
  hideRental30,
  hideRental30OrEmpty,
  isEmptyLoanProgramType,
  isFixNFlip,
  isNewConstruction,
  isRental30Premier
} from "../../../../components/application/sections/sections.utils";
import {
  OnChangeInput,
  Section
} from "../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../components/form-builder/field-specs/fields.types";
import {
  addRuleToInput,
  addRulesToInputs,
  buildSection
} from "../../utils/formBuilder.utils";
import { totalCostAmount } from "../inputs/deal.inputs";
import {
  PSABuyerBorrowerMismatch,
  PSAExpirationDate,
  allocatedLoanAmount,
  annualCapitalExpenditure,
  annualHomeownersAssociationFeeAmount,
  annualTaxAmount,
  appraisalCompletedDate,
  appraisalCoordinator,
  appraisalEffectiveDate,
  appraisalExpirationDate,
  appraisalForm1007RequiredIndicator,
  appraisalManagementCompanyName,
  appraisalManagementContactPointEmailValue,
  appraisalManagementContactPointTelephoneValue,
  appraisalManagementRepresentativeFullName,
  appraisalOrderedDate,
  appraisalType,
  appraisalValueDerivationType,
  assignmentFeeAmount,
  borrowerRenovationCostsAfterPurchaseAmount,
  bridgeLTVPercent,
  cemaRefinanceIndicator,
  conditionRatingType,
  condoCertificateComments,
  condoCertificateFeesCollectedIndicator,
  condoWarrantableIndicator,
  constructionBudgetAssessment,
  constructionCostAmount,
  constructionCostComments,
  contractClosingDate,
  contractExecutionDate,
  defaultInterestPaymentsOrFeesNotedIndicator,
  financedUnitCount,
  floodInsuranceAddressLineText,
  floodInsuranceAddressUnitIdentifier,
  floodInsuranceCityName,
  floodInsuranceContactPointEmailValue,
  floodInsuranceContactPointTelephoneValue,
  floodInsuranceDeductibleAmount,
  floodInsuranceDwellingCoverageAmount,
  floodInsuranceFullName,
  floodInsuranceNextPaymentDueDate,
  floodInsurancePolicyExpirationDate,
  floodInsurancePolicyNumber,
  floodInsurancePostalCode,
  floodInsurancePremiumAmount,
  floodInsurancePremiumOutstandingAmount,
  floodInsurancePremiumPaymentType,
  floodInsuranceRepresentativeFullName,
  floodInsuranceStateCode,
  inspectionDocumentsReceivedDate,
  inspectionInitialDelayReasonStatusType,
  inspectionInitialRequestDate,
  inspectionOrderProcessedDate,
  inspectionOrderReceivedDate,
  inspectionOrderType,
  inspectionReportDueDate,
  inspectionRequestOrderedDate,
  inspectorFullName,
  internalL1CRefinance,
  internalValuationEligibilityIndicator,
  leaseStatusType,
  leaseStrategy,
  legalDescription,
  ltcRatePercent,
  mortgageeContactFullName,
  mortgageeContactPointEmailValue,
  mortgageeContactPointTelephone,
  mortgageeFullName,
  originalPurchaseDate,
  originalPurchasePriceAmount,
  outstandingLoanPayoffAmount,
  parcelNumber,
  payoffExpirationDate,
  pricingEngineLTARVRatePercent,
  propertyAccessInformation,
  propertyAddressLineText,
  propertyAddressUnitIdentifier,
  propertyAreaValue,
  propertyBlockIdentifier,
  propertyCityName,
  propertyCountyName,
  propertyDetailComments,
  propertyInsuranceAddressLineText,
  propertyInsuranceAddressUnitIdentifier,
  propertyInsuranceAgentFullName,
  propertyInsuranceBuilderRiskInsurance,
  propertyInsuranceCityName,
  propertyInsuranceContactPointEmail,
  propertyInsuranceContactPointTelephone,
  propertyInsuranceDeductibleAmount,
  propertyInsuranceDwellingCoverageAmount,
  propertyInsuranceFullName,
  propertyInsuranceLiabilityCoverageAmount,
  propertyInsuranceNextPaymentDueDate,
  propertyInsurancePolicyExpirationDate,
  propertyInsurancePolicyIdentifier,
  propertyInsurancePostalCode,
  propertyInsurancePremiumAmount,
  propertyInsurancePremiumOutstandingAmount,
  propertyInsurancePremiumPaymentType,
  propertyInsuranceRentalLossCoverageAmount,
  propertyInsuranceReplacementCoverageIndicator,
  propertyInsuranceStateCode,
  propertyLoanPurpose,
  propertyLotIdentifier,
  propertyPostalCode,
  propertyRightsOwnershipType,
  propertyStateCode,
  propertyStateName,
  propertyStructureBuiltYear,
  propertySubdivisionIdentifier,
  propertyType,
  propertyValuationAmount,
  propertyZoningComplianceRatingType,
  propertyZoningDescription,
  psaAddendumIndicator,
  psaAssignmentContractIndicator,
  psaExtensionFormIndicator,
  psaExtensionRequestedIndicator,
  psaSellerOwnerRecordMismatchIndicator,
  purchasePriceAmount,
  qualityRatingType,
  rentalLTVPercent,
  specificZoningClassification,
  subjectToPropertyValuationAmount,
  totalBathroomCount,
  totalBedroomCount
} from "../inputs/property.inputs";

const propertyDescriptionSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange?: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Property Description",
      inputs: [
        propertyAddressLineText,
        propertyAddressUnitIdentifier,
        propertyCityName,
        propertyCountyName,
        propertyStateName,
        propertyStateCode,
        propertyPostalCode,
        propertyType,
        financedUnitCount,
        parcelNumber,
        legalDescription,
        propertyLotIdentifier,
        propertyBlockIdentifier,
        propertySubdivisionIdentifier,
        propertyRightsOwnershipType,
        condoWarrantableIndicator,
        condoCertificateFeesCollectedIndicator,
        condoCertificateComments
      ],
      fieldSpecs
    });

const propertyDetailSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    dealState?: Deal;
    onChange?: (v: OnChangeInput) => void;
  }): Section => {
    const dealState = r.dealState || {};
    return buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Property Details",
      inputs: [
        propertyLoanPurpose,
        purchasePriceAmount,
        outstandingLoanPayoffAmount,
        defaultInterestPaymentsOrFeesNotedIndicator,
        allocatedLoanAmount,
        addRuleToInput({
          input: bridgeLTVPercent,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideBridgePlus(dealState)
            }
          ]
        }),
        addRuleToInput({
          input: rentalLTVPercent,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideRental30(dealState)
            }
          ]
        }),
        addRuleToInput({
          input: pricingEngineLTARVRatePercent,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideFixNFlipNoBridge(dealState)
            }
          ]
        }),
        ...addRulesToInputs({
          inputs: [constructionCostAmount, constructionCostComments],
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideFixNFlip(dealState)
            }
          ]
        }),
        addRuleToInput({
          input: leaseStatusType,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideRental30(dealState)
            }
          ]
        }),
        originalPurchaseDate,
        originalPurchasePriceAmount,
        borrowerRenovationCostsAfterPurchaseAmount,
        ltcRatePercent,
        ...addRulesToInputs({
          inputs: [annualTaxAmount, annualHomeownersAssociationFeeAmount],
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideRental30(dealState)
            }
          ]
        }),
        addRuleToInput({
          input: annualCapitalExpenditure,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !isRental30Premier(dealState) &&
                !isEmptyLoanProgramType(dealState)
            }
          ]
        }),
        totalCostAmount,
        propertyDetailComments,
        internalL1CRefinance,
        addRuleToInput({
          input: leaseStrategy,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideRental30(dealState)
            }
          ]
        }),
        addRuleToInput({
          input: constructionBudgetAssessment,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !isNewConstruction(dealState) &&
                !isFixNFlip(dealState) &&
                !isEmptyLoanProgramType(dealState)
            }
          ]
        })
      ],
      fieldSpecs
    });
  };

const purchaseAndSaleAgreementSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange?: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Purchase and Sale Agreement",
      inputs: [
        contractExecutionDate,
        contractClosingDate,
        PSAExpirationDate,
        psaExtensionRequestedIndicator,
        psaExtensionFormIndicator,
        psaAssignmentContractIndicator,
        assignmentFeeAmount,
        psaAddendumIndicator,
        psaSellerOwnerRecordMismatchIndicator,
        PSABuyerBorrowerMismatch
      ],
      fieldSpecs
    });

const payoffSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange?: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Payoff",
      inputs: [
        payoffExpirationDate,
        mortgageeFullName,
        mortgageeContactFullName,
        mortgageeContactPointTelephone,
        mortgageeContactPointEmailValue,
        cemaRefinanceIndicator
      ],
      fieldSpecs
    });

const valuationSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    dealState?: Deal;
    onChange?: (v: OnChangeInput) => void;
  }): Section => {
    const dealState = r.dealState || {};
    return buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Valuation",
      inputs: [
        appraisalValueDerivationType,
        propertyValuationAmount,
        addRuleToInput({
          input: subjectToPropertyValuationAmount,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => !hideRental30OrEmpty(dealState)
            }
          ]
        }),
        appraisalOrderedDate,
        appraisalCompletedDate,
        appraisalEffectiveDate,
        appraisalExpirationDate,
        appraisalType,
        qualityRatingType,
        conditionRatingType,
        propertyAreaValue,
        totalBedroomCount,
        totalBathroomCount,
        propertyStructureBuiltYear,
        propertyCountyName,
        specificZoningClassification,
        propertyZoningDescription,
        propertyZoningComplianceRatingType,
        appraisalCoordinator,
        addRuleToInput({
          input: internalValuationEligibilityIndicator,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideFixNFlip(dealState)
            }
          ]
        }),
        addRuleToInput({
          input: appraisalForm1007RequiredIndicator,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideRental30(dealState)
            }
          ]
        }),
        appraisalManagementRepresentativeFullName,
        appraisalManagementContactPointTelephoneValue,
        appraisalManagementContactPointEmailValue,
        appraisalManagementCompanyName,
        propertyAccessInformation
      ],
      fieldSpecs
    });
  };

const propertyInsuranceSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    dealState?: Deal;
    onChange?: (v: OnChangeInput) => void;
  }): Section => {
    const dealState = r.dealState || {};
    return buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Property Insurance",
      inputs: [
        propertyInsuranceDwellingCoverageAmount,
        propertyInsuranceLiabilityCoverageAmount,
        propertyInsuranceReplacementCoverageIndicator,
        addRuleToInput({
          input: propertyInsuranceRentalLossCoverageAmount,
          state: dealState,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideRental30(dealState)
            }
          ]
        }),
        propertyInsurancePremiumOutstandingAmount,
        propertyInsurancePremiumAmount,
        propertyInsurancePremiumPaymentType,
        propertyInsurancePolicyIdentifier,
        propertyInsurancePolicyExpirationDate,
        propertyInsuranceNextPaymentDueDate,
        propertyInsuranceDeductibleAmount,
        propertyInsuranceAgentFullName,
        propertyInsuranceFullName,
        propertyInsuranceContactPointTelephone,
        propertyInsuranceContactPointEmail,
        propertyInsuranceAddressLineText,
        propertyInsuranceAddressUnitIdentifier,
        propertyInsuranceCityName,
        propertyInsuranceStateCode,
        propertyInsurancePostalCode,
        propertyInsuranceBuilderRiskInsurance
      ],
      fieldSpecs
    });
  };

const floodInsuranceSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Flood Insurance",
      inputs: [
        floodInsuranceDwellingCoverageAmount,
        floodInsurancePremiumAmount,
        floodInsurancePremiumPaymentType,
        floodInsurancePremiumOutstandingAmount,
        floodInsurancePolicyNumber,
        floodInsurancePolicyExpirationDate,
        floodInsuranceNextPaymentDueDate,
        floodInsuranceDeductibleAmount,
        floodInsuranceFullName,
        floodInsuranceRepresentativeFullName,
        floodInsuranceContactPointTelephoneValue,
        floodInsuranceContactPointEmailValue,
        floodInsuranceAddressLineText,
        floodInsuranceAddressUnitIdentifier,
        floodInsuranceCityName,
        floodInsuranceStateCode,
        floodInsurancePostalCode
      ],
      fieldSpecs
    });

const generalSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    onChange: (v: OnChangeInput) => void;
  }): Section =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "General",
      inputs: [
        inspectionInitialRequestDate,
        inspectionRequestOrderedDate,
        inspectionReportDueDate,
        inspectionOrderType,
        inspectorFullName,
        inspectionOrderReceivedDate,
        inspectionOrderProcessedDate,
        inspectionDocumentsReceivedDate,
        inspectionInitialDelayReasonStatusType
      ],
      fieldSpecs
    });

const createPropertySection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<Property>;
    options?: { hideAttachedComponent: boolean };
  }): Section =>
    buildSection({
      state: r.state,
      header: "Create New",
      inputs: [
        propertyAddressLineText,
        propertyAddressUnitIdentifier,
        propertyCityName,
        propertyStateName,
        propertyStateCode,
        propertyPostalCode,
        propertyLotIdentifier,
        propertyBlockIdentifier,
        propertySubdivisionIdentifier
      ],
      hideAttachedComponent: r.options?.hideAttachedComponent,
      fieldSpecs
    });

export const propertySectionBuilders = {
  propertyDescriptionSection,
  propertyDetailSection,
  purchaseAndSaleAgreementSection,
  payoffSection,
  valuationSection,
  propertyInsuranceSection,
  floodInsuranceSection,
  generalSection,
  createPropertySection
};
