import { getFocusedData } from "@elphi/aggregations/src/utils/aggregation.utils";
import { Deal } from "@elphi/types";

import { useCallback } from "react";
import useDealHooks from "../../hooks/deal.hooks";
import { removeNulls } from "../../utils/filter.utils";
import { getRankedData } from "../../utils/ranked.utils";
import { SearchComponentProps } from "../SearchComponent";
import { SearchHandler } from "../search/SearchHandler";

const buildOption = (deal: Deal) => {
  return {
    label: [
      deal.LoanIdentifier,
      deal.LoanName || "missing name",
      getFocusedData(deal?.aggregations?.LoanProgramType)
    ]
      .filter(removeNulls)
      .join(", "),
    value: deal.id
  };
};
export type DealSearchProps = {
  filter?: (a: Deal) => boolean;
} & Pick<
  SearchComponentProps,
  | "onSelect"
  | "currentValue"
  | "label"
  | "labelPosition"
  | "isReadOnly"
  | "isDisabled"
> &
  Pick<Partial<SearchComponentProps>, "fieldType">;
const DealSearch = (props: DealSearchProps) => {
  const {
    searchDeal,
    searchResponse,
    rankedSort,
    dealState,
    dataRank,
    dealSearchState
  } = useDealHooks();
  const customFilterLogic: SearchComponentProps["filterOption"] = (
    option,
    searchText
  ) => {
    if (!searchText) return true;
    const deal = dealState?.entities?.[option.data.value];
    if (!deal || !deal.id) return false;
    return (
      getRankedData(searchText, deal, dataRank) > 0.5 ||
      searchText === deal.id.toLowerCase()
    );
  };
  const customFilter = useCallback(customFilterLogic, [dealState?.entities]);

  return (
    <SearchHandler
      {...props}
      version="v2"
      searchApi={searchDeal}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      filterOption={customFilter}
      state={dealSearchState}
      buildOption={buildOption}
    />
  );
};

export default DealSearch;
