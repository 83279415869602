import { Flex, Skeleton, SkeletonProps, SkeletonText } from "@chakra-ui/react";
import { useMemo } from "react";

export const CardsContainer = (props: {
  isLoading: boolean;
  children: JSX.Element;
}) => {
  return (
    <Flex gap="12px" wrap={"wrap"}>
      {props.isLoading ? <CardsSkeleton /> : <>{props.children}</>}
    </Flex>
  );
};

export const CardsSkeleton = (props: {
  amount?: number;
  skeletonStyle?: SkeletonProps;
}) => {
  const { amount = 10, skeletonStyle = {} } = props;
  const skeleton = useMemo(() => {
    return Array(amount)
      .fill(0)
      .map((_, i) => {
        return (
          <Skeleton key={i} width={"400px"} height={"80px"} {...skeletonStyle}>
            <SkeletonText />
          </Skeleton>
        );
      });
  }, []);
  return <>{skeleton}</>;
};
