import {
  AggregationType,
  IntegrationType,
  PropertyReportVendorType
} from "@elphi/types";
import { stringValidation } from "../../../../../components/utils/validationUtils";
import { baseElphiFieldsSpecs } from "../../../base/spec-files/baseElphiFieldsSpecs";
import { buildInput } from "../../../utils/formBuilder.utils";
export const siteXOrderStatus = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.PropertyReport
    ]?.[PropertyReportVendorType.SiteX].orderStatus?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXOrderCompletedOn = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.PropertyReport
    ]?.[PropertyReportVendorType.SiteX].orderCompletedOn?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXAddressLineText = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.PropertyReport
    ]?.[PropertyReportVendorType.SiteX].addressLineText?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXAddressUnitIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.PropertyReport
    ]?.[PropertyReportVendorType.SiteX].addressUnitIdentifier?.focused
      ?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXCityName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.PropertyReport
    ]?.[PropertyReportVendorType.SiteX].cityName?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXStateCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.PropertyReport
    ]?.[PropertyReportVendorType.SiteX].stateCode?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXPostalCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.PropertyReport
    ]?.[PropertyReportVendorType.SiteX].postalCode?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXCountyName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.PropertyReport
    ]?.[PropertyReportVendorType.SiteX].countyName?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXAssessorParcelNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.PropertyReport
    ]?.[PropertyReportVendorType.SiteX].assessorParcelNumber?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const siteXFips = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.PropertyReport
    ]?.[PropertyReportVendorType.SiteX].fips?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});
