import Decimal from "decimal.js";
import { removeNonNumeric } from "../../types";

type ElphiDecimalValue = string | number;
type ElphiDecimal = (v: ElphiDecimalValue) => {
  pow(exponent: string | number): ReturnType<ElphiDecimal>;
  div(n: string | number): ReturnType<ElphiDecimal>;
  mul(n: string | number): ReturnType<ElphiDecimal>;
  add(n: string | number): ReturnType<ElphiDecimal>;
  minus(n: string | number): ReturnType<ElphiDecimal>;
  lessThan(n: string | number): boolean;
  greaterThanOrEqualTo(n: string | number): boolean;
  toString: () => string;
  toNumber: () => number;
  floor: () => ReturnType<ElphiDecimal>;
  trunc: () => ReturnType<ElphiDecimal>;
  toPrecision: (n: number) => string;
  toFixed: (n: number) => string;
};

type ElphiDecimalReductions = {
  max(...n: (string | number)[]): ReturnType<ElphiDecimal>;
  min(...n: (string | number)[]): ReturnType<ElphiDecimal>;
};

export type ElphiDecimalConfig = {
  sanitization: (v: string | number) => string;
};

const decimalFactory = (c: ElphiDecimalConfig): ElphiDecimal => {
  return (v) => {
    const sanitizedValue = new Decimal(c.sanitization(v));
    const configuredDecimal = decimalFactory(c);
    return {
      div: (n) => {
        const result = sanitizedValue.div(c.sanitization(n));
        return configuredDecimal(result.valueOf());
      },
      mul: (n) => {
        const result = sanitizedValue.mul(c.sanitization(n));
        return configuredDecimal(result.valueOf());
      },
      pow: (n) => {
        const result = sanitizedValue.pow(c.sanitization(n));
        return configuredDecimal(result.valueOf());
      },
      floor: () => {
        const result = sanitizedValue.floor();
        return configuredDecimal(result.valueOf());
      },
      trunc: () => {
        const result = sanitizedValue.trunc();
        return configuredDecimal(result.valueOf());
      },
      add: (n) => {
        const result = sanitizedValue.add(c.sanitization(n));
        return configuredDecimal(result.valueOf());
      },
      minus: (n) => {
        const result = sanitizedValue.minus(c.sanitization(n));
        return configuredDecimal(result.valueOf());
      },
      lessThan: (n) => {
        return sanitizedValue.lessThan(c.sanitization(n));
      },
      greaterThanOrEqualTo: (n) => {
        return sanitizedValue.greaterThanOrEqualTo(c.sanitization(n));
      },
      toString: () => v.toString(),
      toNumber: () => Number(v),
      toPrecision: (n) => sanitizedValue.toPrecision(n).toString(),
      toFixed: (n) => sanitizedValue.toFixed(n).toString()
    };
  };
};

export const decimalReductionsFactory = (
  c: ElphiDecimalConfig
): ElphiDecimalReductions => {
  const configuredDecimal = decimalFactory(c);
  return {
    max: (...n) => {
      const sanitizedValues = n.map((v) => c.sanitization(v));
      const result = Decimal.max(...sanitizedValues);
      return configuredDecimal(result.valueOf());
    },
    min: (...n) => {
      const sanitizedValues = n.map((v) => c.sanitization(v));
      const result = Decimal.min(...sanitizedValues);
      return configuredDecimal(result.valueOf());
    }
  };
};

const sanitizeDecimalValue = (v: ElphiDecimalValue) => {
  const sanitizedValue = removeNonNumeric(String(v));
  try {
    return new Decimal(sanitizedValue).toString();
  } catch (error) {
    return new Decimal(0).toString();
  }
};

export const decimalReductions = decimalReductionsFactory({
  sanitization: sanitizeDecimalValue
});

export const elphiDecimal = decimalFactory({
  sanitization: sanitizeDecimalValue
});
