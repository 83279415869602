import { AggregationType } from "@elphi/types";
import { creditReportFieldSpecs } from "../../../../../components/form-builder/field-specs/credit-report/credit-report.fields";
import {
  singleSelectValidation,
  stringValidation
} from "../../../../../components/utils/validationUtils";
import { limaSpecs } from "../../../lima/spec-files/limaFieldsSpecs";
import { buildInput } from "../../../utils/formBuilder.utils";

export const creditScorePullType = buildInput({
  fieldKey:
    limaSpecs?.party?.specsParts?.individualPartyFieldSpecs.CreditReportPullType
      .fieldKey,
  validation: singleSelectValidation
});

export const experianScore = buildInput({
  fieldKey: creditReportFieldSpecs.experian?.score?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const experianDate = buildInput({
  fieldKey: creditReportFieldSpecs.experian?.date.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const equifaxScore = buildInput({
  fieldKey: creditReportFieldSpecs.equifax?.score?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const equifaxDate = buildInput({
  fieldKey: creditReportFieldSpecs.equifax?.date?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const transunionScore = buildInput({
  fieldKey: creditReportFieldSpecs.transunion?.score?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const transunionDate = buildInput({
  fieldKey: creditReportFieldSpecs.transunion?.date?.focused?.fieldKey,
  calculationType: AggregationType.ThirdParty,
  validation: stringValidation
});

export const creditScorePullTypeField = buildInput({
  fieldKey: creditReportFieldSpecs.pullType?.fieldKey,
  validation: singleSelectValidation
});
