import { Party } from "@elphi/types";
import { useEffect } from "react";
import { useFormBuilderStateHandler } from "../components/form-builder/InputBuilder";
import { useElphiToast } from "../components/toast/toast.hook";
import { AppConfig } from "../config/appConfig";
import { usePartyHooks } from "./party.hooks";

export type PartyStructureState = {
  parties: { [id: string]: Party };
};
export const usePartyFormHandler = () => {
  const {
    partyTabHighlightedParty: highlightedParty,
    updateBatchParties: updateBatch
  } = usePartyHooks();
  const { errorToast, successToast } = useElphiToast();

  useEffect(() => {
    syncPartyState({
      shouldSync: !!highlightedParty,
      state: highlightedParty,
      statePath: () => {
        if (highlightedParty) {
          return ["parties", highlightedParty.id];
        }
      }
    });
  }, [highlightedParty]);

  const updatePartyHandler = async (diff: Partial<PartyStructureState>) => {
    if (!diff.parties) return null;
    const parties = Object.keys(diff.parties)
      .map((id) => {
        if (diff.parties) {
          return {
            ...diff.parties[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!parties.length) return null;

    return await updateBatch({ parties } as {
      parties: ({
        id: string;
      } & Partial<Party>)[];
    }).then((r) => {
      if (r?.status === 200) {
        successToast({
          title: "Parties Updated",
          description: `${r.data?.batch?.length} parties updated`
        });
      }
      if (r?.status === 400) {
        errorToast({
          title: "Failed to update parties",
          description:
            r.data?.error?.data?.error?.payload?.description ||
            r.data?.description
        });
      }
      return r;
    });
  };

  const {
    onChange: partyOnChange,
    state: partyState,
    setState: setPartyState,
    diffState: partyDiffState,
    setDiffState: setPartyDiffState,
    syncState: syncPartyState
  } = useFormBuilderStateHandler<PartyStructureState>({
    initialState: { parties: {} } as PartyStructureState,
    callback: updatePartyHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });
  return {
    partyOnChange,
    partyState,
    setPartyState,
    partyDiffState,
    setPartyDiffState,
    updatePartyHandler
  };
};
