// Need to use the React-specific entry point to allow generating React hooks
import { Deal, DealProperty, DealUser, Property } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { removeNulls } from "../../../utils/filter.utils";
import { RootState } from "../../store";
import serviceBuilder from "../builders/api.builder";
import { dealPropertyEntityAdapter } from "../deal-property-relation/dealPropertyRelation.adapter";
import { dealEntityAdapter } from "../deal/deal.adapter";
import { propertyEntityAdapter } from "./property.adapter";

export const propertySelector = propertyEntityAdapter.getSelectors<RootState>(
  (state) => state.property
);
// Define a service using a base URL and expected endpoints
export const basePropertyApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "propertyApi",
  tagTypes: ["Property", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "property"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: propertyEntityAdapter
  })
});

export type PaginateV2Response = {
  nextCursor?: string;
  hasMore?: boolean;
  page: {
    deal: Deal[];
    property: Property[];
    dealProperty: DealProperty[];
    dealUser: DealUser[];
  };
};
export const propertyApi = basePropertyApi.injectEndpoints({
  endpoints: (builder) => ({
    paginateV2: builder.query<
      PaginateV2Response,
      { limit?: string; cursor?: string; userId?: string; propertyId?: string }
    >({
      query: (r) => {
        const urlCursor = r.cursor ? `cursor=${r.cursor}` : null;
        const urlLimit = r.limit ? `limit=${r.limit}` : null;
        const urlUser = r.userId ? `user_id=${r.userId}` : null;
        const urlProperty = r.propertyId ? `property_id=${r.propertyId}` : null;
        const queryParams = [urlCursor, urlLimit, urlUser, urlProperty]
          .filter(removeNulls)
          .join("&");
        const query = queryParams ? `?${queryParams}` : "";
        const url = `/v2/paginate${query}`;
        return {
          url,
          method: "GET"
        };
      }
    }),
    updateBatch: builder.mutation<
      { batch: string[] },
      { properties: ({ id: string } & Partial<Property>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "POST",
          body: r
        };
      }
    }),
    propertyDeals: builder.query<
      { relations: EntityState<DealProperty>; deals: EntityState<Deal> },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/property-deals`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: {
        results: { relations: DealProperty[]; deals: Deal[] };
      }) => {
        const dealAdapter = dealEntityAdapter.addMany(
          dealEntityAdapter.getInitialState(),
          response.results.deals
        );
        const dealPropertyAdapter = dealPropertyEntityAdapter.addMany(
          dealPropertyEntityAdapter.getInitialState(),
          response.results.relations
        );
        return {
          relations: dealPropertyAdapter,
          deals: dealAdapter
        };
      }
    }),
    search: builder.query<EntityState<Property>, string>({
      query: (query) => {
        return {
          url: `/search?q=${query}`,
          method: "GET"
        };
      },
      transformResponse: (response: { results: Property[] }) => {
        const adapter = propertyEntityAdapter.addMany(
          propertyEntityAdapter.getInitialState(),
          response.results
        );
        return { ...adapter };
      }
    }),
    getPropertyBatch: builder.query<
      { properties: EntityState<Property> },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: { results: Property[] }) => {
        const adapter = propertyEntityAdapter.addMany(
          propertyEntityAdapter.getInitialState(),
          response.results
        );
        return { properties: adapter };
      }
    })
  })
});
