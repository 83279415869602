import { Box } from "@chakra-ui/react";
import { Deal, Property } from "@elphi/types";
import { useEffect } from "react";
import { AppConfig } from "../../config/appConfig";
import { useSections } from "../../forms/schemas/providers/SectionsProvider";
import { usePropertyFormHandler } from "../../hooks/propertyform.hooks";
import { NavigationPath } from "../../shared/types/navigation.types";
import FormBuilder, { OnChangeInput } from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";

const SectionsBeforeTables = (props: {
  dealState?: Deal;
  selectedProperty?: Property;
  snapshotId?: string;
  navigationPath?: NavigationPath;
}) => {
  const { selectedProperty, navigationPath } = props;
  const { updatePropertyHandler } = usePropertyFormHandler();
  const { sections } = useSections();
  const { onChange, state, syncState } = useFormBuilderStateHandler({
    initialState: { properties: {} },
    callback: props.snapshotId ? undefined : updatePropertyHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    if (selectedProperty) {
      syncState({
        shouldSync: !!selectedProperty,
        state: selectedProperty,
        statePath: () => {
          if (selectedProperty) {
            return ["properties", selectedProperty.id];
          }
        }
      });
    }
  }, [selectedProperty, props.snapshotId]);

  const propertyState =
    (selectedProperty && state.properties[selectedProperty.id]) || {};

  const propertyOnChangeBatch = (v: OnChangeInput) => {
    selectedProperty &&
      !props.snapshotId &&
      onChange({
        fieldType: v.fieldType,
        fieldKey: ["properties", selectedProperty.id, ...v.fieldKey],
        value: v.value
      });
  };
  const { property: propertySections } = sections || {};
  const valuationSection = propertySections?.valuationSection?.({
    state: propertyState,
    dealState: props.dealState,
    onChange: propertyOnChangeBatch
  });

  const sectionsBeforeTables = [
    propertySections?.propertyDescriptionSection,
    propertySections?.propertyDetailSection,
    propertySections?.purchaseAndSaleAgreementSection,
    propertySections?.payoffSection
  ]
    .map((section) =>
      section?.({
        state: propertyState,
        onChange: propertyOnChangeBatch
      })
    )
    .concat(valuationSection);

  return (
    <Box h="100%" w="100%">
      {selectedProperty && (
        <Box h="100%" w="100%">
          <Box bgColor="white" h="100%" w="100%">
            <FormBuilder
              customKey="sectionsBeforeTables"
              onChange={propertyOnChangeBatch}
              sections={sectionsBeforeTables}
              navigationPath={navigationPath}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default SectionsBeforeTables;
