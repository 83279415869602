import {
  AccountAsset,
  AccountType,
  AssetType,
  BaseAsset,
  FieldType
} from "@elphi/types";
import { createOptionsFromEnum } from "../../../utils/formUtils";
import { baseEntityFormFieldSpecs } from "../baseEntity.fields";
import { EntityFormFieldSpecs, SpecsBaseOmit } from "../fields.types";
import { createSpecWithFieldMeta } from "../utils/fieldMeta.utils";

export type AccountAssetFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<AccountAsset>
>;
export const assetFieldBaseSpecs: EntityFormFieldSpecs<
  SpecsBaseOmit<BaseAsset<AssetType>>
> = {
  ...baseEntityFormFieldSpecs,
  type: {
    fieldType: FieldType.SingleSelect,
    label: "Asset Type",
    fieldKey: ["type"],
    options: createOptionsFromEnum(AssetType)
  },
  estimatedValueAmount: {
    fieldType: FieldType.Money,
    label: "Estimated Value",
    fieldKey: ["estimatedValueAmount"]
  },
  estimatedValueConsideredPercentage: {
    fieldType: FieldType.Percentage,
    label: "Estimated Value Percentage Considered",
    fieldKey: ["estimatedValueConsideredPercentage"]
  },
  appraisalValueAmount: {
    fieldType: FieldType.Money,
    label: "Appraisal Value",
    fieldKey: ["appraisalValueAmount"]
  },
  finalEstimatedValueAmount: {
    fieldType: FieldType.Money,
    label: "Final Estimated Value",
    fieldKey: ["finalEstimatedValueAmount"]
  },
  statements: {
    fieldType: FieldType.Array,
    label: "Attached Statements",
    fieldKey: ["statements"]
  }
};
export const accountAssetFieldBaseSpecs: AccountAssetFieldSpecs = {
  ...assetFieldBaseSpecs,
  fullNameOnAccount: {
    fieldType: FieldType.String,
    label: "Name on Account",
    fieldKey: ["fullNameOnAccount"]
  },
  accountName: {
    fieldType: FieldType.String,
    label: "Account Name",
    fieldKey: ["accountName"]
  },
  accountType: {
    fieldType: FieldType.SingleSelect,
    label: "Account Type",
    fieldKey: ["accountType"],
    options: createOptionsFromEnum(AccountType)
  },
  financialInstitution: {
    fieldType: FieldType.String,
    label: "Financial Institution",
    fieldKey: ["financialInstitution"]
  },
  last4Digits: {
    fieldType: FieldType.FourDigit,
    label: "Last 4 Digits",
    fieldKey: ["last4Digits"]
  }
};

export const accountAssetFieldSpecs = createSpecWithFieldMeta({
  spec: accountAssetFieldBaseSpecs
});
