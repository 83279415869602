// Need to use the React-specific entry point to allow generating React hooks
import { Role } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";

import { AppConfig } from "../../../config/appConfig";
import { RootState } from "../../store";
import serviceBuilder from "../builders/api.builder";
import { roleEntityAdapter } from "./adapter";

export const roleSelector = roleEntityAdapter.getSelectors<RootState>(
  (state) => state.role
);

// Define a service using a base URL and expected endpoints
export const baseRoleApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "roleApi",
  tagTypes: ["Role", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "role"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: roleEntityAdapter
  })
});

export const roleApi = baseRoleApi.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query<EntityState<Role>, string>({
      query: (query) => {
        return {
          url: `/search?q=${query}`,
          method: "GET"
        };
      },
      transformResponse: (response: { results: Role[] }) => {
        const adapter = roleEntityAdapter.addMany(
          roleEntityAdapter.getInitialState(),
          response.results
        );
        return { ...adapter };
      }
    }),
    getBatch: builder.query<{ batch: EntityState<Role> }, string[]>({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: { results: Role[] }) => {
        const adapter = roleEntityAdapter.addMany(
          roleEntityAdapter.getInitialState(),
          response.results
        );
        return { batch: adapter };
      }
    })
  })
});
