import { getFocusedData } from "@elphi/aggregations/src/utils/aggregation.utils";
import { Task } from "@elphi/types";
import { get } from "lodash";
import { TaskRowData } from "../../taskRowData.utils";
import { hasOrderStatus } from "../typeGuards";

const extractIntegrationField = ({
  data,
  path,
  isAggregation
}: {
  data: TaskRowData;
  path?: string;
  isAggregation?: boolean;
}): string | undefined => {
  if (!path) return;

  const value: string | undefined = isAggregation
    ? getFocusedData(get(data, path))
    : get(data, path);
  return value;
};

export const resolveIntegrationOrderStatus = ({
  data,
  path,
  isAggregation,
  mapper
}: {
  data: TaskRowData;
  path?: string;
  isAggregation?: boolean;
  mapper?: (value: string) => string;
}): string | undefined => {
  if (!path) return;
  const orderStatusValue = extractIntegrationField({
    data,
    path,
    isAggregation
  });
  return orderStatusValue
    ? mapper
      ? mapper(orderStatusValue)
      : orderStatusValue
    : undefined;
};

export const resolveTemplateOrderStatus = ({
  task
}: {
  task?: Task;
}): string | undefined => {
  return hasOrderStatus(task) && task.orderStatus
    ? task.orderStatus
    : undefined;
};
