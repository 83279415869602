// Need to use the React-specific entry point to allow generating React hooks
import { ElphiSnapshot, ElphiSnapshotEntity } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { RootState } from "../../store";
import serviceBuilder from "../builders/api.builder";
import { snapshotEntityAdapter } from "./snapshot.adapter";
export const snapshotSelector = snapshotEntityAdapter.getSelectors<RootState>(
  (state) => state.snapshot
);

// Define a service using a base URL and expected endpoints
export const snapshotBaseApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "snapshotApi",
  tagTypes: ["snapshot"],
  baseQuery: serviceBuilder.baseQuery({
    route: "snapshot"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: snapshotEntityAdapter
  })
});

export const snapshotApi = snapshotBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSnapshot: builder.query<
      { snapshot: EntityState<ElphiSnapshotEntity> },
      // {
      //   id: string;
      //   data: ElphiSnapshot;
      // },
      { id: string }
    >({
      query: (r) => {
        return {
          url: `/data/${r.id}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          snapshot: ElphiSnapshot;
        },
        _,
        arg
      ) => {
        // const snapshotAdapter = snapshotEntityAdapter.updateMany(
        //   snapshotEntityAdapter.getInitialState(),
        //   [
        //     {
        //       id: arg.id,
        //       changes: {
        //         data: response.snapshot
        //       }
        //     }
        //   ]
        // );

        const snapshotAdapter = snapshotEntityAdapter.addMany(
          snapshotEntityAdapter.getInitialState(),
          [
            {
              id: arg.id,
              snapshot: response.snapshot
            } as any
          ]
        );
        return { snapshot: snapshotAdapter };
      }
    }),
    batchUpdate: builder.mutation<
      { batch: string[] },
      { snapshots: ({ id: string } & Partial<ElphiSnapshotEntity>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    })
  })
});
