import { Deal } from "@elphi/types";
import {
  hideFixNFlip,
  hideFixNFlipOrEmpty,
  hideRental30,
  isEmptyLoanProgramType,
  isRental30Premier
} from "../../../../components/application/sections/sections.utils";
import { OnChangeInput } from "../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../components/form-builder/field-specs/fields.types";
import {
  addRuleToInput,
  addRulesToInputs,
  buildSection
} from "../../utils/formBuilder.utils";
import {
  achElectedIndicator,
  achExemptionStatus,
  achRequiredIndicator,
  allongeAssignmentSentToWarehouseIndicator,
  allongeAssignmentTrackingNumber,
  amortizationType,
  appraisalNotes,
  armFinalAdjustableRateTermMonthCount,
  armInitialFixedTermMonthCount,
  armRepriceFrequencyType,
  armRepriceLookbackType,
  armRepriceRoundingMethodType,
  armRepriceRoundingRatePercentType,
  brokerContactPointEmailValue,
  brokerContactPointTelephone,
  brokerFullName,
  brokerOriginationFeeAmount,
  brokerProcessingFeeAmount,
  brokerRepresentativeFirstName,
  brokerRepresentativeLastName,
  brokerWiringInstructionsIndicator,
  buyDownFeeAmount,
  buyUpFeeReductionAmount,
  capitalStructureType,
  cashFromBorrowerAmount,
  cashToBorrowerAmount,
  channel,
  chargentCollectionAmount,
  chargentGatewayIdentifier,
  chargentTransactionIdentifier,
  closingAgentFirstName,
  closingAgentLastName,
  closingAnalystUserId,
  condoCertificationFeeAmount,
  constructionBudgetVerifiedMatchIndicator,
  contractorReviewFeeAmount,
  creditReportFeeAmount,
  crmId,
  debtServiceCoverageRatioPercent,
  defaultInterestRatePercent,
  deferredMaintenanceAmount,
  deferredOriginationFeeAmount,
  downPaymentAmount,
  employeeLoanIndicator,
  eoExpirationDate,
  eoNamedInsuredMatchIndicator,
  estimatedClosingDate,
  executionType,
  feasibilityReviewFeeAmount,
  firstRateChangeDate,
  floodInsuranceAgencyFullName,
  floodInsurancePremiumPaymentType,
  floorPercent,
  fullyDrawnInterestOnlyPayment,
  fundingAgentEmailAddress,
  fundingAndDisbursementApprovalIndicator,
  fundingShieldStatusType,
  generalContractorContactPointEmail,
  generalContractorContactPointTelephone,
  generalContractorFullName,
  generalContractorRepresentativeFirstName,
  generalContractorRepresentativeLastName,
  gracePeriodDays,
  grossSpreadPercent,
  haircutAmount,
  indexSourceType,
  initialAdvancedFundAmount,
  initialLTVRatePercent,
  initialMonthlyInterestOnlyPaymentAmount,
  interestAccrualMethodType,
  interestReserveDepositAmount,
  interestReserveEscrow,
  lastDayOfClosingMonth,
  lastPaymentDueDate,
  lateFeePercent,
  lenderCounselFeeAmount,
  lenderFinanceApplicationFee,
  lenderFinanceAppraisalFee,
  lenderFinanceBorrowerFinalTermSheetApprovalStatusDate,
  lenderFinanceDocumentFee,
  lenderFinancePartnerFinalTermSheetApprovalStatusDate,
  lenderFinancePartnerFirstName,
  lenderFinancePartnerInitialTermSheetApprovalStatusDate,
  lenderFinancePartnerInitialTermSheetApprovalStatusIndicator,
  lenderFinancePartnerLastName,
  lenderFinanceProcessingFee,
  lenderIdentifier,
  lesserOfLotOrPurchase,
  letterOfIntentSignedDate,
  lifetimeCapPercent,
  loanAfterInterestOnlyPeriodMonthCount,
  loanAmortizationPeriodMonthCount,
  loanAmountVerifiedMatchIndicator,
  loanInterestOnlyPeriodMonthCount,
  loanMaturityDate,
  loanName,
  loanPackageCheckAmount,
  loanPackageCheckNumber,
  loanPackageChecksReceivedIndicator,
  loanPackageInternationalExecutionIndicator,
  loanPackageNoteReceivedByLenderIndicator,
  loanPackageNoteTrackingNumber,
  loanPackageTrackingNumber,
  loanPaymentType,
  loanProductType,
  loanProgramType,
  loanPurposeType,
  loanTermPeriodMonthCount,
  mailAwayIndicator,
  margin,
  marketingPromotionCode,
  marketingPromotionCreditAmount,
  monthlyHomeownersAssociationAmount,
  monthlyPaymentOfCapitalExpenditure,
  monthlyPrincipalInterestPaymentAmount,
  monthlyTaxInsurancePaymentAmount,
  netWireAmount,
  newYorkProcessingFeeAmount,
  nmlsIdentifier,
  notaryExpirationDate,
  notaryUserId,
  noteRatePercent,
  operationsDepartment,
  originationFeeAmount,
  paymentDueDay,
  perDiemPrepaidInterestAmount,
  personalGuarantorsCount,
  portfolioLoanIndicator,
  postClosingNotes,
  powerOfAttorneyFullName,
  prepaidInterestAmount,
  prepaymentPenaltyExpirationDate,
  prepaymentPenaltyType,
  processingFeeAmount,
  promissoryNoteComments,
  promissoryNoteRequestedIndicator,
  promissoryNoteShippedIndicator,
  promissoryNoteTrackingNumber,
  propertyInsuranceAgencyFullName,
  propertyInsurancePremiumPaymentType,
  propertyManagementComments,
  propertyManagementContactPointEmailValue,
  propertyManagementContactPointTelephoneValue,
  propertyManagementFullAddressName,
  propertyManagementFullName,
  propertyManagementRepresentativeFullName,
  purchaserWarrantyDeedAddedIndicator,
  rateLockEndDate,
  rateLockStartDate,
  recordedMortgageReceivedDate,
  recordedWarrantyDeedReceivedDate,
  recourseType,
  releasePercent,
  requestedLoanAmount,
  scheduledFirstPaymentDate,
  sellerHUDAddedIndicator,
  servicingSetupFee,
  settlementStatementComments,
  settlementStatementFileNumber,
  settlementStatementMatchLedgerIndicator,
  shippingCompanyType,
  titleCommitmentAllPayoffsLiensIncludedInSection1RequirementsIndicator,
  titleCommitmentAllSubjectPropertiesIncludedIndicator,
  titleCommitmentComments,
  titleCommitmentLeaseholdEndorsementIndicator,
  titleCommitmentOwnership,
  titleCommitmentRequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator,
  titleCommitmentTitleEffectiveDate,
  titleCommitmentTitleExpirationDate,
  titleCommitmentTitleInternationalExecutionIndicator,
  titleCommitmentTitlePolicyAmount,
  titleCommitmentTitleVestingType,
  titleCompanyAddressLineText,
  titleCompanyAddressUnitIdentifier,
  titleCompanyCityName,
  titleCompanyClosingAgentContactPointEmail,
  titleCompanyClosingAgentContactPointTelephone,
  titleCompanyClosingAgentFirstName,
  titleCompanyClosingAgentLastName,
  titleCompanyName,
  titleCompanyPostalCode,
  titleCompanyStateCode,
  titleFormsComments,
  titleInsuranceUnderwriter,
  titlePolicyReceivedDate,
  totalAdjustedMonthlyRentAmount,
  totalAnnualCapitalExpenditure,
  totalAsIsAppraisedValueAmount,
  totalBrokerFeeAmount,
  totalBudgetAmount,
  totalCostAmount,
  totalFloodInsurancePremiumAmount,
  totalFloodInsurancePremiumOutstandingAmount,
  totalHOAFeesAmount,
  totalInitialInsuranceEscrowAmount,
  totalInitialTaxEscrowAmount,
  totalLTARVRatePercent,
  totalLTVRatePercent,
  totalLiquidAssetAmount,
  totalLoanFeesAndClosingCostAmount,
  totalMonthlyPITIPaymentAmount,
  totalNumberOfProperties,
  totalOutstandingLoanPayoffAmount,
  totalPropertyInsurancePremiumAmount,
  totalPropertyInsurancePremiumOutstandingAmount,
  totalPurchasePriceAmount,
  totalSubjectToAppraisedValueAmount,
  totalTaxAmount,
  totalValuationOrPurchasePriceAmount,
  uccExpirationDate,
  uccFiledIndicator,
  valuationFeesCollectedBySalesIndicator,
  warehouseBankStatusType,
  warehouseBankType,
  wireABARoutingNumber,
  wireAccountName,
  wireAccountNumber,
  wireBankName,
  wireInsuranceMatchDealIndicator,
  wireReleaseDate
} from "../inputs/deal.inputs";

const loanInformationSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Information",
      inputs: [
        loanProgramType,
        loanPurposeType,
        loanName,
        portfolioLoanIndicator,
        estimatedClosingDate,
        channel,
        employeeLoanIndicator,
        chargentTransactionIdentifier,
        chargentGatewayIdentifier,
        chargentCollectionAmount,
        valuationFeesCollectedBySalesIndicator,
        appraisalNotes,
        crmId,
        operationsDepartment,
        lenderIdentifier
      ],
      fieldSpecs
    });

const dealTotalsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Deal Totals",
      inputs: [
        totalPurchasePriceAmount,
        addRuleToInput({
          input: totalBudgetAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        totalAsIsAppraisedValueAmount,
        addRuleToInput({
          input: totalSubjectToAppraisedValueAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        totalLTVRatePercent,
        addRuleToInput({
          input: totalLTARVRatePercent,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        ...addRulesToInputs({
          inputs: [totalTaxAmount, totalHOAFeesAmount],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        totalPropertyInsurancePremiumAmount,
        totalFloodInsurancePremiumAmount,
        addRuleToInput({
          input: totalCostAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        requestedLoanAmount,
        addRuleToInput({
          input: initialLTVRatePercent,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),

        totalOutstandingLoanPayoffAmount,
        addRuleToInput({
          input: initialAdvancedFundAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),

        totalLoanFeesAndClosingCostAmount,
        addRuleToInput({
          input: debtServiceCoverageRatioPercent,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),

        totalValuationOrPurchasePriceAmount,
        addRuleToInput({
          input: lesserOfLotOrPurchase,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),

        totalNumberOfProperties,
        totalAdjustedMonthlyRentAmount,
        ...addRulesToInputs({
          inputs: [
            totalAnnualCapitalExpenditure,
            monthlyPaymentOfCapitalExpenditure
          ],
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () =>
                !isRental30Premier(r.state) && !isEmptyLoanProgramType(r.state)
            }
          ]
        }),

        cashFromBorrowerAmount,
        cashToBorrowerAmount
      ],
      fieldSpecs
    });

const loanTermsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms",
      inputs: [
        addRuleToInput({
          input: loanProductType,
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () => !hideFixNFlipOrEmpty(r.state)
            }
          ]
        }),
        amortizationType,
        noteRatePercent,
        estimatedClosingDate,
        rateLockStartDate,
        rateLockEndDate,
        wireReleaseDate,
        scheduledFirstPaymentDate,
        paymentDueDay,
        lastPaymentDueDate,
        lastDayOfClosingMonth,
        loanMaturityDate,
        loanTermPeriodMonthCount,
        ...addRulesToInputs({
          inputs: [
            loanAmortizationPeriodMonthCount,
            loanInterestOnlyPeriodMonthCount,
            loanAfterInterestOnlyPeriodMonthCount,
            interestAccrualMethodType,
            loanPaymentType,
            totalMonthlyPITIPaymentAmount
          ],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        monthlyPrincipalInterestPaymentAmount,
        initialMonthlyInterestOnlyPaymentAmount,
        addRuleToInput({
          input: fullyDrawnInterestOnlyPayment,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        ...addRulesToInputs({
          inputs: [
            monthlyTaxInsurancePaymentAmount,
            monthlyHomeownersAssociationAmount
          ],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        addRuleToInput({
          input: executionType,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        ...addRulesToInputs({
          inputs: [prepaymentPenaltyType, prepaymentPenaltyExpirationDate],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        addRuleToInput({
          input: recourseType,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        })
      ],
      fieldSpecs
    });

const loanTermsARMSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      isHidden: hideRental30(r.state),
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms - ARM",
      inputs: [
        firstRateChangeDate,
        indexSourceType,
        capitalStructureType,
        armRepriceRoundingRatePercentType,
        armRepriceRoundingMethodType,
        armRepriceLookbackType,
        armInitialFixedTermMonthCount,
        armFinalAdjustableRateTermMonthCount,
        armRepriceFrequencyType,
        margin,
        floorPercent,
        lifetimeCapPercent
      ],
      fieldSpecs
    });

const loanTermsCustomSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms - Custom",
      inputs: [
        uccFiledIndicator,
        uccExpirationDate,
        lateFeePercent,
        grossSpreadPercent,
        letterOfIntentSignedDate
      ],
      fieldSpecs
    });

const propertyManagementQuestionnaireSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      isHidden: hideRental30(r.state),
      state: r.state,
      onChange: r.onChange,
      header: "Property Management Questionnaire",
      inputs: [
        propertyManagementFullName,
        propertyManagementRepresentativeFullName,
        propertyManagementFullAddressName,
        propertyManagementContactPointTelephoneValue,
        propertyManagementContactPointEmailValue,
        propertyManagementComments
      ],
      fieldSpecs
    });

const titleCompanyClosingAttorneySection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Title Company",
      inputs: [
        titleInsuranceUnderwriter,
        titleCompanyName,
        titleCompanyClosingAgentFirstName,
        titleCompanyClosingAgentLastName,
        titleCompanyClosingAgentContactPointTelephone,
        titleCompanyClosingAgentContactPointEmail,
        titleCompanyAddressLineText,
        titleCompanyAddressUnitIdentifier,
        titleCompanyCityName,
        titleCompanyStateCode,
        titleCompanyPostalCode
      ],
      fieldSpecs
    });

const generalContractorSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "General Contractor",
      inputs: [
        generalContractorFullName,
        generalContractorRepresentativeFirstName,
        generalContractorRepresentativeLastName,
        generalContractorContactPointTelephone,
        generalContractorContactPointEmail
      ],
      fieldSpecs
    });

const brokerSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Broker",
      inputs: [
        brokerRepresentativeFirstName,
        brokerRepresentativeLastName,
        brokerFullName,
        brokerContactPointEmailValue,
        brokerContactPointTelephone,
        nmlsIdentifier,
        brokerWiringInstructionsIndicator
      ],
      fieldSpecs
    });

const lenderFinanceSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Lender Finance",
      inputs: [
        lenderFinancePartnerFirstName,
        lenderFinancePartnerLastName,
        lenderFinancePartnerInitialTermSheetApprovalStatusIndicator,
        lenderFinancePartnerInitialTermSheetApprovalStatusDate,
        lenderFinancePartnerFinalTermSheetApprovalStatusDate,
        lenderFinanceBorrowerFinalTermSheetApprovalStatusDate
      ],
      fieldSpecs
    });

const closingLoanTermsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms",
      inputs: [
        addRuleToInput({
          input: loanProductType,
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () => !hideFixNFlip(r.state)
            }
          ]
        }),
        amortizationType,
        noteRatePercent,
        estimatedClosingDate,
        rateLockStartDate,
        rateLockEndDate,
        wireReleaseDate,
        scheduledFirstPaymentDate,
        paymentDueDay,
        loanMaturityDate,
        loanTermPeriodMonthCount,
        ...addRulesToInputs({
          inputs: [
            loanAmortizationPeriodMonthCount,
            loanInterestOnlyPeriodMonthCount,
            loanAfterInterestOnlyPeriodMonthCount,
            interestAccrualMethodType,
            loanPaymentType,
            totalMonthlyPITIPaymentAmount
          ],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        monthlyPrincipalInterestPaymentAmount,
        initialMonthlyInterestOnlyPaymentAmount,
        addRuleToInput({
          input: fullyDrawnInterestOnlyPayment,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        ...addRulesToInputs({
          inputs: [
            monthlyTaxInsurancePaymentAmount,
            monthlyHomeownersAssociationAmount
          ],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        addRuleToInput({
          input: executionType,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        ...addRulesToInputs({
          inputs: [prepaymentPenaltyType, prepaymentPenaltyExpirationDate],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        lenderIdentifier,
        addRuleToInput({
          input: recourseType,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        })
      ],
      fieldSpecs
    });

const closingLoanTermsCustomSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms - Custom",
      inputs: [
        uccFiledIndicator,
        uccExpirationDate,
        defaultInterestRatePercent,
        lateFeePercent,
        releasePercent,
        grossSpreadPercent,
        gracePeriodDays,
        letterOfIntentSignedDate
      ],
      fieldSpecs
    });

const ledgerSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Ledger",
      inputs: [
        totalOutstandingLoanPayoffAmount,
        totalPurchasePriceAmount,
        downPaymentAmount,
        originationFeeAmount,
        deferredOriginationFeeAmount,
        creditReportFeeAmount,
        processingFeeAmount,
        lenderCounselFeeAmount,
        buyDownFeeAmount,
        buyUpFeeReductionAmount,
        brokerOriginationFeeAmount,
        brokerProcessingFeeAmount,
        totalBrokerFeeAmount,
        ...addRulesToInputs({
          inputs: [contractorReviewFeeAmount, feasibilityReviewFeeAmount],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        condoCertificationFeeAmount,
        interestReserveEscrow,
        newYorkProcessingFeeAmount,
        chargentCollectionAmount,
        prepaidInterestAmount,
        perDiemPrepaidInterestAmount,
        servicingSetupFee,
        marketingPromotionCreditAmount,
        marketingPromotionCode,
        lenderFinanceProcessingFee,
        lenderFinanceApplicationFee,
        lenderFinanceDocumentFee,
        lenderFinanceAppraisalFee
      ],
      fieldSpecs
    });

const escrowsImpoundsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Escrows/Impounds",
      inputs: [
        propertyInsurancePremiumPaymentType,
        totalPropertyInsurancePremiumOutstandingAmount,
        propertyInsuranceAgencyFullName,
        floodInsurancePremiumPaymentType,
        totalFloodInsurancePremiumOutstandingAmount,
        floodInsuranceAgencyFullName,
        ...addRulesToInputs({
          inputs: [
            totalInitialInsuranceEscrowAmount,
            totalInitialTaxEscrowAmount,
            interestReserveDepositAmount,
            deferredMaintenanceAmount
          ],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        addRuleToInput({
          input: totalBudgetAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        })
      ],
      fieldSpecs
    });

const titleCommitmentSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Title Commitment",
      inputs: [
        titleCommitmentTitleEffectiveDate,
        titleCommitmentTitleExpirationDate,
        titleCommitmentOwnership,
        titleCommitmentTitlePolicyAmount,
        addRuleToInput({
          input: titleCommitmentTitleVestingType,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        titleCommitmentAllSubjectPropertiesIncludedIndicator,
        titleCommitmentAllPayoffsLiensIncludedInSection1RequirementsIndicator,
        titleCommitmentRequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator,
        titleCommitmentTitleInternationalExecutionIndicator,
        addRuleToInput({
          input: titleCommitmentLeaseholdEndorsementIndicator,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        titleCommitmentComments
      ],
      fieldSpecs
    });

const settlementStatementSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Settlement Statement",
      inputs: [
        totalLiquidAssetAmount,
        settlementStatementMatchLedgerIndicator,
        loanAmountVerifiedMatchIndicator,
        addRuleToInput({
          input: constructionBudgetVerifiedMatchIndicator,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        settlementStatementComments,
        cashFromBorrowerAmount,
        cashToBorrowerAmount
      ],
      fieldSpecs
    });

const titleFormsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Title Forms",
      inputs: [
        eoExpirationDate,
        eoNamedInsuredMatchIndicator,
        wireABARoutingNumber,
        wireAccountNumber,
        wireBankName,
        wireAccountName,
        wireInsuranceMatchDealIndicator,
        titleFormsComments
      ],
      fieldSpecs
    });

const preFundingSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Pre-Funding",
      inputs: [
        netWireAmount,
        estimatedClosingDate,
        wireReleaseDate,
        warehouseBankType,
        warehouseBankStatusType,
        fundingShieldStatusType,
        haircutAmount,
        postClosingNotes,
        mailAwayIndicator
      ],
      fieldSpecs
    });

const promissoryNoteWarehouseTrackingSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Promissory Note - Warehouse Tracking",
      inputs: [
        warehouseBankType,
        closingAgentFirstName,
        closingAgentLastName,
        promissoryNoteComments,
        shippingCompanyType,
        settlementStatementFileNumber,
        personalGuarantorsCount,
        powerOfAttorneyFullName,
        loanProgramType,
        loanPackageNoteTrackingNumber
      ],
      fieldSpecs
    });

const promissoryNoteMFASection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Promissory Note - MFA",
      inputs: [
        promissoryNoteRequestedIndicator,
        promissoryNoteShippedIndicator,
        promissoryNoteTrackingNumber
      ],
      fieldSpecs
    });

const userAssignmentSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "User Assignment",
      inputs: [notaryExpirationDate, closingAnalystUserId, notaryUserId],
      fieldSpecs
    });

const custodianCollateralTrackingSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Allonge and Assignment",
      inputs: [
        allongeAssignmentSentToWarehouseIndicator,
        allongeAssignmentTrackingNumber
      ],
      fieldSpecs
    });

const electronicExecutedLoanPackageSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Electronic Executed Loan Package",
      inputs: [
        loanPackageTrackingNumber,
        loanPackageNoteTrackingNumber,
        fundingAndDisbursementApprovalIndicator,
        loanPackageInternationalExecutionIndicator,
        warehouseBankStatusType,
        achElectedIndicator,
        achRequiredIndicator,
        achExemptionStatus,
        sellerHUDAddedIndicator,
        purchaserWarrantyDeedAddedIndicator,
        fundingAgentEmailAddress
      ],
      fieldSpecs
    });

const physicalExecutedLoanPackageSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Physical Executed Loan Package",
      inputs: [
        loanPackageTrackingNumber,
        loanPackageNoteReceivedByLenderIndicator,
        personalGuarantorsCount,
        loanPackageChecksReceivedIndicator,
        loanPackageCheckNumber,
        loanPackageCheckAmount,
        recordedMortgageReceivedDate,
        titlePolicyReceivedDate,
        recordedWarrantyDeedReceivedDate
      ],
      fieldSpecs
    });

export const dealSectionBuilders = {
  loanInformationSection,
  dealTotalsSection,
  loanTermsSection,
  loanTermsARMSection,
  loanTermsCustomSection,
  propertyManagementQuestionnaireSection,
  titleCompanyClosingAttorneySection,
  generalContractorSection,
  brokerSection,
  lenderFinanceSection,
  closingLoanTermsSection,
  closingLoanTermsCustomSection,
  ledgerSection,
  escrowsImpoundsSection,
  titleCommitmentSection,
  settlementStatementSection,
  titleFormsSection,
  preFundingSection,
  promissoryNoteWarehouseTrackingSection,
  promissoryNoteMFASection,
  userAssignmentSection,
  custodianCollateralTrackingSection,
  electronicExecutedLoanPackageSection,
  physicalExecutedLoanPackageSection
};
