import { Box, BoxProps, Button } from "@chakra-ui/react";
import { DevFeatureFlag } from "@elphi/utils";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { GateKeeper } from "../../features/gate/GateKeeper";
import { useInsurancePolicyModalsHooks } from "./insurancePolicyModal.hooks";
import { PropertyInsurancePolicyModal } from "./property/PropertyInsurancePolicyModal";

export const CreateInsurancePolicyButton = (props: {
  dealId: string;
  buttonWrapper?: BoxProps;
}) => {
  const {
    isInsurancePolicyCreateModalOpen,
    onToggleInsuranceCreatePolicyModal
  } = useInsurancePolicyModalsHooks();

  const { buttonWrapper = {} } = props;
  return (
    <GateKeeper gate={DevFeatureFlag.ESD_2792_InsurancePolicy}>
      <Box {...buttonWrapper}>
        <Button
          {...elphiTheme.components.light.button.primary}
          onClick={onToggleInsuranceCreatePolicyModal}
        >
          Add Insurance Policy
        </Button>
      </Box>
      <PropertyInsurancePolicyModal
        showDomainStep={true}
        isOpen={isInsurancePolicyCreateModalOpen}
        onClose={onToggleInsuranceCreatePolicyModal}
        dealId={props.dealId}
      />
    </GateKeeper>
  );
};
