import { EntityState } from "@reduxjs/toolkit";
// Need to use the React-specific entry point to allow generating React hooks
import { DealProperty } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { RootState } from "../../store";
import serviceBuilder from "../builders/api.builder";
import { dealPropertyEntityAdapter } from "./dealPropertyRelation.adapter";

export const dealPropertyRelationSelector =
  dealPropertyEntityAdapter.getSelectors<RootState>(
    (state) => state.dealPropertyRelation
  );

// Define a service using a base URL and expected endpoints
export const dealPropertyRelationBaseApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "dealPropertyRelationApi",
  tagTypes: ["Deal-Property-Relation", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "deal-property"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: dealPropertyEntityAdapter
  })
});

export const dealPropertyRelationApi =
  dealPropertyRelationBaseApi.injectEndpoints({
    endpoints: (builder) => ({
      dealPropertyBatch: builder.query<
        { relations: EntityState<DealProperty> },
        string[]
      >({
        query: (ids) => {
          return {
            url: `/get-batch`,
            method: "POST",
            body: { ids }
          };
        },
        transformResponse: (response: { results: DealProperty[] }) => {
          const adapter = dealPropertyEntityAdapter.addMany(
            dealPropertyEntityAdapter.getInitialState(),
            response.results
          );
          return { relations: adapter };
        }
      }),
      children: builder.query<EntityState<DealProperty>, string>({
        query: (id) => {
          return {
            url: `/${id}`,
            method: "GET"
          };
        },
        transformResponse: (response: DealProperty[]) => {
          const adapter = dealPropertyEntityAdapter.addMany(
            dealPropertyEntityAdapter.getInitialState(),
            response
          );
          return adapter;
        }
      })
    })
  });
