import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import { getFocusedData } from "@elphi/aggregations/src/utils/aggregation.utils";
import {
  BooleanSelectType,
  CreditReportPOSTV2,
  CreditReportPullType,
  CreditReportVendorType,
  CreditScore,
  IndividualParty,
  IntegrationType,
  Party,
  Task,
  getCreditReportRequestTemplate,
  individualToCreditScorePOSTMap
} from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import lodash from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppResponse } from "../../apis/appResponse.types";
import { responseHandler } from "../../apis/rtk/response.handler";
import { SendIcon } from "../../assets/icons";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { AppConfig } from "../../config/appConfig";
import { useSections } from "../../forms/schemas/providers/SectionsProvider";
import { useCreditReportHooks } from "../../hooks/creditReport.hooks";
import { usePartyHooks } from "../../hooks/party.hooks";
import { PartyStructureState } from "../../hooks/partyform.hooks";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import useTaskHooks from "../../hooks/task.hooks";
import { RootState } from "../../redux/store";
import { CreditReportSliceState } from "../../redux/v2/credit-report";
import { creditReportApi } from "../../redux/v2/credit-report/creditReport.service";
import ScrollableSections from "../ScrollableSections";
import FormBuilder, {
  OnChangeInput,
  SectionHeader
} from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { TaskFileManager } from "../task/TaskFileManager";
import { TaskFormState } from "../task/TaskForm";
import { useElphiToast } from "../toast/toast.hook";
import { CreateCreditReportModal } from "./CreateCreditReportModal";
import { CreditReportHistoryTableContainer } from "./CreditReportHistoryTable";

export type CreditScoreStructure = {
  creditScores: { [id: string]: CreditScore };
};

export type CreditReportTaskFormProps = {
  snapshotId?: string;
  selectedTask?: Task;
  state?: TaskFormState;
  onChange?: Function;
  setTabIndex: Function;
};

export const CreditReportTaskFormContainer = (
  props: CreditReportTaskFormProps
) => {
  return props.snapshotId ? (
    <CreditReportTaskFormSnapshotContainer {...props} />
  ) : (
    <CreditReportTaskFormLiveStateContainer {...props} />
  );
};

export const CreditReportTaskFormSnapshotContainer = (
  props: CreditReportTaskFormProps
) => {
  const { snapshotId, state, onChange, setTabIndex } = props;
  const { snapshotDataState } = useSnapshotHooks();
  const { taskState } = useTaskHooks();
  const snapshot = snapshotDataState({ snapshotId: String(snapshotId) });
  return (
    <CreditReportTaskForm
      snapshotId={snapshotId}
      state={state}
      onChange={onChange}
      setTabIndex={setTabIndex}
      selectedTask={
        (taskState?.selectedId &&
          snapshot.taskState &&
          snapshot.taskState.entities[taskState.selectedId]) ||
        undefined
      }
      creditScoreState={snapshot.creditReportState}
      partyReduxState={snapshot.partyState}
    />
  );
};
export const CreditReportTaskFormLiveStateContainer = (
  props: CreditReportTaskFormProps
) => {
  const { snapshotId, selectedTask, state, onChange, setTabIndex } = props;

  const creditScoreState = useSelector(
    (state: RootState) => state.creditReport
  );
  const { partyState: partyReduxState, updateBatchParties } = usePartyHooks();
  const [getLatestCreditScore] =
    creditReportApi.useLazyGetLatestCreditScoreQuery();
  const [updateBatchCreditScoresApi] = creditReportApi.useBatchUpdateMutation();

  return (
    <CreditReportTaskForm
      snapshotId={snapshotId}
      selectedTask={selectedTask}
      state={state}
      onChange={onChange}
      setTabIndex={setTabIndex}
      creditScoreState={creditScoreState}
      partyReduxState={partyReduxState}
      updateBatchParties={updateBatchParties}
      getLatestCreditScore={getLatestCreditScore}
      updateBatchCreditScoresApi={updateBatchCreditScoresApi}
    />
  );
};

export const CreditReportTaskForm = (props: {
  snapshotId?: string;
  selectedTask?: Task;
  state?: TaskFormState;
  setTabIndex?: Function;
  onChange?: Function;
  creditScoreState?: CreditReportSliceState;
  partyReduxState?: EntityState<Party>;
  updateBatchParties?: Function;
  updateBatchCreditScoresApi?: Function;
  getLatestCreditScore?: Function;
}) => {
  const {
    selectedTask,
    state,
    snapshotId,
    setTabIndex,
    creditScoreState,
    partyReduxState,
    updateBatchParties,
    getLatestCreditScore,
    updateBatchCreditScoresApi
  } = props;

  const selectedParty =
    selectedTask?.entityId &&
    (partyReduxState?.entities[selectedTask?.entityId] as IndividualParty);
  const { errorToast, successToast } = useElphiToast();
  const updateBatchCreditScores = async (newCreditScores: {
    creditScores: ({ id: string } & Partial<CreditScoreStructure>)[];
  }) => {
    return (
      updateBatchCreditScoresApi &&
      (await updateBatchCreditScoresApi(newCreditScores).then(responseHandler))
    );
  };
  const [selectedCreditScoreId, setSelectedCreditScoreId] = useState("");
  const { sections } = useSections();

  const getLatestScore = (
    selectedParty: IndividualParty,
    setSelectedCreditScore: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (!selectedParty) setSelectedCreditScore("");
    else if (
      creditScoreState?.entities?.[selectedCreditScoreId]?.latest !==
      BooleanSelectType.true
    ) {
      getLatestCreditScore &&
        getLatestCreditScore(selectedParty.id).then((score) => {
          if (score.data) setSelectedCreditScore(score.data.id);
          else {
            setSelectedCreditScore("");
          }
        });
    }
  };

  useEffect(() => {
    if (props.snapshotId) {
      const selectedCreditScore =
        (selectedParty &&
          Object.values(creditScoreState?.entities || {}).filter(
            (entity) =>
              entity &&
              entity.partyId === selectedParty.id &&
              entity.latest === BooleanSelectType.true
          )?.[0]?.id) ||
        "";
      setSelectedCreditScoreId(selectedCreditScore);
    } else {
      selectedParty && getLatestScore(selectedParty, setSelectedCreditScoreId);
    }
  }, [selectedParty, creditScoreState]);

  const updatePartiesHandler = async (diff: Partial<PartyStructureState>) => {
    if (!diff.parties) return null;
    const parties = Object.keys(diff.parties)
      .map((id) => {
        if (diff.parties && diff.parties[id]) {
          return {
            ...diff.parties[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!parties.length) return null;
    return (
      updateBatchParties &&
      (await updateBatchParties({ parties } as {
        parties: ({
          id: string;
        } & Partial<Party>)[];
      }).then((r) => {
        if (r.status === 200) {
          successToast({
            title: "Parties Updated",
            description: `${r.data.batch.length} parties updated`
          });
        }
        if (r.status === 400) {
          errorToast({
            title: "Failed to update parties",
            description: r.data.description
          });
        }
        return r;
      }))
    );
  };

  const updateCreditScoresHandler = async (
    diff: Partial<CreditScoreStructure>
  ) => {
    if (!diff.creditScores) return null;
    const creditScores = Object.keys(diff.creditScores)
      .map((id) => {
        if (diff.creditScores && diff.creditScores[id]) {
          return {
            ...diff.creditScores[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!creditScores.length) return null;
    return await updateBatchCreditScores({ creditScores } as {
      creditScores: ({
        id: string;
      } & Partial<CreditScore>)[];
    }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Credit Scores Updated",
          description: `${r.data.batch.length} scores updated`
        });
      }
      if (r.status === 400) {
        errorToast({
          title: "Failed to update credit scores",
          description: r.data.description
        });
      }
      return r;
    });
  };

  const {
    onChange: partyOnChange,
    state: partyState,
    syncState: syncPartyState
  } = useFormBuilderStateHandler({
    initialState: { parties: {} },
    callback: updatePartiesHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const {
    onChange: creditScoreOnChange,
    state: localCreditScoreState,
    syncState: syncCreditScoreState
  } = useFormBuilderStateHandler({
    initialState: { creditScores: {} },
    callback: updateCreditScoresHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    selectedParty &&
      syncPartyState({
        shouldSync: !!selectedParty,
        state: selectedParty,
        statePath: () => {
          if (selectedParty) {
            return ["parties", selectedParty.id];
          }
        }
      });
  }, [selectedParty]);

  useEffect(() => {
    if (creditScoreState) {
      syncCreditScoreState({
        shouldSync: !!creditScoreState,
        state: creditScoreState.entities,
        statePath: () => {
          return ["creditScores"];
        }
      });
    }
  }, [creditScoreState]);

  const partyOnChangeBatch = (v: OnChangeInput) => {
    selectedParty &&
      !snapshotId &&
      partyOnChange({
        fieldType: v.fieldType,
        fieldKey: ["parties", selectedParty.id, ...v.fieldKey],
        value: v.value
      });
  };
  const creditScoreOnChangeBatch = (v: OnChangeInput) => {
    selectedCreditScoreId &&
      !snapshotId &&
      creditScoreOnChange({
        fieldType: v.fieldType,
        fieldKey: ["creditScores", selectedCreditScoreId, ...v.fieldKey],
        value: v.value
      });
  };

  const selectedPartyState =
    (selectedParty &&
      (partyState.parties[selectedParty.id] as IndividualParty)) ||
    ({} as IndividualParty);

  const selectedCreditScoreState =
    (selectedCreditScoreId &&
      (localCreditScoreState.creditScores[
        selectedCreditScoreId
      ] as CreditScore)) ||
    undefined;

  const selectedReduxCreditScoreState =
    (selectedCreditScoreId &&
      (creditScoreState?.entities?.[selectedCreditScoreId] as CreditScore)) ||
    undefined;

  const creditReportSection = selectedCreditScoreState
    ? sections?.integrations?.creditReport?.[IntegrationType.CreditReport]?.[
        CreditReportVendorType.Lima
      ]?.creditReportSection?.({
        state: selectedCreditScoreState,
        onChange: creditScoreOnChange
      })
    : undefined;

  const creditReportBorrowerSections = selectedPartyState
    ? sections?.integrations?.creditReport?.[IntegrationType.CreditReport]?.[
        CreditReportVendorType.Lima
      ]?.creditReportOrderSection?.({
        state: selectedPartyState,
        onChange: partyOnChangeBatch
      })
    : undefined;

  if (selectedTask && selectedParty) {
    return (
      <Tabs w="100%" onChange={(index) => setTabIndex?.(index)}>
        <TabList background="white" position="sticky" top="0">
          <Tab>Data</Tab>
          <Tab>History</Tab>
        </TabList>
        <TabPanels h="100%" w="100%" p="0">
          <TabPanel h="100%" w="100%" p="0">
            <Box>
              <TaskFileManager
                selectedTask={selectedTask}
                snapshotId={props.snapshotId}
              />
            </Box>
            <Box>
              <ScrollableSections
                customKey={"actionsCreditReportTask"}
                sections={[
                  {
                    header: <SectionHeader header="Actions" />,
                    body: (
                      <Flex flexDirection={"row"}>
                        <PullCreditReportModal
                          party={selectedParty}
                          task={selectedTask}
                          creditScore={selectedCreditScoreState}
                          getLatestScore={() =>
                            getLatestScore(
                              selectedParty,
                              setSelectedCreditScoreId
                            )
                          }
                        />
                        <Box ml={"20px"}>
                          <CreateCreditReportModal
                            party={selectedParty}
                            getLatestScore={() =>
                              getLatestScore(
                                selectedParty,
                                setSelectedCreditScoreId
                              )
                            }
                          />
                        </Box>
                      </Flex>
                    )
                  }
                ]}
              />
              {state?.tasks && (
                <FormBuilder
                  customKey="creditReportBorrowerSections"
                  isDisabled={!!snapshotId}
                  size={{
                    minW: "300px"
                  }}
                  onChange={partyOnChangeBatch}
                  sections={[creditReportBorrowerSections]}
                />
              )}
              {selectedReduxCreditScoreState && state?.tasks ? (
                <FormBuilder
                  customKey="creditReportSections"
                  isDisabled={!!snapshotId}
                  size={{
                    minW: "300px"
                  }}
                  onChange={creditScoreOnChangeBatch}
                  sections={[creditReportSection]}
                />
              ) : (
                <Box m={"30px"}>
                  <CreateCreditReportModal
                    party={selectedParty}
                    getLatestScore={() =>
                      getLatestScore(selectedParty, setSelectedCreditScoreId)
                    }
                  />
                </Box>
              )}
            </Box>
          </TabPanel>
          <TabPanel background={"white"}>
            <CreditReportHistoryTableContainer
              getLatestScore={() =>
                getLatestScore(selectedParty, setSelectedCreditScoreId)
              }
              selectedParty={selectedParty}
              snapshotId={props.snapshotId}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }
  return <></>;
};

export const PullCreditReportModal = (props: {
  dealId?: string;
  propertyId?: string;
  partyId?: string;
  isDisabled?: boolean;
  party?: Partial<IndividualParty>;
  task?: any;
  creditScore?: Partial<CreditScore>;
  getLatestScore: Function;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { errorToast, successToast } = useElphiToast();
  const { party } = props;
  const { getPartyCreditScores } = useCreditReportHooks();
  const creditReportState = useSelector(
    (state: RootState) => state.creditReport
  );

  const [orderCreditScoreApi, orderCreditScoreResponse] =
    creditReportApi.useGetCreditScoreMutation();
  const { uploadMultipleTaskFilesApi, uploadMultipleTaskFilesApiResponse } =
    useTaskHooks();
  const orderCreditScoreResponseIsLoading =
    orderCreditScoreResponse.isLoading ||
    uploadMultipleTaskFilesApiResponse.isLoading;

  const orderCreditScore = async (r: { party: Partial<IndividualParty> }) => {
    const body: CreditReportPOSTV2 = getCreditReportRequestTemplate();
    Object.keys(individualToCreditScorePOSTMap).map((key) => {
      lodash.set(
        body,
        individualToCreditScorePOSTMap[key],
        lodash.get(r.party, key, "")
      );
    });
    body.party.borrowerSSN = body.party.borrowerSSN.split("-").join("");
    body.creditReportType =
      r.party.CreditReportPullType === CreditReportPullType.Soft
        ? "Other"
        : "Merge";
    body.creditReportTypeOtherDescription =
      r.party.CreditReportPullType === CreditReportPullType.Soft
        ? "Prequal"
        : "";

    if (r.party.id) {
      const fullResponse = {
        creditScoreResponse: {} as AppResponse<{
          creditScore: CreditScore;
          pdf: { type: string; data: Buffer };
        }>,
        creditReportResponse: {} as AppResponse<any>
      };
      fullResponse.creditScoreResponse = responseHandler(
        await orderCreditScoreApi({
          body: body,
          partyId: r.party.id
        })
      );
      if (fullResponse.creditScoreResponse.status === 200) {
        const file = new File(
          [
            new Blob([
              new Uint8Array(fullResponse.creditScoreResponse.data.pdf.data)
                .buffer
            ])
          ],
          `CreditReport${new Date().toISOString()}.pdf`
        );
        await uploadMultipleTaskFilesApi({
          files: file,
          taskId: props.task.id,
          folderId: props.task.storageMeta.box.folderId
        });
      }
      return fullResponse;
    }
    return;
  };

  useEffect(() => {
    if (party && party.id) {
      getPartyCreditScores(party.id);
    }
  }, [party]);

  const currentCreditScore = lodash.orderBy(
    Object.values(creditReportState.entities).filter(
      (e) =>
        e?.pullType === party?.CreditReportPullType && e?.partyId === party?.id
    ),
    ["createdAt._seconds"],
    ["desc"]
  )?.[0] as CreditScore;

  const currentCreditScoreDate =
    lodash.maxBy(
      [
        getFocusedData(currentCreditScore?.equifax?.date),
        getFocusedData(currentCreditScore?.experian?.date),
        getFocusedData(currentCreditScore?.transunion?.date)
      ],
      (d) => d && new Date(d)
    ) || "N/A";

  return (
    <Box>
      <Tooltip
        label={
          !party?.CreditReportPullType
            ? "Please choose between hard/soft pull"
            : ""
        }
      >
        <Box>
          <Button
            leftIcon={SendIcon}
            onClick={onOpen}
            isDisabled={!party?.CreditReportPullType}
            {...elphiTheme.components.light.button.success}
          >
            Order Report
          </Button>
        </Box>
      </Tooltip>{" "}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>
            Credit Report {party?.CreditReportPullType} Pull for{" "}
            {party?.FirstName} {party?.LastName}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              Are you sure you want to order a Credit Report{" "}
              {party?.CreditReportPullType} Pull for {party?.FirstName}{" "}
              {party?.LastName}? The last {party?.CreditReportPullType} Pull was
              ordered on{" "}
              {currentCreditScoreDate === "N/A"
                ? "N/A"
                : new Date(
                    currentCreditScoreDate + "T00:00-0800"
                  ).toLocaleDateString("en-US")}
              <Spacer mb="50px" />
              <Flex justifyContent={"space-between"}>
                <Button
                  {...elphiTheme.components.light.button.primary}
                  isLoading={orderCreditScoreResponseIsLoading}
                  onClick={async () => {
                    if (party) {
                      await orderCreditScore({ party }).then((r) => {
                        if (r) {
                          if (r.creditScoreResponse) {
                            if (r.creditScoreResponse.status === 200) {
                              successToast({
                                title: "Credit report information received",
                                description: ``
                              });
                            }
                            if (r.creditScoreResponse.status === 400) {
                              errorToast({
                                title: "Credit report  wasn't received",
                                description:
                                  r.creditScoreResponse.data.description
                              });
                            }
                          }
                          if (r.creditReportResponse) {
                            if (r.creditReportResponse.status === 200) {
                              successToast({
                                title: "Credit report file received",
                                description: ``
                              });
                            }
                            if (r.creditReportResponse.status === 400) {
                              errorToast({
                                title: "Credit report file wasn't received",
                                description:
                                  r.creditReportResponse.data.description
                              });
                            }
                          }
                        }
                        return r;
                      });
                    }
                    await props.getLatestScore();
                    onClose();
                  }}
                >
                  Yes, I am sure. Order {party?.CreditReportPullType} Pull
                </Button>
                <Button
                  {...elphiTheme.components.light.button.generate}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Flex>
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
