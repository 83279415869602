import { Center, Flex, FlexProps, Text, TextProps } from "@chakra-ui/react";
import { isString } from "lodash";
import React from "react";
import { NOT_AVAILABLE } from "../../constants/common";
import {
  LargeBoxCell,
  MediumBoxCell,
  SmallBoxCell,
  TinyBoxCell
} from "../custom/chakra/Cell";

export enum SIZE_FIELD {
  T = "tiny",
  S = "small",
  M = "medium",
  L = "large"
}

const sizeComponent = {
  [SIZE_FIELD.T]: TinyBoxCell,
  [SIZE_FIELD.S]: SmallBoxCell,
  [SIZE_FIELD.M]: MediumBoxCell,
  [SIZE_FIELD.L]: LargeBoxCell
};

export type BaseRowSizeProps = {
  size?: SIZE_FIELD;
  label?: string | React.ReactElement;
  data?: string | React.ReactElement;
  textStyle?: TextProps;
};

export type TableRowSizeComponenProps<T extends BaseRowSizeProps> = {
  row?: T[];
  size?: SIZE_FIELD;
  children?: React.ReactElement;
  withBorderRightWidth?: boolean;
} & FlexProps;

export const TableRowSizeComponent = <T extends BaseRowSizeProps>({
  row = [],
  withBorderRightWidth = true,
  children,
  size,
  ...flexProps
}: TableRowSizeComponenProps<T>) => {
  return children ? (
    <SizeComponent size={size} withBorderRightWidth={withBorderRightWidth}>
      {children}
    </SizeComponent>
  ) : (
    <Flex w="100%" bgColor="gray.300" {...flexProps}>
      {row.map(({ size, label, data, textStyle }, index: number) => {
        const body = label || data || "";
        return (
          <SizeComponent
            key={index}
            size={size}
            withBorderRightWidth={withBorderRightWidth}
          >
            {isString(body) ? (
              <Center marginY={1}>
                <Text fontSize="16px" fontWeight="bold" {...textStyle}>
                  {body}
                </Text>
              </Center>
            ) : (
              body
            )}
          </SizeComponent>
        );
      })}
    </Flex>
  );
};

export const TableTextCell = (props: { text?: string | number }) => {
  return (
    <Center my={1}>
      <Text fontSize={"16px"} fontWeight={"300"}>
        {props.text || NOT_AVAILABLE}
      </Text>
    </Center>
  );
};

const SizeComponent = ({
  size = SIZE_FIELD.L,
  withBorderRightWidth = true,
  children
}: {
  size?: SIZE_FIELD;
  withBorderRightWidth?: boolean;
  children: React.ReactElement;
}) => {
  const BoxCell = sizeComponent[size];

  return (
    <BoxCell
      borderRightWidth={withBorderRightWidth ? 1 : 0}
      alignSelf={"center"}
    >
      {children}
    </BoxCell>
  );
};
