import { BaseSpecs } from "../../base/types/baseSchema.types";
import { injectSpecToBuilder } from "../../utils/formBuilder.utils";
import { DealIntegrationsSections } from "../types/dealIntegrations.types";
import { PropertyIntegrationsSections } from "../types/propertyIntegrations.types";
import { floodSectionBuilders } from "./flood/flood.sections";
import { siteXSectionBuilders } from "./property-report/siteX.sections";
import { rentRangeSectionBuilders } from "./rental-data/rentRange.section";
import { wireInsuranceSectionBuilders } from "./wire-insurance/fundingShield.section";

export const getPropertyIntegrationsSections = (
  spec?: BaseSpecs["property"]["entitySpecs"]
) => {
  const serviceLinkFloodSections = injectSpecToBuilder<
    PropertyIntegrationsSections["FloodCertification"]["ServiceLink"]
  >({
    builders: floodSectionBuilders,
    spec
  });

  const siteXPropertyReportSections = injectSpecToBuilder<
    PropertyIntegrationsSections["PropertyReport"]["SiteX"]
  >({
    builders: siteXSectionBuilders,
    spec
  });

  const rentRangeRentalDataSections = injectSpecToBuilder<
    PropertyIntegrationsSections["RentalData"]["RentRange"]
  >({
    builders: rentRangeSectionBuilders,
    spec
  });

  return {
    serviceLinkFloodSections,
    siteXPropertyReportSections,
    rentRangeRentalDataSections
  };
};

export const getDealIntegrationsSections = (
  spec?: BaseSpecs["deal"]["entitySpecs"]
) => {
  const FundingShieldWireInsuranceCertificateSections =
    injectSpecToBuilder<
      DealIntegrationsSections["WireInsuranceCertificate"]["FundingShield"]
    >({
      builders: wireInsuranceSectionBuilders,
      spec
    }) || {};

  return { FundingShieldWireInsuranceCertificateSections };
};
