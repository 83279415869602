import {
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { BusinessFeatureFlag } from "@elphi/utils";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import elphiLogo from "../../../../assets/img/elphi/elphiLogoOutlined.svg";
import { useElphiToast } from "../../../../components/toast/toast.hook";
import { auth as firebaseAuth } from "../../../../firebase/firebaseConfig";
import PathVariables from "../../../../routes/pathVariables";
import { useAuth } from "../../../../utils/authUtils";
import { getOktaUrl } from "../../../../utils/envUtils";
import { GateKeeper } from "../../../gate/GateKeeper";
import {
  AuthErrorType,
  authErrorMessages,
  firebaseAuthErrorMap
} from "./loginErrors";

const oktaLoginEmailsUsers = ["limaonecapital"];

export const LoginPage = () => {
  const auth = useAuth();
  const { orgId } = useParams();
  const { successToast, errorToast } = useElphiToast();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<AuthErrorType | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const shouldSignInWithOkta = oktaLoginEmailsUsers.some((x) =>
    email.includes(x)
  );

  const signInWithEmailAndPassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (!email || !password) {
      return;
    }

    setIsLoading(true);
    try {
      await auth.signin(email, password);
      navigate(PathVariables.SelectOrganization, { replace: true });
    } catch (error: any) {
      const errorCode =
        firebaseAuthErrorMap[error.code] ?? AuthErrorType.InternalServerError;
      setError(errorCode);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {
    if (email) {
      firebaseAuth
        .sendPasswordResetEmail(email)
        .then(() => {
          successToast({
            title: "Reset Password",
            description: "Reset email sent"
          });
        })
        .catch((err) => {
          console.error(err);
          errorToast({
            title: "Reset Password",
            description: `Failed to reset password: ${err.message}`
          });
        });
    }
  };

  useEffect(() => {
    if (auth?.instance?.currentUser && !orgId) {
      navigate(PathVariables.SelectOrganization, { replace: true });
    }
  }, [auth?.instance?.currentUser]);

  useEffect(() => {
    if (shouldSignInWithOkta) {
      setError(AuthErrorType.MustUseOkta);
    } else {
      setError(undefined);
    }
  }, [shouldSignInWithOkta]);

  const handleFocus = () => {
    error && error !== AuthErrorType.MustUseOkta && setError(undefined);
  };

  const isDisable = !email || !password || isLoading || !!error;
  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      justifyContent="center"
      bg="gray.50"
    >
      <Box
        rounded="lg"
        boxShadow="lg"
        bg="white"
        p={8}
        width={{ base: "90%", md: "25rem" }}
      >
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          position="relative"
          mb={4}
        >
          <Image src={elphiLogo} alt="Elphi Logo" width="10rem" />
          <Text
            fontSize="xl"
            textAlign="center"
            fontWeight="500"
            position="absolute"
            bottom={0}
          >
            LOS Login
          </Text>
        </Flex>
        <Box as="form" onSubmit={signInWithEmailAndPassword}>
          <FormControl id="email" mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value.toLowerCase());
              }}
              onFocus={handleFocus}
              placeholder="you@example.com"
              isRequired
            />
          </FormControl>
          <FormControl id="password" mb={4}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              onFocus={handleFocus}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Enter your password"
              autoComplete="current-password"
              isRequired
            />
          </FormControl>
          <Tooltip
            label="Please enter your email to reset your password"
            isDisabled={!!email}
            hasArrow
          >
            <Button
              mb={3}
              variant="link"
              isDisabled={!email}
              _hover={{ textDecoration: "none", textColor: "gray.600" }}
              fontSize="smaller"
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </Button>
          </Tooltip>
          <Collapse in={!!error} animateOpacity>
            <Box pt={2} pb={2} color="red.500">
              {authErrorMessages[error!]}
            </Box>
          </Collapse>
          <Button
            isLoading={isLoading}
            isDisabled={isDisable}
            type="submit"
            colorScheme="blue"
            width="full"
            mb={4}
          >
            Sign In
          </Button>
          <Center>
            <Flex width="full" gap={2} alignItems="center" mb={4}>
              <Divider />
              Or
              <Divider />
            </Flex>
          </Center>

          <GateKeeper gate={BusinessFeatureFlag.Okta_SSO}>
            <Button
              colorScheme="blue"
              variant="outline"
              width="full"
              mt={4}
              onClick={() => {
                window.location.href = getOktaUrl();
              }}
            >
              Sign in with OKTA
            </Button>
          </GateKeeper>
        </Box>
      </Box>
    </Flex>
  );
};
