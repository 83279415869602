import { TaskTemplate } from "@elphi/types";
import { FieldsWeight } from "../../../../utils/ranked.utils";
import { CellsFilterStateFlat } from "../../../table/hooks/filter.hook";

export const DATA_RANK: FieldsWeight<TaskTemplate> = {
  "template.templateName": 30
};

export const filterState: CellsFilterStateFlat<TaskTemplate> = {
  createdAt: {
    column: "Created At",
    orderBy: "asc",
    query: []
  },
  templateName: {
    column: "Template Name",
    orderBy: "asc",
    query: [],
    baseField: "template"
  },
  type: {
    column: "Template Type",
    orderBy: "asc",
    query: []
  },
  checklistOf: {
    column: "Responsible Role",
    orderBy: "asc",
    query: []
  },
  assignableBy: {
    column: "Assignable By",
    orderBy: "asc",
    query: []
  },
  editableBy: {
    column: "Editable By",
    orderBy: "asc",
    query: []
  },
  availableStatus: {
    column: "Status",
    orderBy: "asc",
    query: []
  }
};
