import {
  AggregationType,
  IntegrationType,
  RentalDataVendorType
} from "@elphi/types";
import {
  numberValidation,
  stringValidation
} from "../../../../../components/utils/validationUtils";
import { baseElphiFieldsSpecs } from "../../../base/spec-files/baseElphiFieldsSpecs";
import { buildInput } from "../../../utils/formBuilder.utils";

export const confidenceScore = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.RentalData
    ]?.[RentalDataVendorType.RentRange]?.confidenceScore?.focused?.fieldKey,
  validation: numberValidation,
  calculationType: AggregationType.ThirdParty
});

export const orderStatus = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.RentalData
    ]?.[RentalDataVendorType.RentRange]?.orderStatus?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const errorMessage = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.RentalData
    ]?.[RentalDataVendorType.RentRange]?.errorMessage?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});

export const requestId = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations?.Integrations?.[
      IntegrationType.RentalData
    ]?.[RentalDataVendorType.RentRange]?.requestId?.focused?.fieldKey,
  validation: stringValidation,
  calculationType: AggregationType.ThirdParty
});
