import {
  DocumentConfiguration,
  FirebaseFilter,
  RolodexConfiguration,
  Task,
  TaskConfiguration,
  TaskTemplate
} from "@elphi/types";
import { Flatten } from "@elphi/types/utils/flatten";
import { EntityState } from "@reduxjs/toolkit";
import lodash, {
  castArray,
  every,
  filter,
  get,
  includes,
  isArray,
  isEmpty,
  map,
  orderBy,
  some
} from "lodash";
import { useEffect, useRef, useState } from "react";
import { OrderItemCellFilterStateKeys } from "../../document-package-order/OrderItemCellFilterStateKeys";
import { RolodexConfigurationCellFilterStateKeys } from "../../rolodex/configuration/tabs/service-domain-configuration/table/tableHeaders.hooks";
import { SearchApiV2 } from "../../search/SearchHandler";
import {
  DocumentConfigurationCellFilterStateKeys,
  TaskCellFilterStateKeys,
  TaskConfigurationCellFilterStateKeys,
  TaskTemplateCellFilterStateKeys
} from "../../task/TaskCellFilterStateKeys";

//TODO: fix type, T is not used
export type CellFilterStateKeys<T> = T extends Task
  ? TaskCellFilterStateKeys
  : T extends TaskTemplate
  ? TaskTemplateCellFilterStateKeys
  : T extends TaskConfiguration
  ? TaskConfigurationCellFilterStateKeys
  : T extends DocumentConfiguration
  ? DocumentConfigurationCellFilterStateKeys
  : T extends RolodexConfiguration
  ? RolodexConfigurationCellFilterStateKeys
  : OrderItemCellFilterStateKeys;

export type CellsFilterStateFlat<T> = Flatten<
  CellFilterStateKeys<T>,
  CellFilterField<T>
>;

export const useFilterHook = <
  T,
  TObject extends Flatten<object, CellFilterField<T>>
>(
  props: {
    state: TObject;
    options?: {
      debounceRate?: number;
    };
    nextFilterConfig?: {
      baseField?: string;
      customFilter?: {
        [key: string]: {
          single?: string;
          multi?: string;
        };
      };
    };
    onCustomFilter?: (o: TObject) => void;
    onNextFilter?: (r?: { filter?: FirebaseFilter | FirebaseFilter[] }) => void;
  } & Partial<SearchApiV2>
) => {
  const [selectedOrderState, setSelectedOrderState] = useState<{
    selectedKey: keyof CellFilterStateKeys<T>;
  }>();

  const [state, setState] = useState<TObject>(props.state);

  const setStateRef = useRef(setState);
  const setStateDebouncer = lodash.debounce((q: TObject) => {
    props.onCustomFilter?.(q) || setStateRef.current({ ...q });
  }, props.options?.debounceRate || 100);

  const setStateInputDebouncer = lodash.debounce((query: string) => {
    !!query && query > "" && props?.searchApi?.({ query }, true);
  }, props.options?.debounceRate || 100);

  const getFirebaseFilterObject = (r: {
    fieldPath: string;
    query?: string | string[];
  }) => {
    const opStr =
      isArray(r.query) && r.query.length > 1
        ? props.nextFilterConfig?.customFilter?.[r.fieldPath]?.multi || "in"
        : props.nextFilterConfig?.customFilter?.[r.fieldPath]?.single || "==";
    const value =
      isArray(r.query) && r.query.length > 0
        ? r.query.length > 1
          ? r?.query
          : r?.query[0]
        : null;

    return {
      fieldPath: r.fieldPath,
      opStr,
      value
    };
  };

  useEffect(() => {
    if (!!props.searchApi) {
      const allEmptyQueries = every(state, (prop) =>
        isEmpty((prop as CellFilterField<T>).query)
      );

      if (!allEmptyQueries) {
        const transformedObject = {
          filter: map(state, (prop, propName) => {
            const propTyped = prop as CellFilterField<T>;
            const fieldPath = props.nextFilterConfig?.baseField
              ? `${props.nextFilterConfig.baseField}.${propName}`
              : String(propName);
            return getFirebaseFilterObject({
              fieldPath,
              query: propTyped.query
            });
          }).filter((item) => item.value !== null)
        };
        props.onNextFilter?.(transformedObject as object);
      } else {
        props.onNextFilter?.(undefined);
      }
    }
  }, [state]);

  const getFilteredAndSortDataIds = (taskState: EntityState<T>) => {
    const allEmptyQueries = every(state, (prop) =>
      isEmpty((prop as CellFilterField<T>).query)
    );
    const filteredItems = allEmptyQueries
      ? undefined
      : filter(taskState.entities, (item) =>
          every(state, (prop, propName) => {
            const propTyped = prop as CellFilterField<T>;
            if (!item || !propTyped.query || propTyped.query.length === 0) {
              return true;
            }
            const valueArray = castArray(
              get(
                item,
                [props.nextFilterConfig?.baseField || "", propName],
                item[propName]
              )
            );
            return some(propTyped.query, (filterItem) =>
              includes(valueArray, filterItem)
            );
          })
        );

    const sortedItems = orderBy(
      filteredItems || taskState.entities,
      map(state, (value, key) => {
        const baseField = value["baseField"];
        return baseField ? `${baseField}.${key}` : key;
      }),
      map(state, "orderBy")
    );

    const arrayOfObjectIds = sortedItems ? map(sortedItems, "id") : undefined;

    return arrayOfObjectIds || taskState.ids;
  };

  return {
    state,
    setState,
    setStateDebouncer,
    setStateInputDebouncer,
    selectedOrderState,
    setSelectedOrderState,
    getFilteredAndSortDataIds
  };
};

export type CellFilterField<T> = {
  column: string;
  query: string[];
  orderBy: "asc" | "desc";
  adapter?: (v: T) => string;
  getData?: ({ task, states }: { task: Task; states: Object }) => string;
  show?: boolean;
  index?: number;
  baseField?: string;
};
