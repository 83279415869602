import { baseElphiFieldsSpecs } from "../base/spec-files/baseElphiFieldsSpecs";
import { limaSpecs } from "../lima/spec-files/limaFieldsSpecs";
import { DomainOwner, DynamicSchema } from "../types/dynamicSchema.types";
import { getDomainOwner } from "./formCommon.utils";

export const fieldSpecsMap = {
  [DomainOwner.Lima]: limaSpecs,
  [DomainOwner.Elphi]: baseElphiFieldsSpecs
};

export const getSpecs = (): DynamicSchema["specs"] => {
  const specOwner = getDomainOwner();
  return fieldSpecsMap[specOwner];
};
