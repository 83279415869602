import { useCallbackRef } from "@chakra-ui/react";
import { Party } from "@elphi/types";
import { usePartyHooks } from "../../../hooks/party.hooks";
import { getListFromDictionary } from "../../../utils/batchUtils";
import { useElphiToast } from "../../toast/toast.hook";
import { PartyTableState } from "../table/v2/PartyTable";

export const usePartyTableHook = () => {
  const { updateBatchParties: updatePartyBatch } = usePartyHooks();
  const { errorToast, successToast } = useElphiToast();
  const handleUpdatePartyBatch = async (parties: Partial<PartyTableState>) => {
    if (!parties.parties) return null;
    const listParties = getListFromDictionary(parties.parties);
    if (!listParties.length) return null;
    const filteredParties = listParties.filter((p): p is Party => !!p);
    return await updatePartyBatch({ parties: filteredParties }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Parties Updated",
          description: `total parties updated: ${listParties.length}`
        });
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to update batch",
          description: r.data.description
        });
      return r;
    });
  };
  const handleUpdatePartyBatchRef = useCallbackRef(handleUpdatePartyBatch);
  return {
    handleUpdatePartyBatch: handleUpdatePartyBatchRef
  };
};
