import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { ServiceProviderContainer } from "./ServiceProviderContainer";
import { serviceProviderContainerHooks } from "./ServiceProviderContainer.hooks";
import { ServiceProviderListCard } from "./list-card/ServiceProviderListCard";
import { ServiceProviderDealCard } from "./list-card/card/ServiceProviderDealCard";
import { serviceProviderListHooks } from "./list-card/serviceProviderList.hooks";
import { ServiceProviderCreateModal } from "./modal/ServiceProviderCreateModal";
import { ServiceProviderDealRelationRemoveModal } from "./modal/ServiceProviderDealRelationRemoveModal";
import { ServiceProviderEditModal } from "./modal/ServiceProviderEditModal";

export const ServiceProviderDealContainer = (props: { dealId: string }) => {
  const { dealId } = props;
  const {
    setCurrentFilter,
    currentFilter,
    getAllHandler,
    isOpenAddModal,
    isOpenEditModal,
    isOpenRemoveModal,
    onToggleAddModal,
    onToggleRemoveModal,
    onToggleEditModal,
    handleToggleEditModal,
    handleToggleRemoveModal,
    selectedProvider,
    isLoading
  } = serviceProviderContainerHooks();
  const { filteredByDealId } = serviceProviderListHooks();

  useEffect(() => {
    setCurrentFilter({
      entityId: dealId
    });
  }, []);

  useEffect(() => {
    if (currentFilter?.entityId) {
      getAllHandler();
    }
  }, [currentFilter?.entityId]);

  return (
    <ServiceProviderContainer
      onOpenModal={onToggleAddModal}
      isLoading={isLoading}
    >
      <>
        <Flex>
          <ServiceProviderListCard
            filteredItems={filteredByDealId}
            card={({ data }) => (
              <ServiceProviderDealCard
                data={data}
                onToggleEdit={() => handleToggleEditModal(data)}
                onToggleRemove={() => handleToggleRemoveModal(data)}
              />
            )}
            isLoading={isLoading}
          />
        </Flex>
        <>
          <ServiceProviderCreateModal
            filteredItems={filteredByDealId}
            isShow={isOpenAddModal}
            showEntitiesStep={true}
            onClose={onToggleAddModal}
            dealId={props.dealId}
          />
          {selectedProvider && (
            <ServiceProviderEditModal
              dealId={props.dealId}
              data={{
                ...selectedProvider,
                representativeId: selectedProvider.repId
              }}
              isShow={isOpenEditModal}
              onClose={onToggleEditModal}
            />
          )}
          {selectedProvider && (
            <ServiceProviderDealRelationRemoveModal
              data={selectedProvider}
              show={isOpenRemoveModal}
              onClose={onToggleRemoveModal}
            />
          )}
        </>
      </>
    </ServiceProviderContainer>
  );
};
