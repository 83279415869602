import { Property } from "@elphi/types";
import { useEffect } from "react";
import { useFormBuilderStateHandler } from "../components/form-builder/InputBuilder";
import { PropertyTableStateToUpdate } from "../components/property/table/v2/PropertyTable";
import { useElphiToast } from "../components/toast/toast.hook";
import { AppConfig } from "../config/appConfig";
import { usePropertyHooks } from "./property.hooks";

export type PropertyStructureState = {
  properties: { [id: string]: Partial<Property> };
};
export const usePropertyFormHandler = () => {
  const { selectedProperty, updateBatchProperties: updateBatch } =
    usePropertyHooks();
  const { errorToast, successToast } = useElphiToast();

  useEffect(() => {
    syncPropertyState({
      shouldSync: !!selectedProperty,
      state: selectedProperty,
      statePath: () => {
        if (selectedProperty) {
          return ["properties", selectedProperty.id];
        }
      }
    });
  }, [selectedProperty]);

  const updatePropertyHandler = async (
    diff: Partial<PropertyStructureState>,
    options?: { successToastTitle: string }
  ) => {
    if (!diff.properties) return null;
    const properties = Object.keys(diff.properties)
      .map((id) => {
        if (diff.properties) {
          return {
            ...diff.properties[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!properties.length) return null;
    return await updateBatch({ properties } as {
      properties: ({
        id: string;
      } & Partial<Property>)[];
    }).then((r) => {
      if (r?.status === 200) {
        successToast({
          title: options?.successToastTitle || "Properties Updated",
          description: `${r.data?.batch?.length} properties updated`
        });
      }
      if (r?.status === 400) {
        errorToast({
          title: "Failed to update properties",
          description:
            r.data?.error?.data?.error?.payload?.description ||
            r.data?.description
        });
      }
      return r;
    });
  };

  const updatePropertyPageHandler = async (
    diff: Partial<PropertyTableStateToUpdate>
  ) => {
    if (!diff.properties) return;
    const properties = Object.keys(diff.properties)
      .map((id) => {
        if (diff.properties) {
          return {
            ...diff.properties[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!properties.length) return;
    updateBatch({ properties } as {
      properties: ({
        id: string;
      } & Partial<Property>)[];
    }).then((r) => {
      if (r?.status === 200) {
        successToast({
          title: "Properties Updated",
          description: `${r.data?.batch?.length} properties updated`
        });
      }
      if (r?.status === 400) {
        errorToast({
          title: "Failed to update properties",
          description:
            r.data?.error?.data?.error?.payload?.description ||
            r.data?.description
        });
      }
    });
  };

  const {
    onChange: propertyOnChange,
    state: propertyState,
    setState: setPropertyState,
    diffState: propertyDiffState,
    setDiffState: setPropertyDiffState,
    syncState: syncPropertyState
  } = useFormBuilderStateHandler<PropertyStructureState>({
    initialState: { properties: {} } as PropertyStructureState,
    callback: updatePropertyHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });
  return {
    propertyOnChange,
    propertyState,
    setPropertyState,
    propertyDiffState,
    syncPropertyState,
    setPropertyDiffState,
    updatePropertyHandler,
    updatePropertyPageHandler
  };
};
