import { Property } from "@elphi/types";
import { ArgumentTypes } from "@elphi/types/utils/arguments";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { RootState } from "../redux/store";
import { serviceLinkFloodApi } from "../redux/v2/integration/service-link/service-link-flood.service";
import { propertyApi } from "../redux/v2/property/property.service";
import {
  PropertyTableCursorType,
  propertySlice
} from "../redux/v2/property/property.slice";
import { getListFromDictionary } from "../utils/batchUtils";
import { FieldsWeight, getRankedData } from "../utils/ranked.utils";
import { PropertyStructureState } from "./propertyform.hooks";

export const usePropertyHooks = () => {
  const dispatcher = useDispatch();
  const setSelectedProperty = (id: string) =>
    dispatcher(propertySlice.actions.selectedId({ id }));
  const [updatePropertyApi] = propertyApi.useUpdateMutation();
  const [updateBatch, updateBatchApiResponse] =
    propertyApi.useUpdateBatchMutation();
  const [deletePropertyApi, deletePropertyApiResponse] =
    propertyApi.useDeleteMutation();
  const [createPropertyApi, createPropertyApiResponse] =
    propertyApi.useCreateMutation();
  const [getPropertyBatchApi, getPropertyBatchResponse] =
    propertyApi.useLazyGetPropertyBatchQuery();
  const [createServiceLinkApi, createServiceLinkResponse] =
    serviceLinkFloodApi.useCreateServiceLinkMutation();
  const [quickCheckServiceLinkApi, quickCheckServiceLinkResponse] =
    serviceLinkFloodApi.useQuickCheckServiceLinkMutation();
  const [changeServiceLinkApi, changeServiceLinkResponse] =
    serviceLinkFloodApi.useChangeServiceLinkMutation();

  const selectedProperty = useSelector((state: RootState) => {
    return state.property.selectedId
      ? state.property.entities[state.property.selectedId]
      : undefined;
  });

  const propertyState = useSelector((state: RootState) => state.property);

  const updateProperty = async (
    newProperty: { id: string } & Partial<Property>
  ) => {
    return await updatePropertyApi(newProperty).then(responseHandler);
  };
  const updateBatchProperties = async (newProperties: {
    properties: ({ id: string } & Partial<PropertyStructureState>)[];
  }) => {
    return await updateBatch(newProperties).then(responseHandler);
  };
  const updatePropertiesHandler = async (
    map:
      | {
          [id: string]: {
            id: string;
          } & Partial<Property>;
        }
      | undefined
  ) => {
    if (!map) return;
    const properties = getListFromDictionary(map);
    if (!properties.length) return;
    return await updateBatchProperties({ properties } as {
      properties: ({
        id: string;
      } & Partial<Property>)[];
    });
  };
  const createProperty = async (newProperty: Property) => {
    return await createPropertyApi(newProperty).then(responseHandler);
  };

  const deleteProperty = async (id: string) => {
    return await deletePropertyApi(id).then(responseHandler);
  };
  const dataRank: FieldsWeight<Property> = {
    "Address.AddressLineText": 20,
    "Address.AddressUnitIdentifier": 20,
    "Address.CityName": 20,
    "Address.StateName": 20,
    "Address.PostalCode": 20
  };
  const rankedSort = (query: string) => {
    return Object.values(propertyState.entities).sort((a, b) => {
      const rankA = getRankedData(query, a, dataRank, {
        sortedByKeywords: true
      });

      const rankB = getRankedData(query, b, dataRank, {
        sortedByKeywords: true
      });

      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  const setPropertyTableCursor = (
    r: ArgumentTypes<typeof propertySlice.actions.setPropertyTableCursor>[0]
  ) => dispatcher(propertySlice.actions.setPropertyTableCursor(r));
  const getPropertyTableCursor = (r: {
    cursorType: PropertyTableCursorType;
    propertyId?: string;
    userId?: string;
  }): string | undefined => {
    const { cursorType, propertyId, userId } = r;
    if (cursorType === PropertyTableCursorType.ALL)
      return propertyState.propertyTable?.cursor.all;
    else if (userId && cursorType === PropertyTableCursorType.USER_FILTER)
      return propertyState.propertyTable?.cursor.userFilter[userId];
    else if (
      propertyId &&
      cursorType === PropertyTableCursorType.PROPERTY_FILTER
    )
      return propertyState.propertyTable?.cursor.propertyFilter[propertyId];
    else if (cursorType === PropertyTableCursorType.PROPERTY_USER_FILTER)
      return propertyState.propertyTable?.cursor.propertyUserFilter[
        `${propertyId}_${userId}`
      ];
    else throw `case ${r} not supported`;
  };

  const getPropertyBatch = async (r: string[]) => {
    const response = await getPropertyBatchApi(r, true);
    return responseHandler(response as RTKResponse<typeof response.data>);
  };

  const createServiceLink = async (r: {
    propertyId: string;
    dealId: string;
  }) => {
    const response = await createServiceLinkApi(r);
    return responseHandler(response);
  };
  const quickCheckServiceLink = async (r: {
    propertyId: string;
    dealId: string;
  }) => {
    const response = await quickCheckServiceLinkApi(r);
    return responseHandler(response);
  };
  const changeServiceLink = async (r: {
    propertyId: string;
    dealId: string;
  }) => {
    const response = await changeServiceLinkApi(r);
    return responseHandler(response);
  };

  return {
    deleteProperty,
    deletePropertyApiResponse,
    rankedSort,
    createProperty,
    createPropertyApiResponse,
    selectedProperty,
    setSelectedProperty,
    updateProperty,
    updateBatchProperties,
    updateBatchApiResponse,
    propertyState,
    updatePropertiesHandler,
    setPropertyTableCursor,
    getPropertyTableCursor,
    getPropertyBatch,
    getPropertyBatchResponse,
    createServiceLink,
    createServiceLinkResponse,
    quickCheckServiceLink,
    quickCheckServiceLinkResponse,
    changeServiceLink,
    changeServiceLinkResponse
  };
};
