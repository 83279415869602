import { ThirdPartyField } from "../aggregations";

export type PricingEngineScenario = {
  scenario: {
    scenarioId: ThirdPartyField;
  }[];
};
export type SharedQuoteFields = {
  InterestReserveEscrow: ThirdPartyField;
  OriginationFeeAmount: ThirdPartyField;
  ProcessingFeeAmount: ThirdPartyField;
  BrokerOriginationFeeAmount: ThirdPartyField;
  ServicingSetupFee: ThirdPartyField;
  PricingEngineWarnings: ThirdPartyField;
  BrokerProcessingFeeAmount: ThirdPartyField;
  NoteRatePercent: ThirdPartyField;
  FeeLLPAs: ThirdPartyField;
  RateLLPAs: ThirdPartyField;
  PricingEngineOverrides: ThirdPartyField;
  ValidationErrors: ThirdPartyField;
  QuoteId: ThirdPartyField;
  LenderFinanceProcessingFee: ThirdPartyField;
  LenderFinanceApplicationFee: ThirdPartyField;
  LenderFinanceDocumentFee: ThirdPartyField;
  LenderFinanceAppraisalFee: ThirdPartyField;
  LimaRateToBorrowerPercent: ThirdPartyField;
  LimaOriginationFeeToBorrowerPercent: ThirdPartyField;
  BlendedLTCAdjuster: ThirdPartyField;
  PurchaseLTCAdjuster: ThirdPartyField;
  ARVAdjuster: ThirdPartyField;
  AchRequiredIndicator: ThirdPartyField;
  AchExemptionStatus: ThirdPartyField;
};

export enum QuoteMappingDataType {
  Number = "number",
  String = "string",
  TimestampToDate = "timestampToDate"
}
