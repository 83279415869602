import { Property } from "@elphi/types";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { auth } from "../../firebase/firebaseConfig";
import { usePropertyHooks } from "../../hooks/property.hooks";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import { RootState } from "../../redux/store";
import { dealApi } from "../../redux/v2/deal/deal.service";
import { propertyApi } from "../../redux/v2/property/property.service";
import { FieldType } from "../form-builder/fieldFormat.types";
import { SearchHandler } from "../search/SearchHandler";
import SearchComponent, { SearchComponentProps } from "../SearchComponent";
import { propertyLabel } from "./utils/printUtils";

//TODO: implement containers; live-state,snapshot
export const DealPropertySearchContainer = (
  props: DealPropertySearchProps & { snapshotId?: string }
) => {
  return props.snapshotId ? (
    <DealPropertySnapshotSearchContainer
      {...props}
      snapshotId={props.snapshotId}
    />
  ) : (
    <DealPropertyLiveStateSearchContainer {...props} />
  );
};

export type DealPropertySearchProps = {
  dealId: string;
  label?: string;
} & Pick<
  SearchComponentProps,
  "onSelect" | "currentValue" | "fieldType" | "labelPosition" | "label"
>;
export const DealPropertySnapshotSearchContainer = (
  props: DealPropertySearchProps & { snapshotId: string }
) => {
  const { snapshotDataState, getSnapshotDataResponse } = useSnapshotHooks();
  const snapshot = snapshotDataState({ snapshotId: props.snapshotId });
  const options = useMemo(() => {
    if (!props.dealId || !snapshot.dealPropertyState?.ids) return [];

    return (
      snapshot.dealPropertyState?.ids
        ?.filter(
          (id) =>
            snapshot.dealPropertyState?.entities?.[id]?.dealId === props.dealId
        )
        .map((id) => {
          const propertyId =
            snapshot.dealPropertyState?.entities?.[id]?.propertyId || "No Id";
          const entity = snapshot.propertyState?.entities[propertyId];
          return {
            label: entity ? propertyLabel(entity) : "No Label",
            value: propertyId
          };
        }) || []
    );
  }, [snapshot.propertyState, snapshot.dealPropertyState, props.dealId]);
  return (
    <SearchComponent
      fieldType={props.fieldType}
      options={options}
      currentValue={props.currentValue}
      onSelect={props.onSelect}
      isLoading={getSnapshotDataResponse.isLoading}
      label={props.label}
      labelPosition={props.labelPosition}
    />
  );
};

export const DealPropertyLiveStateSearchContainer = (
  props: DealPropertySearchProps
) => {
  const propertySlice = useSelector((state: RootState) => state.property);
  const dealPropertySlice = useSelector(
    (state: RootState) => state.dealPropertyRelation
  );

  const [getDealProperties, dealPropertiesResponse] =
    dealApi.useLazyDealPropertiesQuery();
  const propertiesIsLoading =
    dealPropertiesResponse.isLoading || dealPropertiesResponse.isFetching;

  useEffect(() => {
    if (auth.currentUser && props.dealId) {
      getDealProperties([props.dealId], true);
    }
  }, [auth.currentUser, props.dealId]);

  const options = useMemo(
    () =>
      (props.dealId &&
        dealPropertySlice.ids
          .filter(
            (id) => dealPropertySlice?.entities?.[id]?.dealId === props.dealId
          )
          .map((id) => {
            const propertyId =
              dealPropertySlice?.entities?.[id]?.propertyId || "No Id";
            const entity = propertySlice.entities[propertyId];
            return {
              label: entity ? propertyLabel(entity) : "No Label",
              value: propertyId
            };
          })) ||
      [],
    [propertySlice, dealPropertySlice, props.dealId]
  );

  return (
    <SearchComponent
      fieldType={props.fieldType}
      options={options}
      currentValue={props.currentValue}
      onSelect={props.onSelect}
      isLoading={propertiesIsLoading}
      label={props.label}
      labelPosition={props.labelPosition}
    />
  );
};

const buildOption = (property: Property) => {
  return {
    label: propertyLabel(property!),
    value: property!.id
  };
};
export const PropertySearch = (
  props: {
    label?: string;
  } & Pick<
    SearchComponentProps,
    "onSelect" | "currentValue" | "fieldType" | "labelPosition" | "label"
  >
) => {
  const propertySlice = useSelector((state: RootState) => state.property);
  const { rankedSort } = usePropertyHooks();
  const [searchProperty, searchResponse] = propertyApi.useLazySearchQuery();

  return (
    <SearchHandler
      {...props}
      searchApi={searchProperty}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={propertySlice}
      buildOption={buildOption}
    />
  );
};

export const NoRelationPropertySearch = (props: {
  onSelect: (v: string[]) => void;
  currentValue: string[];
}) => {
  const noRelationFilter = (property: Property) => {
    return (
      property &&
      (!property?.dealRelation || property?.dealRelation?.length === 0)
    );
  };
  const propertySlice = useSelector((state: RootState) => state.property);

  const { rankedSort } = usePropertyHooks();
  const [searchProperty, searchResponse] = propertyApi.useLazySearchQuery();

  return (
    <SearchHandler
      {...props}
      filter={noRelationFilter}
      fieldType={FieldType.MultiSelect}
      searchApi={searchProperty}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={propertySlice}
      buildOption={buildOption}
    />
  );
};
