import { ElphiEntityType } from "@elphi/types";
import { creditReportFieldBaseSpecs } from "../../../../components/form-builder/field-specs/credit-report/credit-report.fields";
import { baseElphiFieldsSpecs } from "../../base/spec-files/baseElphiFieldsSpecs";
import { LimaSpecs } from "../types";

export const limaSpecs: LimaSpecs = {
  ...baseElphiFieldsSpecs,
  [ElphiEntityType.creditScore]: {
    entitySpecs: creditReportFieldBaseSpecs,
    specsParts: {}
  }
};
