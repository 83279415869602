import { AggregationType } from "@elphi/types";
import { partyValidations } from "@elphi/utils/src/validation.utils";
import {
  booleanValidation,
  dateValidation,
  emailValidation,
  moneyValidation,
  multiSelectValidation,
  numberValidation,
  singleSelectValidation,
  stringLengthValidation,
  stringValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { baseElphiFieldsSpecs } from "../spec-files/baseElphiFieldsSpecs";

export const partyAddressLineText = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.Address
      ?.AddressLineText?.fieldKey,
  validation: stringValidation
});

export const partyAddressUnitIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.Address
      ?.AddressUnitIdentifier?.fieldKey,
  validation: stringValidation
});

export const partyCityName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.Address
      ?.CityName?.fieldKey,
  validation: stringValidation
});

export const partyCountyName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.Address
      ?.CountyName?.fieldKey,
  validation: stringValidation
});

export const partyStateName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.Address
      ?.StateName?.fieldKey,
  validation: singleSelectValidation
});

export const partyStateCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.Address
      ?.StateCode?.fieldKey,
  validation: singleSelectValidation
});

export const partyPostalCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.Address
      ?.PostalCode?.fieldKey,
  validation: stringValidation
});

export const taxpayerIdentifierType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs
      .TaxpayerIdentifierType?.fieldKey,
  validation: singleSelectValidation
});

export const taxpayerIdentifierValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs
      .TaxpayerIdentifierValue?.fieldKey
});

export const groupId = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.GroupId
      ?.fieldKey,
  validation: singleSelectValidation
});

export const overridePartyValidationSettingsIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs
      .OverridePartyValidationSettingsIndicator?.fieldKey,
  validation: singleSelectValidation
});

export const entityType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs.EntityType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const entityFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs.FullName
      ?.fieldKey,
  validation: stringLengthValidation(partyValidations.fullNameMaxLength)
});

export const partyContactPointEmailValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs
      .ContactPointEmailValue?.fieldKey,
  validation: emailValidation
});

export const partyContactPointTelephoneValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs
      .ContactPointTelephoneValue?.fieldKey,
  validation: stringValidation
});

export const representativeCreditScore = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs
      .RepresentativeCreditScore?.fieldKey,
  validation: numberValidation
});

export const businessIncorporationStateName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs
      .BusinessIncorporationStateName?.fieldKey,
  validation: singleSelectValidation
});

export const businessIncorporationStateCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs
      .BusinessIncorporationStateCode?.fieldKey,
  validation: singleSelectValidation
});

export const businessRegistrationStates = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs
      .BusinessRegistrationStates?.fieldKey,
  validation: multiSelectValidation
});

export const fixAndFlipBridgePlusTier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs
      .FixAndFlipBridgePlusTier?.fieldKey,
  validation: numberValidation
});

export const newConstructionEligibilityIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs
      .NewConstructionEligibilityIndicator?.fieldKey,
  validation: booleanValidation
});

export const realEstateOperationType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs
      .RealEstateOperationType?.fieldKey,
  validation: multiSelectValidation
});

export const operationsDepartment = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs
      .OperationsDepartment?.fieldKey,
  validation: singleSelectValidation
});

export const entityNotes = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs.Notes
      ?.fieldKey,
  validation: stringValidation
});

export const crmId = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.CRMId?.fieldKey,
  validation: stringValidation
});

export const backgroundReportDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs
      .BackgroundReportDate?.fieldKey,
  validation: dateValidation
});

export const backgroundReportExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.aggregations
      .BackgroundReportExpirationDate?.focused?.fieldKey,
  calculationType: AggregationType.Aggregation,
  validation: dateValidation
});

export const evidenceOfGoodStandingExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs
      .EvidenceOfGoodStandingExpirationDate?.fieldKey,
  validation: dateValidation
});

export const partyMilestone = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.entityPartyFieldSpecs.PartyMilestone
      ?.fieldKey,
  validation: singleSelectValidation
});

export const individualFirstName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs.FirstName
      ?.fieldKey,
  validation: stringValidation
});

export const individualMiddleName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs.MiddleName
      ?.fieldKey,
  validation: stringValidation
});

export const individualLastName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs.LastName
      ?.fieldKey,
  validation: stringValidation
});

export const individualBirthDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs.BirthDate
      ?.fieldKey,
  validation: dateValidation
});

export const individualGovernmentIssuedIDExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .GovernmentIssuedIDExpirationDate?.fieldKey,
  validation: dateValidation
});

export const individualCompletedExits = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .CompletedExits?.fieldKey,
  validation: numberValidation
});

export const individualCitizenshipCountry = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .CitizenshipCountry?.fieldKey,
  validation: stringValidation
});

export const individualUSCitizenshipIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .USCitizenshipIndicator?.fieldKey,
  validation: booleanValidation
});

export const individualContactPointRoleType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .ContactPointRoleType?.fieldKey,
  validation: singleSelectValidation
});

export const totalStatementQualifyingBalance = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.aggregations
      .TotalStatementQualifyingBalance?.focused?.fieldKey,
  calculationType: AggregationType.Aggregation,
  validation: moneyValidation
});

export const individualPaymentTokenId = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .PaymentTokenId?.fieldKey,
  validation: stringValidation
});

export const individualCrmId = buildInput({
  //use crmId
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.CRMId?.fieldKey,
  validation: stringValidation
});

export const creditReportNotes = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .CreditReportNotes?.fieldKey,
  validation: stringValidation
});

export const locStatusType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .LOCStatusType?.fieldKey,
  validation: singleSelectValidation
});

export const totalApprovedLOC = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .TotalApprovedLOC?.fieldKey,
  validation: moneyValidation
});

export const loanAmountInProcess = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .LoanAmountInProcess?.fieldKey,
  validation: moneyValidation
});

export const locExpirationDateAggregation = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.aggregations
      .LOCExpirationDate?.focused?.fieldKey,
  calculationType: AggregationType.Aggregation,
  validation: dateValidation
});

export const numberOfTransactions = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .NumberOfTransactions?.fieldKey,
  validation: numberValidation
});

export const transactionsCumulativeSalePrice = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .TransactionsCumulativeSalePrice?.fieldKey,
  validation: moneyValidation
});

export const transactionsAverageProjectDuration = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .TransactionsAverageProjectDuration?.fieldKey,
  validation: numberValidation
});

export const transactionsAverageSalePrice = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .TransactionsAverageSalePrice?.fieldKey,
  validation: moneyValidation
});

export const numberOfFlips = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .NumberOfFlips?.fieldKey,
  validation: numberValidation
});

export const flipsCumulativeSalePrice = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .FlipsCumulativeSalePrice?.fieldKey,
  validation: moneyValidation
});

export const flipsAverageProjectDuration = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .FlipsAverageProjectDuration?.fieldKey,
  validation: numberValidation
});

export const flipsAverageSalePrice = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .FlipsAverageSalePrice?.fieldKey,
  validation: moneyValidation
});

export const numberOfNewBuilds = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .NumberOfNewBuilds?.fieldKey,
  validation: numberValidation
});

export const newBuildsCumulativeSalePrice = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .NewBuildsCumulativeSalePrice?.fieldKey,
  validation: moneyValidation
});

export const newBuildsAverageProjectDuration = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .NewBuildsAverageProjectDuration?.fieldKey,
  validation: numberValidation
});

export const newBuildsAverageSalePrice = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .NewBuildsAverageSalePrice?.fieldKey,
  validation: moneyValidation
});

export const experienceNotes = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .ExperienceNotes?.fieldKey,
  validation: stringValidation
});

export const fixNFlipNBridgePlusTier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .FixNFlipNBridgePlusTier?.fieldKey,
  validation: numberValidation
});

export const estimatedCreditScore = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .EstimatedCreditScore?.fieldKey,
  validation: numberValidation
});

export const convictionIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .ConvictionIndicator?.fieldKey,
  validation: booleanValidation
});

export const outstandingLawsuitsIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .OutstandingLawsuitsIndicator?.fieldKey,
  validation: booleanValidation
});

export const outstandingJudgmentsIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .OutstandingJudgmentsIndicator?.fieldKey,
  validation: booleanValidation
});

export const bankruptcyIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .BankruptcyIndicator?.fieldKey,
  validation: booleanValidation
});

export const foreclosureIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .ForeclosureIndicator?.fieldKey,
  validation: booleanValidation
});

export const mortgageDelinquentIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .MortgageDelinquentIndicator?.fieldKey,
  validation: booleanValidation
});

export const statedCashOnHandAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.individualPartyFieldSpecs
      .StatedCashOnHandAmount?.fieldKey,
  validation: booleanValidation
});

export const partyType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.party?.specsParts?.basePartyFieldSpecs.PartyType
      .fieldKey,
  validation: singleSelectValidation
});
