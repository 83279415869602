import { LOSUser, UserRoleType } from "../../../types";
import { namesToContractFormat } from "./format.utils";

export const getLOSUserFullName = (user: LOSUser) => {
  if (!!user?.name) return user.name;
  else if (!!user?.firstName || !!user?.lastName || !!user?.middleName) {
    return `${user.firstName}${user.middleName ? ` ${user.middleName} ` : " "}${
      user.lastName
    }`.trim();
  }
  return "";
};

export const getLOSUserEmail = (user: LOSUser) => {
  if (!!user?.email) return user.email;
  return "";
};

export const getLOSUserTitle = (user: LOSUser, filterRoles: string[]) => {
  let userTitle = "";
  if (!!user?.roles) {
    const filteredRoles = user.roles.filter((role) =>
      filterRoles.includes(role.value)
    );
    userTitle = namesToContractFormat<typeof filteredRoles>({
      list: filteredRoles,
      adapter: (role: { label: string; value: string }) => role.label
    });
  }
  return userTitle;
};

export const getAssignedUsersInfo = (users: LOSUser[]) => {
  let assignedUsers: string = "";
  let assignedUserEmails: string = "";
  const filteredUsers = users.filter((u) => {
    const userRoles = u?.roles?.map((role) => role.value) ?? [];
    return userRoles.includes(UserRoleType.OpsAnalyst);
  });
  filteredUsers.forEach((user, index) => {
    const name = user?.name ?? "";
    const email = user?.email ?? "";
    assignedUsers = `${assignedUsers} ${name}`;
    assignedUserEmails = `${assignedUserEmails} ${email}`;
    if (filteredUsers.length > 2 && index === filteredUsers.length - 2) {
      assignedUsers = assignedUsers + ", and";
      assignedUserEmails = assignedUserEmails + ", and";
    } else if (
      filteredUsers.length <= 2 &&
      index === filteredUsers.length - 2
    ) {
      assignedUsers = assignedUsers + " and";
      assignedUserEmails = assignedUserEmails + " and";
    } else if (filteredUsers.length > 2 && index !== filteredUsers.length - 1) {
      assignedUsers = assignedUsers + ", ";
      assignedUserEmails = assignedUserEmails + ", ";
    }
  });
  return {
    AssignedUsers: assignedUsers,
    AssignedUserEmails: assignedUserEmails
  };
};
