import { EntityParty } from "@elphi/types";
import { OnChangeInput } from "../../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { einInputFormatter } from "../../../../../components/form-builder/formatters/inputs.formatter";
import {
  getTaxIdentifierFormatter,
  getTaxIdentifierValidator
} from "../../../../../components/utils/partyUtils";
import { addRuleToInput, buildSection } from "../../../utils/formBuilder.utils";
import {
  backgroundReportDate,
  backgroundReportExpirationDate,
  businessIncorporationStateCode,
  businessIncorporationStateName,
  businessRegistrationStates,
  crmId,
  entityFullName,
  entityNotes,
  entityType,
  evidenceOfGoodStandingExpirationDate,
  fixAndFlipBridgePlusTier,
  groupId,
  newConstructionEligibilityIndicator,
  operationsDepartment,
  overridePartyValidationSettingsIndicator,
  partyContactPointEmailValue,
  partyContactPointTelephoneValue,
  partyMilestone,
  realEstateOperationType,
  representativeCreditScore,
  taxpayerIdentifierType,
  taxpayerIdentifierValue
} from "../../inputs/party.inputs";

const partyMilestoneSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<EntityParty>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Party Milestone",
      inputs: [partyMilestone],
      fieldSpecs
    });

const entityTaxIdentifierSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<EntityParty>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Identifier",
      inputs: [
        taxpayerIdentifierType,
        addRuleToInput({
          input: taxpayerIdentifierValue,
          state: r.state,
          rules: [
            {
              field: "formatter",
              formatter: () =>
                getTaxIdentifierFormatter(r.state?.TaxpayerIdentifierType)
            },
            {
              field: "validation",
              validation:
                getTaxIdentifierValidator(r.state?.TaxpayerIdentifierType) ||
                (() => false)
            }
          ]
        }),
        groupId,
        overridePartyValidationSettingsIndicator
      ],
      fieldSpecs
    });

const entityInfoSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<EntityParty>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Entity Info",
      inputs: [
        entityType,
        entityFullName,
        partyContactPointEmailValue,
        partyContactPointTelephoneValue,
        representativeCreditScore,
        businessIncorporationStateName,
        businessIncorporationStateCode,
        businessRegistrationStates,
        fixAndFlipBridgePlusTier,
        newConstructionEligibilityIndicator,
        realEstateOperationType,
        operationsDepartment,
        entityNotes,
        crmId,
        backgroundReportDate,
        backgroundReportExpirationDate,
        evidenceOfGoodStandingExpirationDate
      ],
      fieldSpecs
    });

const createEntitySection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<EntityParty>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "",
      inputs: [
        entityFullName,
        { ...taxpayerIdentifierValue, formatter: einInputFormatter }
      ],
      hideAttachedComponent: true,
      fieldSpecs
    });

export const entityPartySectionBuilders = {
  partyMilestoneSection,
  entityTaxIdentifierSection,
  entityInfoSection,
  createEntitySection
};
