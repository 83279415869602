import { Task } from "../../task.types";
import { BaseTaskTemplate, TaskType } from "../base.types";
import { DocumentTaskFields, OrderStatusTaskFields } from "../document.types";
import { CreditReportOrderTask } from "./creditReport.types";
import { FloodCertificateOrderTask } from "./flood-certificate/floodCertificate.types";
import { PropertyReportOrderTask } from "./property-report/propertyReport.types";
import { RentalDataOrderTask } from "./rental-data";
import { WireInsuranceCertificateOrderTask } from "./wire-insurance-certificate/wireInsuranceCertificate.types";

export enum IntegrationType {
  FloodCertificate = "FloodCertification",
  CreditReport = "CreditReport",
  WireInsuranceCertificate = "WireInsuranceCertificate",
  PropertyReport = "PropertyReport",
  RentalData = "RentalData"
}

export type IntegrationTypeLabels = { [type in IntegrationType]: string };

export type BaseIntegrationTask<TType extends IntegrationType> =
  BaseTaskTemplate<TaskType.Integration> & {
    template: DocumentTaskFields & {
      integrationType: TType;
      mapIntegrationFileKeys?: IntegrationFileType;
      isReadyToOrder?: boolean;
    } & OrderStatusTaskFields;
  };
export type IntegrationTaskTemplate<
  T extends IntegrationTask = IntegrationTask
> = {
  selectedTask: T["template"] & Task;
};

export type IntegrationTask =
  | FloodCertificateOrderTask
  | CreditReportOrderTask
  | WireInsuranceCertificateOrderTask
  | PropertyReportOrderTask
  | RentalDataOrderTask;

export type IntegrationFileType = {
  [K in IntegrationType]: Record<string, string>;
};

export enum ElphiTrxStatus {
  Pending = "Pending Order",
  InProcess = "In Process",
  Success = "Success",
  Failed = "Failed"
}

export type StepRequired = "additionalCost";
