import { AggregationField, ThirdPartyField } from "../aggregations";
import { ElphiDealSnapshotEntity } from "../snapshots";
import { DotNestedKeys } from "../utils/flatten";
import { LoanProductType } from "./deal.enums.types";
import {
  PricingEngineScenario,
  QuoteMappingDataType,
  SharedQuoteFields
} from "./deal.quote.shared.types";

export const rentalQuoteGETApiToDealMapping: { [field: string]: string[] } = {
  id: ["quote", "aggregations", "QuoteId"],
  ltvEligibility: ["quote", "aggregations", "LtvEligibility"],
  originationFee: ["quote", "aggregations", "OriginationFeeAmount"],
  processingFee: ["quote", "aggregations", "ProcessingFeeAmount"],
  brokerFee: ["quote", "aggregations", "BrokerOriginationFeeAmount"],
  insuranceCertifications: ["quote", "aggregations", "ServicingSetupFee"],
  interestReserveEscrow: ["quote", "aggregations", "InterestReserveEscrow"],
  initFixedInterestRate: ["quote", "aggregations", "NoteRatePercent"],
  monthlyPrincipalInterest: [
    "quote",
    "aggregations",
    "MonthlyPrincipalInterestPaymentAmount"
  ],
  monthlyTaxesInsurance: [
    "quote",
    "aggregations",
    "MonthlyTaxInsurancePaymentAmount"
  ],
  monthlyHOADues: [
    "quote",
    "aggregations",
    "MonthlyHomeownersAssociationAmount"
  ],
  monthlyTotalPayment: [
    "quote",
    "aggregations",
    "TotalMonthlyPITIPaymentAmount"
  ],
  rentDebtRatio: ["quote", "aggregations", "DebtServiceCoverageRatioPercent"],
  buyUpDownFee: ["quote", "aggregations", "BuyUpDownFee"],
  warnings: ["quote", "aggregations", "PricingEngineWarnings"],
  brokerProcessingFeeCalc: [
    "quote",
    "aggregations",
    "BrokerProcessingFeeAmount"
  ],
  feeLLPAs: ["quote", "aggregations", "FeeLLPAs"],
  rateLLPAs: ["quote", "aggregations", "RateLLPAs"],
  overridePricing: ["quote", "aggregations", "PricingEngineOverrides"],
  finalRateOverride: [
    "quote",
    "aggregations",
    "PricingEngineNoteRateOverridePercent"
  ],
  margin: ["quote", "aggregations", "Margin"],
  floor: ["quote", "aggregations", "FloorPercent"],
  lifetimeCap: ["quote", "aggregations", "LifetimeCapPercent"],
  "scenarios[0].lenderFinanceProcessingFee": [
    "quote",
    "aggregations",
    "LenderFinanceProcessingFee"
  ],
  "scenarios[0].lenderFinanceApplicationFee": [
    "quote",
    "aggregations",
    "LenderFinanceApplicationFee"
  ],
  "scenarios[0].lenderFinanceDocumentFee": [
    "quote",
    "aggregations",
    "LenderFinanceDocumentFee"
  ],
  "scenarios[0].lenderFinanceAppraisalFee": [
    "quote",
    "aggregations",
    "LenderFinanceAppraisalFee"
  ],
  achRequired: ["quote", "aggregations", "AchRequiredIndicator"],
  achExempt: ["quote", "aggregations", "AchExemptionStatus"]
};

export const rentalQuotePOSTApiToDealMapping: {
  [path: string]: { type: QuoteMappingDataType; field: string[] };
} = {
  id: {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "QuoteId"]
  },
  "fields.prepaymentPenalty": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "PrepaymentPenaltyType"]
  },
  "fields.quoteType": {
    type: QuoteMappingDataType.String,
    field: ["aggregations", "LoanProgramType"]
  },
  "fields.creditScore": {
    type: QuoteMappingDataType.Number,
    field: ["quote", "aggregations", "PrimaryBorrowerCreditScore"]
  },
  "fields.annualTaxes": {
    type: QuoteMappingDataType.Number,
    field: ["aggregations", "TotalTaxAmount"]
  },
  "fields.state": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "StateNY"]
  },
  "fields.annualInsurance": {
    type: QuoteMappingDataType.String,
    field: ["aggregations", "TotalPropertyInsurancePremiumAmount"]
  },
  "fields.refiL1cFixNFlip": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "InternalL1CRefinanceIndicator"]
  },
  "fields.numOfProperties": {
    type: QuoteMappingDataType.Number,
    field: ["aggregations", "TotalNumberOfProperties"]
  },
  "fields.annualFloodInsurance": {
    type: QuoteMappingDataType.Number,
    field: ["aggregations", "TotalFloodInsurancePremiumAmount"]
  },
  "fields.borrowingEntity": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "BorrowingEntity"]
  },
  "fields.mortgagePayoffs": {
    type: QuoteMappingDataType.String,
    field: ["aggregations", "TotalOutstandingLoanPayoffAmount"]
  },
  "fields.appraisedVal3": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "OverPropertyValuationAmount"]
  },
  "fields.monthlyRent": {
    type: QuoteMappingDataType.String,
    field: ["aggregations", "TotalAdjustedMonthlyRentAmount"]
  },
  "fields.productType": {
    type: QuoteMappingDataType.String,
    field: ["aggregations", "LoanProductType"]
  },
  "fields.purchasePrice": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "TotalValuationOrPurchasePriceAmount"]
  },
  "fields.annualHOA": {
    type: QuoteMappingDataType.Number,
    field: ["aggregations", "TotalHOAFeesAmount"]
  },
  "fields.base.condo": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "PropertyTypeCondoIndicator"]
  },
  "fields.base.propertyVal": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "PropertyValuationAmountUnderIndicator"]
  },
  "fields.usCitizen": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "USCitizenshipIndicator"]
  },
  "fields.seasonalRental": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "SeasonalRental"]
  },
  "fields.fiveNineUnit": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "PropertyTypeMultifamilyIndicator"]
  },
  "fields.scenario[0].scenarioId": {
    type: QuoteMappingDataType.Number,
    field: ["quote", "aggregations", "scenario", "scenarioId"]
  },
  "fields.scenario[0].ltvPercentage": {
    type: QuoteMappingDataType.Number,
    field: ["aggregations", "TotalLTVRatePercent"]
  },
  "fields.milestone": {
    type: QuoteMappingDataType.String,
    field: ["DealMetadata", "milestone"]
  },
  "fields.exceptionPricingOverride.finalRateOverride": {
    type: QuoteMappingDataType.Number,
    field: ["quote", "aggregations", "PricingEngineNoteRateOverridePercent"]
  },
  "fields.zip": {
    type: QuoteMappingDataType.String,
    field: [""]
  },
  "fields.loanCostOverride.taxEscrowAmount": {
    type: QuoteMappingDataType.Number,
    field: ["aggregations", "TotalInitialTaxEscrowAmount"]
  },
  "fields.loanCostOverride.insEscrowAmount": {
    type: QuoteMappingDataType.Number,
    field: ["aggregations", "TotalInitialInsuranceEscrowAmount"]
  },
  "fields.loanCostOverride.iREscrowOverrideInt": {
    type: QuoteMappingDataType.Number,
    field: ["InterestReserveEscrowOverrideMonthCount"]
  },
  "fields.loanCostOverride.iREscrowOverrideMoneyAmt": {
    type: QuoteMappingDataType.Number,
    field: ["InterestReserveEscrowOverrideAmount"]
  },
  "fields.exceptionPricingOverride.processingFeeOverride": {
    type: QuoteMappingDataType.Number,
    field: ["ProcessingFeeOverride"]
  }
};

export const rentalQuotePOSTApiToPropertyMapping: {
  [path: string]: { type: QuoteMappingDataType; field: string[] };
} = {
  "fields.zip": {
    type: QuoteMappingDataType.String,
    field: ["Address", "PostalCode"]
  },
  "fields.propertyAddress": {
    type: QuoteMappingDataType.String,
    field: ["Address", "AddressLineText"]
  },
  "fields.city": {
    type: QuoteMappingDataType.String,
    field: ["Address", "CityName"]
  }
};

export const rentalQuotePOSTApiToSnapshotMapping: Partial<{
  [path in DotNestedKeys<RentalQuotePOSTApiFields>]: {
    type: QuoteMappingDataType;
    field: DotNestedKeys<ElphiDealSnapshotEntity>;
  };
}> = {
  "fields.submittedDate": {
    type: QuoteMappingDataType.TimestampToDate,
    field: "createdAt"
  }
};

export const rentalQuoteGETApiToDealOverridesMapping: {
  [path: string]: string[];
} = {
  "exceptionPricingOverride.finalRateOverride": [
    "quote",
    "aggregations",
    "PricingEngineNoteRateOverridePercent"
  ]
};

export const dealQuoteKeysToRemoveIfEmpty = [
  "fields.exceptionPricingOverride.finalRateOverride",
  "fields.exceptionPricingOverride.arvOverride",
  "fields.exceptionPricingOverride.blendedLTCOverride",
  "fields.exceptionPricingOverride.purchaseLTCOverride"
];

export type RentalQuoteGETFields = SharedQuoteFields & {
  // GET fields
  LtvEligibility: ThirdPartyField;
  PrepaymentPenaltyType: ThirdPartyField;
  MonthlyPrincipalInterestPaymentAmount: ThirdPartyField;
  MonthlyTaxInsurancePaymentAmount: ThirdPartyField;
  MonthlyHomeownersAssociationAmount: ThirdPartyField;
  TotalMonthlyPITIPaymentAmount: ThirdPartyField;
  DebtServiceCoverageRatioPercent: ThirdPartyField;
  BuyDownFeeAmount: ThirdPartyField;
  BuyUpFeeReductionAmount: ThirdPartyField;
  PricingEngineNoteRateOverridePercent: ThirdPartyField;
  Margin: ThirdPartyField;
  FloorPercent: ThirdPartyField;
  LifetimeCapPercent: ThirdPartyField;
};
export type RentalQuotePOSTFields = SharedQuoteFields &
  Partial<{
    UnderPropertyValuationAmount: AggregationField;
    PrimaryBorrowerCreditScore: AggregationField;
    StateNY: AggregationField;
    InternalL1CRefinanceIndicator: AggregationField;
    BorrowingEntity: AggregationField;
    OverPropertyValuationAmount: AggregationField;
    UnderPropertyValuationTotalCost: AggregationField;
    TotalValuationOrPurchasePriceAmount: AggregationField;
    PropertyTypeCondoIndicator: AggregationField;
    PropertyValuationAmountUnderIndicator: AggregationField;
    USCitizenshipIndicator: AggregationField;
    SeasonalRental: AggregationField;
    PropertyTypeMultifamilyIndicator: AggregationField;
    scenario: PricingEngineScenario;
    PricingEngineNoteRateOverridePercent: ThirdPartyField;
  }>;

export type RentalQuoteFields = RentalQuotePOSTFields & RentalQuoteGETFields;

export type QuoteInputFields = {
  id?: string;
  quoteType?: string;
  brokerFee?: number;
  borrowerIsReferral?: string;
  appraisedVal1?: number;
  creditScore?: number;
  submissionType?: string;
  annualTaxes?: number;
  propertiesNY?: string;
  state?: string;
  zip?: string;
  propertyAddress?: string;
  city?: string;
  annualInsurance?: string;
  refiL1cFixNFlip?: string;
  buyUpDownOption?: string;
  username?: string;
  numOfProperties?: string;
  overrideMaster?: boolean;
  annualFloodInsurance?: string;
  borrowingEntity?: string;
  mortgagePayoffs?: string;
  appraisedVal3?: string;
  totalCosts1?: string;
  monthlyRent?: string;
  sreoPropertyNumber?: number;
  productType?: string;
  purchasePrice?: string;
  prepaymentPenalty?: string;
  borrowerNetWorth?: string;
  yieldSpread?: number;
  annualHOA?: string;
  base?: {
    condo?: string;
    fixToRent?: string;
    propertyVal?: string;
  };
  buyDownPercentage?: number;
  brokerProcessingFee?: number;
  appraisalFee?: number;
  usCitizen?: string;
  isTier5Borrower?: string;
  isZeroOrigFee?: string;
  seasonalRental?: string;
  fiveNineUnit?: string;
  submittedDate?: string;
  exceptionPricingOverride?: {
    autoRate?: number;
    autoFee?: number;
    commissionReduction?: number;
    finalRateOverride?: number;
  };
  scenario?: {
    scenarioId?: number;
    included?: string;
    preferred?: string;
    ltvPercentage?: number;
  }[];
  milestone?: string;
};

export type OutputScenario = {
  ltvEligibility?: string;
  estimatedValofProperty?: string;
  numOfProperties?: number;
  originationFee?: number;
  processingFee?: number;
  brokerFee?: number;
  insuranceCertifications?: number;
  interestReserveEscrow?: number;
  prepaymentPenalty?: number;
  initFixedInterestRate?: number;
  monthlyPrincipalInterest?: number;
  monthlyTaxesInsurance?: number;
  monthlyHOADues?: number;
  monthlyTotalPayment?: number;
  rentDebtRatio?: number;
  buyUpDownFee?: number;
  warnings?: string;
  brokerProcessingFeeCalc?: string;
  feeLLPAs?: string;
  rateLLPAs?: string;
  overridePricing?: string;
  finalRateOverride?: string;
  margin?: string;
  floor?: string;
  lifetimeCap?: string;
};

export type RentalQuoteOutputFields = {
  id?: string;
  validQuote?: boolean;
  workbookId?: string;
  termSheetTitle?: string;
  validationErrors?: string;
  feeLLPAs?: string;
  rateLLPAs?: string;
  originationFeePercent?: number;
  overridePricing?: string;
  scenario?: OutputScenario[];
};

export type RentalQuotePOSTApiFields = {
  id: string;
  fields: QuoteInputFields;
};

export type RentalQuoteGETResponse = {
  quoteInfo: {
    id: string;
    program: string;
    product: LoanProductType;
  } & object;
  input: QuoteInputFields & object;
  output: RentalQuoteOutputFields & object;
};

export type RentalQuoteGETApiFields = {
  quoteInfo: any;
  input: any;
  output: any;
};
