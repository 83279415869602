import {
  AggregationFocusType,
  AggregationLeasedFinancedUnitsFieldValueType,
  AppraisalCoordinatorType,
  AppraisalType,
  AppraisalValueDerivationType,
  BillingFrequencyType,
  BooleanSelectType,
  ConditionRatingType,
  ConstructionBudgetAssessmentType,
  FloodCertificateVendorType,
  FloodCheckStatus,
  FloodCheckStatusShortType,
  FloodInsurancePremiumPaymentType,
  InspectionInitialDelayReasonStatusType,
  InspectionOrderType,
  IntegrationType,
  LeasedFinancedUnit,
  LeasedFinancedUnitKeys,
  LeaseStatusType,
  LeaseStrategyType,
  Property,
  PropertyInsurancePremiumPaymentType,
  PropertyLoanPurpose,
  PropertyReportVendorType,
  PropertyRightsOwnershipType,
  PropertyType,
  PropertyZoningComplianceRatingType,
  QualityRatingType,
  RentalDataVendorType,
  ServiceLinkOrderStatusShortType,
  ServiceLinkOrderStatusType,
  SiteXTrxStatus,
  StateCode,
  StateName
} from "@elphi/types";
import { NOT_AVAILABLE } from "../../../../constants/common";
import {
  booleanOptions,
  createOptionsFromEnum,
  createOptionsFromEnumAllowUndefined,
  createOptionsFromEnumSeparateLabels
} from "../../../utils/formUtils";
import { mapEnumValues } from "../../../utils/general.utils";
import { FieldType } from "../../fieldFormat.types";
import {
  addressFieldSpecs,
  nestedAddressFieldSpecs
} from "../address/address.fields";
import { baseEntityFormFieldSpecs } from "../baseEntity.fields";
import {
  EntityFormFieldSpecs,
  EntityFormFieldSpecsHelper,
  SpecsBaseOmit
} from "../fields.types";
import { createSpecWithFieldMeta } from "../utils/fieldMeta.utils";

type UnitFieldSpecs = EntityFormFieldSpecs<LeasedFinancedUnit>;

export const generateListUnitAdjustedRentAmount = <
  T extends keyof typeof LeasedFinancedUnitKeys
>(
  key: T
): EntityFormFieldSpecsHelper<
  Partial<AggregationLeasedFinancedUnitsFieldValueType>,
  ["aggregations", "LeasedFinancedUnits", T]
> => {
  return {
    AdjustedRentAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "LeasedFinancedUnits",
          key,
          "AdjustedRentAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Adjusted Rent Amount"
      },
      override: {
        fieldKey: [
          "aggregations",
          "LeasedFinancedUnits",
          key,
          "AdjustedRentAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Adjusted Rent Amount"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "LeasedFinancedUnits",
          key,
          "AdjustedRentAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  };
};

export const unitFieldSpecs: UnitFieldSpecs = {
  AdjustedRentAmount: {
    fieldKey: ["AdjustedRentAmount"],
    fieldType: FieldType.Money,
    label: "Unit Adjusted Rent Amount"
  },
  CorporateLeaseIndicator: {
    fieldKey: ["CorporateLeaseIndicator"],
    fieldType: FieldType.Boolean,
    label: "Corporate Lease?",
    options: booleanOptions
  },
  LeaseExpirationDate: {
    fieldKey: ["LeaseExpirationDate"],
    fieldType: FieldType.Date,
    label: "Lease Expiration"
  },
  LeaseStatusType: {
    fieldKey: ["LeaseStatusType"],
    fieldType: FieldType.SingleSelect,
    label: "Lease Status",
    options: createOptionsFromEnum(LeaseStatusType)
  },
  PaymentVerificationIndicator: {
    fieldKey: ["PaymentVerificationIndicator"],
    fieldType: FieldType.Boolean,
    label: "Payment Verification",
    options: booleanOptions
  },
  RentReportConfidenceScore: {
    fieldKey: ["RentReportConfidenceScore"],
    fieldType: FieldType.Percentage,
    label: "Rent Report Confidence Score"
  },
  UnitName: {
    fieldKey: ["UnitName"],
    fieldType: FieldType.String,
    label: "Unit Name"
  },
  MonthlyLeaseRentAmount: {
    fieldKey: ["MonthlyLeaseRentAmount"],
    fieldType: FieldType.Money,
    label: "Contracted Monthly Rent"
  },
  MonthlyMarketRentAmount: {
    fieldKey: ["MonthlyMarketRentAmount"],
    fieldType: FieldType.Money,
    label: "Monthly Market Rent"
  }
};

export type PropertyFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<Omit<Property, "SheetMetadata">>
>;

const propertyBaseSpecFields: PropertyFieldSpecs = {
  aggregations: {
    Integrations: {
      [IntegrationType.FloodCertificate]: {
        [FloodCertificateVendorType.ServiceLink]: {
          SpecialFloodHazardAreaIndicator: {
            calculated: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "SpecialFloodHazardAreaIndicator",
                "calculated"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Special Flood Hazard Area?",
              options: mapEnumValues(BooleanSelectType)
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "SpecialFloodHazardAreaIndicator",
                "override"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Special Flood Hazard Area?",
              options: mapEnumValues(BooleanSelectType)
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "SpecialFloodHazardAreaIndicator",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          OrderNumber: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "OrderNumber",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Order Number"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "OrderNumber",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Order Number"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "OrderNumber",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          DeterminationDate: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "DeterminationDate",
                "thirdParty"
              ],
              fieldType: FieldType.Date,
              label: "Determination Date"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "DeterminationDate",
                "override"
              ],
              fieldType: FieldType.Date,
              label: "Determination Date"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "DeterminationDate",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          ReturnDate: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "ReturnDate",
                "thirdParty"
              ],
              fieldType: FieldType.Date,
              label: "Order Return Date"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "ReturnDate",
                "override"
              ],
              fieldType: FieldType.Date,
              label: "Order Return Date"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "ReturnDate",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          ReturnTime: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "ReturnTime",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "ReturnTime",
                "override"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "ReturnTime",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          FloodCheckStatus: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodCheckStatus",
                "thirdParty"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Flood Insurance Required?",
              options: createOptionsFromEnumSeparateLabels(
                FloodCheckStatusShortType,
                FloodCheckStatus
              )
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodCheckStatus",
                "override"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Flood Insurance Required?",
              options: createOptionsFromEnumSeparateLabels(
                FloodCheckStatusShortType,
                FloodCheckStatus
              )
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodCheckStatus",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          FloodCheckStatusDescription: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodCheckStatusDescription",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodCheckStatusDescription",
                "override"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodCheckStatusDescription",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          FloodCheckInfo: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodCheckInfo",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodCheckInfo",
                "override"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodCheckInfo",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          Disclaimer: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "Disclaimer",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "Disclaimer",
                "override"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "Disclaimer",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          OrderStatus: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "OrderStatus",
                "thirdParty"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Order Status",
              options: createOptionsFromEnumSeparateLabels(
                ServiceLinkOrderStatusShortType,
                ServiceLinkOrderStatusType
              )
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "OrderStatus",
                "override"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Order Status",
              options: createOptionsFromEnumSeparateLabels(
                ServiceLinkOrderStatusShortType,
                ServiceLinkOrderStatusType
              )
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "OrderStatus",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          NFIPMapCommunityName: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPMapCommunityName",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "NFIP Map Community Name"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPMapCommunityName",
                "override"
              ],
              fieldType: FieldType.String,
              label: "NFIP Map Community Name"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPMapCommunityName",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          NFIPMapCommunityNumber: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPMapCommunityNumber",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "NFIP Map Community Number"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPMapCommunityNumber",
                "override"
              ],
              fieldType: FieldType.String,
              label: "NFIP Map Community Number"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPMapCommunityNumber",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          NFIPLegalCommunityNumber: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPLegalCommunityNumber",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "NFIP Legal Community Number"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPLegalCommunityNumber",
                "override"
              ],
              fieldType: FieldType.String,
              label: "NFIP Legal Community Number"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPLegalCommunityNumber",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          NFIPLegalCommunityName: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPLegalCommunityName",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "NFIP Legal Community Name"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPLegalCommunityName",
                "override"
              ],
              fieldType: FieldType.String,
              label: "NFIP Legal Community Name"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPLegalCommunityName",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          NFIPMapPanelNumber: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPMapPanelNumber",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "NFIP Map Panel Number"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPMapPanelNumber",
                "override"
              ],
              fieldType: FieldType.String,
              label: "NFIP Map Panel Number"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPMapPanelNumber",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          NFIPPrgStat: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPPrgStat",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPPrgStat",
                "override"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "NFIPPrgStat",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          FloodMapDate: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodMapDate",
                "thirdParty"
              ],
              fieldType: FieldType.Date,
              label: "Flood Map Date"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodMapDate",
                "override"
              ],
              fieldType: FieldType.Date,
              label: "Flood Map Date"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodMapDate",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          FloodZone: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodZone",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Flood Zone"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodZone",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Flood Zone"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "FloodZone",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          GeoPropertyAddress1: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoPropertyAddress1",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoPropertyAddress1",
                "override"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoPropertyAddress1",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          GeoCity: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoCity",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoCity",
                "override"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoCity",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          GeoStateCode: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoStateCode",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoStateCode",
                "override"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoStateCode",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          GeoZipLast4: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoZipLast4",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoZipLast4",
                "override"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoZipLast4",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          GeoZipFirst5: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoZipFirst5",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoZipFirst5",
                "override"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoZipFirst5",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          GeoCounty: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoCounty",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoCounty",
                "override"
              ],
              fieldType: FieldType.String,
              label: NOT_AVAILABLE
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "GeoCounty",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          PropertyMSAMD: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "PropertyMSAMD",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Metro Statistical Area (MSD)"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "PropertyMSAMD",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Metro Statistical Area (MSD)"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "PropertyMSAMD",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          TSDNComments: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "TSDNComments",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Researcher Comments"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "TSDNComments",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Researcher Comments"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "TSDNComments",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          RequestedInformation: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "RequestedInformation",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Additional Requested Information"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "RequestedInformation",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Additional Requested Information"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "RequestedInformation",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          // Error Response
          ResultDescription: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "ResultDescription",
                "thirdParty"
              ],
              fieldType: FieldType.RichText,
              label: "Error Result Description"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "ResultDescription",
                "override"
              ],
              fieldType: FieldType.RichText,
              label: "Error Result Description"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "ResultDescription",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          ResultCode: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "ResultCode",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Error Result Code"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "ResultCode",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Error Result Code"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.FloodCertificate,
                FloodCertificateVendorType.ServiceLink,
                "ResultCode",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          }
        }
      },
      [IntegrationType.PropertyReport]: {
        [PropertyReportVendorType.SiteX]: {
          addressLineText: {
            thirdParty: {
              ...addressFieldSpecs.AddressLineText,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "addressLineText",
                "thirdParty"
              ],
              label: "Property Report Address Line 1"
            },
            focused: {
              ...addressFieldSpecs.AddressLineText,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "addressLineText",
                "focused"
              ],
              label: "Focus"
            },
            override: {
              ...addressFieldSpecs.AddressLineText,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "addressLineText",
                "override"
              ],
              label: "Property Report Address Line 1"
            }
          },
          addressUnitIdentifier: {
            thirdParty: {
              ...addressFieldSpecs.AddressUnitIdentifier,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "addressUnitIdentifier",
                "thirdParty"
              ],
              label: "Property Report Address Line 2"
            },
            focused: {
              ...addressFieldSpecs.AddressUnitIdentifier,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "addressUnitIdentifier",
                "focused"
              ],
              label: "Focus"
            },
            override: {
              ...addressFieldSpecs.AddressUnitIdentifier,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "addressUnitIdentifier",
                "override"
              ],
              label: "Property Report Address Line 2"
            }
          },
          cityName: {
            thirdParty: {
              ...addressFieldSpecs.CityName,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "cityName",
                "thirdParty"
              ],
              label: "Property Report City Name"
            },
            focused: {
              ...addressFieldSpecs.CityName,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "cityName",
                "focused"
              ],
              label: "Focus"
            },
            override: {
              ...addressFieldSpecs.CityName,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "cityName",
                "override"
              ],
              label: "Property Report City Name"
            }
          },
          stateCode: {
            thirdParty: {
              ...addressFieldSpecs.StateCode,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "stateCode",
                "thirdParty"
              ],
              label: "Property Report State Code"
            },
            focused: {
              ...addressFieldSpecs.StateCode,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "stateCode",
                "focused"
              ],
              label: "Focus"
            },
            override: {
              ...addressFieldSpecs.StateCode,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "stateCode",
                "override"
              ],
              label: "Property Report State Code"
            }
          },
          postalCode: {
            thirdParty: {
              ...addressFieldSpecs.PostalCode,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "postalCode",
                "thirdParty"
              ],
              label: "Property Report Zip Code"
            },
            focused: {
              ...addressFieldSpecs.PostalCode,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "postalCode",
                "focused"
              ],
              label: "Focus"
            },
            override: {
              ...addressFieldSpecs.PostalCode,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "postalCode",
                "override"
              ],
              label: "Property Report Zip Code"
            }
          },
          countyName: {
            thirdParty: {
              ...addressFieldSpecs.CountyName,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "countyName",
                "thirdParty"
              ],
              label: "Property Report County"
            },
            focused: {
              ...addressFieldSpecs.CountyName,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "countyName",
                "focused"
              ],
              label: "Focus"
            },
            override: {
              ...addressFieldSpecs.CountyName,
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "countyName",
                "override"
              ],
              label: "Property Report County"
            }
          },
          assessorParcelNumber: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "assessorParcelNumber",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Property Report APN"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "assessorParcelNumber",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "assessorParcelNumber",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Property Report APN"
            }
          },
          fips: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "fips",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "FIPS"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "fips",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "fips",
                "override"
              ],
              fieldType: FieldType.String,
              label: "FIPS"
            }
          },
          orderStatus: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "orderStatus",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Order Status"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "orderStatus",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              options: mapEnumValues(SiteXTrxStatus),
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "orderStatus",
                "override"
              ],
              fieldType: FieldType.SingleSelect,
              options: mapEnumValues(SiteXTrxStatus),
              label: "Order Status"
            }
          },
          orderCompletedOn: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "orderCompletedOn",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Order Completed On"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "orderCompletedOn",
                "focused"
              ],
              fieldType: FieldType.Date,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.PropertyReport,
                PropertyReportVendorType.SiteX,
                "orderCompletedOn",
                "override"
              ],
              fieldType: FieldType.Date,
              label: "Order Completed On"
            }
          }
        }
      },
      [IntegrationType.RentalData]: {
        [RentalDataVendorType.RentRange]: {
          confidenceScore: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.RentalData,
                RentalDataVendorType.RentRange,
                "confidenceScore",
                "thirdParty"
              ],
              fieldType: FieldType.Number,
              label: "Confidence Score"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.RentalData,
                RentalDataVendorType.RentRange,
                "confidenceScore",
                "override"
              ],
              fieldType: FieldType.Number,
              label: "Confidence Score"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.RentalData,
                RentalDataVendorType.RentRange,
                "confidenceScore",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          errorMessage: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.RentalData,
                RentalDataVendorType.RentRange,
                "errorMessage",
                "thirdParty"
              ],
              fieldType: FieldType.RichText,
              label: "Error Message"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.RentalData,
                RentalDataVendorType.RentRange,
                "errorMessage",
                "override"
              ],
              fieldType: FieldType.RichText,
              label: "Error Message"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.RentalData,
                RentalDataVendorType.RentRange,
                "errorMessage",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          orderStatus: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.RentalData,
                RentalDataVendorType.RentRange,
                "orderStatus",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Order Status"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.RentalData,
                RentalDataVendorType.RentRange,
                "orderStatus",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Order Status"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.RentalData,
                RentalDataVendorType.RentRange,
                "orderStatus",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          },
          requestId: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.RentalData,
                RentalDataVendorType.RentRange,
                "requestId",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Request Id"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.RentalData,
                RentalDataVendorType.RentRange,
                "requestId",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Request Id"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.RentalData,
                RentalDataVendorType.RentRange,
                "requestId",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
            }
          }
        }
      }
    },
    InitialPropertyInsuranceEscrowAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "InitialPropertyInsuranceEscrowAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Initial Property Insurance Escrow"
      },
      override: {
        fieldKey: [
          "aggregations",
          "InitialPropertyInsuranceEscrowAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Initial Property Insurance Escrow"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "InitialPropertyInsuranceEscrowAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    InitialPropertyInsuranceEscrowMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "InitialPropertyInsuranceEscrowMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "Initial Property Insurance Escrow (Months)"
      },
      override: {
        fieldKey: [
          "aggregations",
          "InitialPropertyInsuranceEscrowMonthCount",
          "override"
        ],
        fieldType: FieldType.Number,
        label: "Initial Property Insurance Escrow (Months)"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "InitialPropertyInsuranceEscrowMonthCount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    SpecialAssessmentInitialTaxEscrowAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "SpecialAssessmentInitialTaxEscrowAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Special Assessment Initial Tax Escrow"
      },
      override: {
        fieldKey: [
          "aggregations",
          "SpecialAssessmentInitialTaxEscrowAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Special Assessment Initial Tax Escrow"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "SpecialAssessmentInitialTaxEscrowAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    SpecialAssessmentInitialTaxEscrowMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "SpecialAssessmentInitialTaxEscrowMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "Special Assessment Initial Tax Escrow (Months)"
      },
      override: {
        fieldKey: [
          "aggregations",
          "SpecialAssessmentInitialTaxEscrowMonthCount",
          "override"
        ],
        fieldType: FieldType.Number,
        label: "Special Assessment Initial Tax Escrow (Months)"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "SpecialAssessmentInitialTaxEscrowMonthCount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    CityInitialTaxEscrowMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "CityInitialTaxEscrowMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "City Initial Tax Escrow (Months)"
      },
      override: {
        fieldKey: [
          "aggregations",
          "CityInitialTaxEscrowMonthCount",
          "override"
        ],
        fieldType: FieldType.Number,
        label: "City Initial Tax Escrow (Months)"
      },
      focused: {
        fieldKey: ["aggregations", "CityInitialTaxEscrowMonthCount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    CityInitialTaxEscrowAmount: {
      calculated: {
        fieldKey: ["aggregations", "CityInitialTaxEscrowAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "City Initial Tax Escrow"
      },
      focused: {
        fieldKey: ["aggregations", "CityInitialTaxEscrowAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      },
      override: {
        fieldKey: ["aggregations", "CityInitialTaxEscrowAmount", "override"],
        fieldType: FieldType.Money,
        label: "City Initial Tax Escrow"
      }
    },
    CountyInitialTaxEscrowMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "CountyInitialTaxEscrowMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "County Initial Tax Escrow (Months)"
      },
      override: {
        fieldKey: [
          "aggregations",
          "CountyInitialTaxEscrowMonthCount",
          "override"
        ],
        fieldType: FieldType.Number,
        label: "County Initial Tax Escrow (Months)"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "CountyInitialTaxEscrowMonthCount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    CountyInitialTaxEscrowAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "CountyInitialTaxEscrowAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "County Initial Tax Escrow"
      },
      override: {
        fieldKey: ["aggregations", "CountyInitialTaxEscrowAmount", "override"],
        fieldType: FieldType.Money,
        label: "County Initial Tax Escrow"
      },
      focused: {
        fieldKey: ["aggregations", "CountyInitialTaxEscrowAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    InitialFloodInsuranceEscrowAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "InitialFloodInsuranceEscrowAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Initial Flood Insurance Escrow"
      },
      override: {
        fieldKey: [
          "aggregations",
          "InitialFloodInsuranceEscrowAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Initial Flood Insurance Escrow"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "InitialFloodInsuranceEscrowAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    InitialFloodInsuranceEscrowMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "InitialFloodInsuranceEscrowMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "Initial Flood Insurance Escrow (Months)"
      },
      override: {
        fieldKey: [
          "aggregations",
          "InitialFloodInsuranceEscrowMonthCount",
          "override"
        ],
        fieldType: FieldType.Number,
        label: "Initial Flood Insurance Escrow (Months)"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "InitialFloodInsuranceEscrowMonthCount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    MonthlyLeaseRentAmount: {
      calculated: {
        fieldKey: ["aggregations", "MonthlyLeaseRentAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Contracted Monthly Rent"
      },
      override: {
        fieldKey: ["aggregations", "MonthlyLeaseRentAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Contracted Monthly Rent"
      },
      focused: {
        fieldKey: ["aggregations", "MonthlyLeaseRentAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalInsuranceEscrowAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalInsuranceEscrowAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Insurance Escrow"
      },
      override: {
        fieldKey: ["aggregations", "TotalInsuranceEscrowAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Insurance Escrow"
      },
      focused: {
        fieldKey: ["aggregations", "TotalInsuranceEscrowAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalTaxEscrowAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalTaxEscrowAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Tax Escrow"
      },
      override: {
        fieldKey: ["aggregations", "TotalTaxEscrowAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Tax Escrow"
      },
      focused: {
        fieldKey: ["aggregations", "TotalTaxEscrowAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    MonthlyMarketRentAmount: {
      calculated: {
        fieldKey: ["aggregations", "MonthlyMarketRentAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Monthly Market Rent"
      },
      override: {
        fieldKey: ["aggregations", "MonthlyMarketRentAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Monthly Market Rent"
      },
      focused: {
        fieldKey: ["aggregations", "MonthlyMarketRentAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    AdjustedRentAmount: {
      calculated: {
        fieldKey: ["aggregations", "AdjustedRentAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Unit Adjusted Rental Income"
      },
      override: {
        fieldKey: ["aggregations", "AdjustedRentAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Unit Adjusted Rental Income"
      },
      focused: {
        fieldKey: ["aggregations", "AdjustedRentAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    AnnualTaxAmount: {
      calculated: {
        fieldKey: ["aggregations", "AnnualTaxAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Annual Tax Amount"
      },
      override: {
        fieldKey: ["aggregations", "AnnualTaxAmount", "override"],
        fieldType: FieldType.Money,
        label: "Annual Tax Amount"
      },
      focused: {
        fieldKey: ["aggregations", "AnnualTaxAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LeasedFinancedUnits: {
      unit0: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit0),
      unit1: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit1),
      unit2: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit2),
      unit3: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit3),
      unit4: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit4),
      unit5: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit5),
      unit6: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit6),
      unit7: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit7),
      unit8: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit8)
    },
    PricingEngineLTARVRatePercent: {
      calculated: {
        fieldKey: [
          "aggregations",
          "PricingEngineLTARVRatePercent",
          "calculated"
        ],
        fieldType: FieldType.Percentage,
        label: "Loan to After Repair Value (LTARV) (%)"
      },
      override: {
        fieldKey: ["aggregations", "PricingEngineLTARVRatePercent", "override"],
        fieldType: FieldType.Percentage,
        label: "Loan to After Repair Value (LTARV) (%)"
      },
      focused: {
        fieldKey: ["aggregations", "PricingEngineLTARVRatePercent", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    BridgeLTVPercent: {
      thirdParty: {
        fieldKey: ["aggregations", "BridgeLTVPercent", "thirdParty"],
        fieldType: FieldType.Percentage,
        label: "Bridge Plus - Loan to Value (%)"
      },
      override: {
        fieldKey: ["aggregations", "BridgeLTVPercent", "override"],
        fieldType: FieldType.Percentage,
        label: "Bridge Plus - Loan to Value (%)"
      },
      focused: {
        fieldKey: ["aggregations", "BridgeLTVPercent", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    AllocatedLoanAmount: {
      calculated: {
        fieldKey: ["aggregations", "AllocatedLoanAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Allocated Loan Amount"
      },
      override: {
        fieldKey: ["aggregations", "AllocatedLoanAmount", "override"],
        fieldType: FieldType.Money,
        label: "Allocated Loan Amount"
      },
      focused: {
        fieldKey: ["aggregations", "AllocatedLoanAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalCostAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalCostAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Property Total Cost"
      },
      override: {
        fieldKey: ["aggregations", "TotalCostAmount", "override"],
        fieldType: FieldType.Money,
        label: "Property Total Cost"
      },
      focused: {
        fieldKey: ["aggregations", "TotalCostAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LTCRatePercent: {
      calculated: {
        fieldKey: ["aggregations", "LTCRatePercent", "calculated"],
        fieldType: FieldType.Percentage,
        label: "LTC (%)"
      },
      override: {
        fieldKey: ["aggregations", "LTCRatePercent", "override"],
        fieldType: FieldType.Percentage,
        label: "LTC (%)"
      },
      focused: {
        fieldKey: ["aggregations", "LTCRatePercent", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  },
  ...baseEntityFormFieldSpecs,
  Address: {
    FullAddressName: {
      fieldKey: ["Address", "FullAddressName"],
      fieldType: FieldType.String,
      label: "Full Property Address"
    },
    LegalDescription: {
      fieldKey: ["Address", "LegalDescription"],
      fieldType: FieldType.RichText,
      label: "Legal Description"
    },
    PropertyBlockIdentifier: {
      fieldKey: ["Address", "PropertyBlockIdentifier"],
      fieldType: FieldType.String,
      label: "Block"
    },
    PropertyIdentifier: {
      fieldKey: ["Address", "PropertyIdentifier"],
      fieldType: FieldType.String,
      label: "Property ID"
    },
    PropertyLotIdentifier: {
      fieldKey: ["Address", "PropertyLotIdentifier"],
      fieldType: FieldType.String,
      label: "Lot Number"
    },
    PropertySubdivisionIdentifier: {
      fieldKey: ["Address", "PropertySubdivisionIdentifier"],
      fieldType: FieldType.String,
      label: "Subdivision"
    },
    AddressLineText: nestedAddressFieldSpecs.Address.AddressLineText,
    AddressUnitIdentifier:
      nestedAddressFieldSpecs.Address.AddressUnitIdentifier,
    CityName: nestedAddressFieldSpecs.Address.CityName,
    CountyName: nestedAddressFieldSpecs.Address.CountyName,
    PostalCode: nestedAddressFieldSpecs.Address.PostalCode,
    StateCode: nestedAddressFieldSpecs.Address.StateCode,
    StateName: nestedAddressFieldSpecs.Address.StateName
  },
  AllocatedRDSRRatePercent: {
    fieldKey: ["AllocatedRDSRRatePercent"],
    fieldType: FieldType.Percentage,
    label: "Allocated RDSR"
  },
  AnnualHomeownersAssociationFeeAmount: {
    fieldKey: ["AnnualHomeownersAssociationFeeAmount"],
    fieldType: FieldType.Money,
    label: "Annual HOA Fees"
  },
  AnnualCapitalExpenditure: {
    fieldKey: ["AnnualCapitalExpenditure"],
    fieldType: FieldType.Money,
    label: "Annual Capital Expenditure"
  },
  AnnualTaxesAmount: {
    fieldKey: ["AnnualTaxesAmount"],
    fieldType: FieldType.Money,
    label: "Annual Taxes ($)"
  },
  Appraisal: {
    AppraisalCompletedDate: {
      fieldKey: ["Appraisal", "AppraisalCompletedDate"],
      fieldType: FieldType.Date,
      label: "Appraisal Completed Date"
    },
    AppraisalCoordinator: {
      fieldKey: ["Appraisal", "AppraisalCoordinator"],
      fieldType: FieldType.SingleSelect,
      label: "Appraisal Coordinator",
      options: createOptionsFromEnum(AppraisalCoordinatorType)
    },
    AppraisalDate: {
      fieldKey: ["Appraisal", "AppraisalDate"],
      fieldType: FieldType.Date,
      label: "Appraisal Date"
    },
    AppraisalEffectiveDate: {
      fieldKey: ["Appraisal", "AppraisalEffectiveDate"],
      fieldType: FieldType.Date,
      label: "Appraisal Effective Date"
    },
    AppraisalExpirationDate: {
      fieldKey: ["Appraisal", "AppraisalExpirationDate"],
      fieldType: FieldType.Date,
      label: "Appraisal Expiration Date"
    },
    AppraisalForm1007RequiredIndicator: {
      fieldKey: ["Appraisal", "AppraisalForm1007RequiredIndicator"],
      fieldType: FieldType.Boolean,
      label: "Is 1007 Needed?",
      options: booleanOptions
    },
    AppraisalOrderedDate: {
      fieldKey: ["Appraisal", "AppraisalOrderedDate"],
      fieldType: FieldType.Date,
      label: "Appraisal Ordered Date"
    },
    AppraisalType: {
      fieldKey: ["Appraisal", "AppraisalType"],
      fieldType: FieldType.SingleSelect,
      label: "Appraisal Type",
      options: createOptionsFromEnum(AppraisalType)
    },
    AppraisalValueDerivationType: {
      fieldKey: ["Appraisal", "AppraisalValueDerivationType"],
      fieldType: FieldType.SingleSelect,
      label: "Appraisal Methodology",
      options: createOptionsFromEnum(AppraisalValueDerivationType)
    },
    ConditionRatingType: {
      fieldKey: ["Appraisal", "ConditionRatingType"],
      fieldType: FieldType.SingleSelect,
      label: "Condition",
      options: createOptionsFromEnum(ConditionRatingType)
    },
    InternalValuationEligibilityIndicator: {
      fieldKey: ["Appraisal", "InternalValuationEligibilityIndicator"],
      fieldType: FieldType.Boolean,
      label: "L1C Val Eligible?",
      options: booleanOptions
    },
    PropertyAccessInformation: {
      fieldKey: ["Appraisal", "PropertyAccessInformation"],
      fieldType: FieldType.RichText,
      label: "Property Access Information"
    },
    PropertyAreaValue: {
      fieldKey: ["Appraisal", "PropertyAreaValue"],
      fieldType: FieldType.Number,
      label: "Square Foot"
    },
    PropertyBuiltYear: {
      fieldKey: ["Appraisal", "PropertyBuiltYear"],
      fieldType: FieldType.Year,
      label: "Year Built"
    },
    PropertyStructureBuiltYear: {
      fieldKey: ["Appraisal", "PropertyStructureBuiltYear"],
      fieldType: FieldType.Year,
      label: "Property Year Built"
    },
    PropertyValuationAmount: {
      fieldKey: ["Appraisal", "PropertyValuationAmount"],
      fieldType: FieldType.Money,
      label: "As-Is Appraised Value"
    },
    PropertyZoningComplianceRatingType: {
      fieldKey: ["Appraisal", "PropertyZoningComplianceRatingType"],
      fieldType: FieldType.SingleSelect,
      label: "Zoning Compliance",
      options: createOptionsFromEnum(PropertyZoningComplianceRatingType)
    },
    PropertyZoningDescription: {
      fieldKey: ["Appraisal", "PropertyZoningDescription"],
      fieldType: FieldType.String,
      label: "Zoning Description"
    },
    QualityRatingType: {
      fieldKey: ["Appraisal", "QualityRatingType"],
      fieldType: FieldType.SingleSelect,
      label: "Quality",
      options: createOptionsFromEnum(QualityRatingType)
    },
    SpecificZoningClassification: {
      fieldKey: ["Appraisal", "SpecificZoningClassification"],
      fieldType: FieldType.String,
      label: "Specific Zoning Classification"
    },
    SubjectToPropertyValuationAmount: {
      fieldKey: ["Appraisal", "SubjectToPropertyValuationAmount"],
      fieldType: FieldType.Money,
      label: "Subject-to Appraised Value"
    },
    TotalBathroomCount: {
      fieldKey: ["Appraisal", "TotalBathroomCount"],
      fieldType: FieldType.Decimal,
      label: "Bathrooms"
    },
    TotalBedroomCount: {
      fieldKey: ["Appraisal", "TotalBedroomCount"],
      fieldType: FieldType.Decimal,
      label: "Bedrooms"
    }
  },
  AppraisalManagementCompany: {
    ContactPointEmailValue: {
      fieldKey: ["AppraisalManagementCompany", "ContactPointEmailValue"],
      fieldType: FieldType.Email,
      label: "Point of Contact Email"
    },
    ContactPointTelephoneValue: {
      fieldKey: ["AppraisalManagementCompany", "ContactPointTelephoneValue"],
      fieldType: FieldType.Phone,
      label: "Point of Contact Phone Number"
    },
    RepresentativeFullName: {
      fieldKey: ["AppraisalManagementCompany", "RepresentativeFullName"],
      fieldType: FieldType.String,
      label: "Point of Contact Name"
    },
    AppraisalManagementCompanyName: {
      fieldKey: [
        "AppraisalManagementCompany",
        "AppraisalManagementCompanyName"
      ],
      fieldType: FieldType.String,
      label: "Appraisal Management Company"
    }
  },
  CapitalExpenditureAmount: {
    fieldKey: ["CapitalExpenditureAmount"],
    fieldType: FieldType.Money,
    label: "Capital Expenditure "
  },
  CEMARefinanceIndicator: {
    fieldKey: ["CEMARefinanceIndicator"],
    fieldType: FieldType.Boolean,
    label: "CEMA Refinance?",
    options: booleanOptions
  },
  CityAnnualTaxAmount: {
    fieldKey: ["CityAnnualTaxAmount"],
    fieldType: FieldType.Money,
    label: "City Annual Taxes"
  },
  CityTaxBillingFrequencyType: {
    fieldKey: ["CityTaxBillingFrequencyType"],
    fieldType: FieldType.SingleSelect,
    label: "City Tax Billing Frequency",
    options: createOptionsFromEnum(BillingFrequencyType)
  },
  CityTaxDueDate: {
    fieldKey: ["CityTaxDueDate"],
    fieldType: FieldType.Date,
    label: "CIty Tax Due Date"
  },
  CityTaxingAuthority: {
    fieldKey: ["CityTaxingAuthority"],
    fieldType: FieldType.String,
    label: "City Taxing Authority"
  },
  CondoCertificateComments: {
    fieldKey: ["CondoCertificateComments"],
    fieldType: FieldType.RichText,
    label: "Condo Certificate Comments"
  },
  CondoCertificateFeesCollectedIndicator: {
    fieldKey: ["CondoCertificateFeesCollectedIndicator"],
    fieldType: FieldType.Boolean,
    label: "Condo Cert. Fees Collected?",
    options: booleanOptions
  },
  CondoWarrantableIndicator: {
    fieldKey: ["CondoWarrantableIndicator"],
    fieldType: FieldType.Boolean,
    label: "Condo Warrantable",
    options: booleanOptions
  },
  ConstructionCostAmount: {
    fieldKey: ["ConstructionCostAmount"],
    fieldType: FieldType.Money,
    label: "Construction Budget"
  },
  ConstructionCostComments: {
    fieldKey: ["ConstructionCostComments"],
    fieldType: FieldType.RichText,
    label: "Construction Budget Comments"
  },
  ContractClosingDate: {
    fieldKey: ["ContractClosingDate"],
    fieldType: FieldType.Date,
    label: "Contract Closing Date"
  },
  ContractExecutionDate: {
    fieldKey: ["ContractExecutionDate"],
    fieldType: FieldType.Date,
    label: "Contract Execution Date"
  },
  CountyAnnualTaxAmount: {
    fieldKey: ["CountyAnnualTaxAmount"],
    fieldType: FieldType.Money,
    label: "County Annual Taxes"
  },
  CountyTaxBillingFrequencyType: {
    fieldKey: ["CountyTaxBillingFrequencyType"],
    fieldType: FieldType.SingleSelect,
    label: "County Tax Billing Frequency",
    options: createOptionsFromEnum(BillingFrequencyType)
  },
  CountyTaxDueDate: {
    fieldKey: ["CountyTaxDueDate"],
    fieldType: FieldType.Date,
    label: "County Tax Due Date"
  },
  CountyTaxingAuthority: {
    fieldKey: ["CountyTaxingAuthority"],
    fieldType: FieldType.String,
    label: "County Taxing Authority"
  },
  DeferredMaintenanceAmount: {
    fieldKey: ["DeferredMaintenanceAmount"],
    fieldType: FieldType.Money,
    label: "Deferred Maintenance"
  },
  FinancedUnitCount: {
    fieldKey: ["FinancedUnitCount"],
    fieldType: FieldType.Number,
    label: "Units"
  },
  FloodInsurance: {
    DeductibleAmount: {
      fieldKey: ["FloodInsurance", "DeductibleAmount"],
      fieldType: FieldType.Money,
      label: "Flood Insurance Deductible"
    },
    DwellingCoverageAmount: {
      fieldKey: ["FloodInsurance", "DwellingCoverageAmount"],
      fieldType: FieldType.Money,
      label: "Flood Insurance Dwelling Coverage Amount"
    },
    InsurancePremiumAmount: {
      fieldKey: ["FloodInsurance", "InsurancePremiumAmount"],
      fieldType: FieldType.Money,
      label: "Flood Insurance Premium Amount"
    },
    InsurancePremiumOutstandingAmount: {
      fieldKey: ["FloodInsurance", "InsurancePremiumOutstandingAmount"],
      fieldType: FieldType.Money,
      label: "Flood Insurance Premium Amount Outstanding"
    },
    InsurancePremiumPaymentType: {
      fieldKey: ["FloodInsurance", "InsurancePremiumPaymentType"],
      fieldType: FieldType.SingleSelect,
      label: "Flood Insurance Premium Payment",
      options: createOptionsFromEnum(FloodInsurancePremiumPaymentType)
    },
    NextPaymentDueDate: {
      fieldKey: ["FloodInsurance", "NextPaymentDueDate"],
      fieldType: FieldType.Date,
      label: "Flood Insurance Next Payment Due Date"
    },
    PolicyExpirationDate: {
      fieldKey: ["FloodInsurance", "PolicyExpirationDate"],
      fieldType: FieldType.Date,
      label: "Flood Insurance Policy Expiration Date"
    },
    PolicyNumber: {
      fieldKey: ["FloodInsurance", "PolicyNumber"],
      fieldType: FieldType.String,
      label: "Flood Insurance Policy Number"
    }
  },
  FloodInsuranceAgency: {
    ContactPointEmailValue: {
      fieldKey: ["FloodInsuranceAgency", "ContactPointEmailValue"],
      fieldType: FieldType.Email,
      label: "Flood Insurance Agency Email Address"
    },
    ContactPointTelephoneValue: {
      fieldKey: ["FloodInsuranceAgency", "ContactPointTelephoneValue"],
      fieldType: FieldType.Phone,
      label: "Flood Insurance Agency Phone Number"
    },
    FullName: {
      fieldKey: ["FloodInsuranceAgency", "FullName"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agency"
    },
    RepresentativeFullName: {
      fieldKey: ["FloodInsuranceAgency", "RepresentativeFullName"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agent"
    },
    AddressLineText: {
      fieldKey: ["FloodInsuranceAgency", "AddressLineText"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agency Address Line 1"
    },
    AddressUnitIdentifier: {
      fieldKey: ["FloodInsuranceAgency", "AddressUnitIdentifier"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agency Address Line 2"
    },
    CityName: {
      fieldKey: ["FloodInsuranceAgency", "CityName"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agency City"
    },
    CountyName: {
      fieldKey: ["FloodInsuranceAgency", "CountyName"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agency County"
    },
    PostalCode: {
      fieldKey: ["FloodInsuranceAgency", "PostalCode"],
      fieldType: FieldType.String,
      label: "Flood Insurance Agency Zip Code"
    },
    StateCode: {
      fieldKey: ["FloodInsuranceAgency", "StateCode"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromEnum(StateCode),
      label: "Flood Insurance Agency State Code"
    },
    StateName: {
      fieldKey: ["FloodInsuranceAgency", "StateName"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromEnum(StateName),
      label: "Flood Insurance Agency State Name"
    }
  },

  LeasedFinancedUnits: {
    unit0: {
      AdjustedRentAmount: {
        ...unitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit0", "AdjustedRentAmount"]
      },
      CorporateLeaseIndicator: {
        ...unitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit0", "CorporateLeaseIndicator"]
      },
      LeaseExpirationDate: {
        ...unitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit0", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...unitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit0", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...unitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit0", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...unitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit0", "MonthlyMarketRentAmount"]
      },
      PaymentVerificationIndicator: {
        ...unitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit0",
          "PaymentVerificationIndicator"
        ]
      },
      RentReportConfidenceScore: {
        ...unitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit0", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...unitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit0", "UnitName"]
      }
    },
    unit1: {
      AdjustedRentAmount: {
        ...unitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit1", "AdjustedRentAmount"]
      },
      CorporateLeaseIndicator: {
        ...unitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit1", "CorporateLeaseIndicator"]
      },
      LeaseExpirationDate: {
        ...unitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit1", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...unitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit1", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...unitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit1", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...unitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit1", "MonthlyMarketRentAmount"]
      },
      PaymentVerificationIndicator: {
        ...unitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit1",
          "PaymentVerificationIndicator"
        ]
      },
      RentReportConfidenceScore: {
        ...unitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit1", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...unitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit1", "UnitName"]
      }
    },
    unit2: {
      AdjustedRentAmount: {
        ...unitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit2", "AdjustedRentAmount"]
      },
      CorporateLeaseIndicator: {
        ...unitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit2", "CorporateLeaseIndicator"]
      },
      LeaseExpirationDate: {
        ...unitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit2", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...unitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit2", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...unitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit2", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...unitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit2", "MonthlyMarketRentAmount"]
      },
      PaymentVerificationIndicator: {
        ...unitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit2",
          "PaymentVerificationIndicator"
        ]
      },
      RentReportConfidenceScore: {
        ...unitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit2", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...unitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit2", "UnitName"]
      }
    },
    unit3: {
      AdjustedRentAmount: {
        ...unitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit3", "AdjustedRentAmount"]
      },
      CorporateLeaseIndicator: {
        ...unitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit3", "CorporateLeaseIndicator"]
      },
      LeaseExpirationDate: {
        ...unitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit3", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...unitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit3", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...unitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit3", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...unitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit3", "MonthlyMarketRentAmount"]
      },
      PaymentVerificationIndicator: {
        ...unitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit3",
          "PaymentVerificationIndicator"
        ]
      },
      RentReportConfidenceScore: {
        ...unitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit3", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...unitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit3", "UnitName"]
      }
    },
    unit4: {
      AdjustedRentAmount: {
        ...unitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit4", "AdjustedRentAmount"]
      },
      CorporateLeaseIndicator: {
        ...unitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit4", "CorporateLeaseIndicator"]
      },
      LeaseExpirationDate: {
        ...unitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit4", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...unitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit4", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...unitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit4", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...unitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit4", "MonthlyMarketRentAmount"]
      },
      PaymentVerificationIndicator: {
        ...unitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit4",
          "PaymentVerificationIndicator"
        ]
      },
      RentReportConfidenceScore: {
        ...unitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit4", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...unitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit4", "UnitName"]
      }
    },
    unit5: {
      AdjustedRentAmount: {
        ...unitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit5", "AdjustedRentAmount"]
      },
      CorporateLeaseIndicator: {
        ...unitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit5", "CorporateLeaseIndicator"]
      },
      LeaseExpirationDate: {
        ...unitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit5", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...unitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit5", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...unitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit5", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...unitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit5", "MonthlyMarketRentAmount"]
      },
      PaymentVerificationIndicator: {
        ...unitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit5",
          "PaymentVerificationIndicator"
        ]
      },
      RentReportConfidenceScore: {
        ...unitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit5", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...unitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit5", "UnitName"]
      }
    },
    unit6: {
      AdjustedRentAmount: {
        ...unitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit6", "AdjustedRentAmount"]
      },
      CorporateLeaseIndicator: {
        ...unitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit6", "CorporateLeaseIndicator"]
      },
      LeaseExpirationDate: {
        ...unitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit6", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...unitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit6", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...unitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit6", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...unitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit6", "MonthlyMarketRentAmount"]
      },
      PaymentVerificationIndicator: {
        ...unitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit6",
          "PaymentVerificationIndicator"
        ]
      },
      RentReportConfidenceScore: {
        ...unitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit6", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...unitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit6", "UnitName"]
      }
    },
    unit7: {
      AdjustedRentAmount: {
        ...unitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit7", "AdjustedRentAmount"]
      },
      CorporateLeaseIndicator: {
        ...unitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit7", "CorporateLeaseIndicator"]
      },
      LeaseExpirationDate: {
        ...unitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit7", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...unitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit7", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...unitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit7", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...unitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit7", "MonthlyMarketRentAmount"]
      },
      PaymentVerificationIndicator: {
        ...unitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit7",
          "PaymentVerificationIndicator"
        ]
      },
      RentReportConfidenceScore: {
        ...unitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit7", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...unitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit7", "UnitName"]
      }
    },
    unit8: {
      AdjustedRentAmount: {
        ...unitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit8", "AdjustedRentAmount"]
      },
      CorporateLeaseIndicator: {
        ...unitFieldSpecs.CorporateLeaseIndicator,
        fieldKey: ["LeasedFinancedUnits", "unit8", "CorporateLeaseIndicator"]
      },
      LeaseExpirationDate: {
        ...unitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit8", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...unitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit8", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...unitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit8", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...unitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit8", "MonthlyMarketRentAmount"]
      },
      PaymentVerificationIndicator: {
        ...unitFieldSpecs.PaymentVerificationIndicator,
        fieldKey: [
          "LeasedFinancedUnits",
          "unit8",
          "PaymentVerificationIndicator"
        ]
      },
      RentReportConfidenceScore: {
        ...unitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit8", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...unitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit8", "UnitName"]
      }
    }
  },
  LeaseExpirationDate: {
    fieldKey: ["LeaseExpirationDate"],
    fieldType: FieldType.Date,
    label: "Lease Expiration"
  },

  LeaseStatusType: {
    fieldKey: ["LeaseStatusType"],
    fieldType: FieldType.SingleSelect,
    label: "Lease Status",
    options: createOptionsFromEnum(LeaseStatusType)
  },
  LTARVAmount: {
    fieldKey: ["LTARVAmount"],
    fieldType: FieldType.Money,
    label: "Loan to After Repair Value (LTARV) ($)"
  },
  MortgageeContactFullName: {
    fieldKey: ["MortgageeContactFullName"],
    fieldType: FieldType.String,
    label: "Mortgagee Contact"
  },
  MortgageeContactPointEmailValue: {
    fieldKey: ["MortgageeContactPointEmailValue"],
    fieldType: FieldType.Email,
    label: "Mortgagee Email Address"
  },
  MortgageeContactPointTelephoneValue: {
    fieldKey: ["MortgageeContactPointTelephoneValue"],
    fieldType: FieldType.Phone,
    label: "Mortgagee Phone Number"
  },
  MortgageeFullName: {
    fieldKey: ["MortgageeFullName"],
    fieldType: FieldType.String,
    label: "Mortgagee"
  },
  NetFundAmount: {
    fieldKey: ["NetFundAmount"],
    fieldType: FieldType.Money,
    label: "Net Fund ($)"
  },
  NetFundRatePercent: {
    fieldKey: ["NetFundRatePercent"],
    fieldType: FieldType.Percentage,
    label: "Net Fund/As-Is (%)"
  },
  NetWireAmount: {
    fieldType: FieldType.Money,
    label: "Net Wire Amount",
    fieldKey: ["NetWireAmount"]
  },
  OriginalPurchaseDate: {
    fieldKey: ["OriginalPurchaseDate"],
    fieldType: FieldType.Date,
    label: "Original Purchase Date"
  },
  OriginalPurchasePriceAmount: {
    fieldKey: ["OriginalPurchasePriceAmount"],
    fieldType: FieldType.Money,
    label: "Original Purchase Price"
  },
  OutstandingLoanPayoffAmount: {
    fieldKey: ["OutstandingLoanPayoffAmount"],
    fieldType: FieldType.Money,
    label: "Outstanding Mortgage Payoff"
  },
  ParcelNumber: {
    fieldKey: ["ParcelNumber"],
    fieldType: FieldType.String,
    label: "Assessors Parcel Number (APN)"
  },
  PayoffExpirationDate: {
    fieldKey: ["PayoffExpirationDate"],
    fieldType: FieldType.Date,
    label: "Payoff Expiration Date"
  },
  PropertyDetailComments: {
    fieldKey: ["PropertyDetailComments"],
    fieldType: FieldType.RichText,
    label: "Property Detail Comments"
  },
  PropertyInspection: {
    ConstructionAnalystFullName: {
      fieldKey: ["PropertyInspection", "ConstructionAnalystFullName"],
      fieldType: FieldType.String,
      label: "Construction Analyst"
    },
    InspectionDocumentsReceivedDate: {
      fieldKey: ["PropertyInspection", "InspectionDocumentsReceivedDate"],
      fieldType: FieldType.Date,
      label: "All Docs Received Date"
    },
    InspectionInitialDelayReasonStatusType: {
      fieldKey: [
        "PropertyInspection",
        "InspectionInitialDelayReasonStatusType"
      ],
      fieldType: FieldType.MultiSelect,
      label: "Initial Inspection Delay Reasons",
      options: createOptionsFromEnum(InspectionInitialDelayReasonStatusType)
    },
    InspectionInitialRequestDate: {
      fieldKey: ["PropertyInspection", "InspectionInitialRequestDate"],
      fieldType: FieldType.Date,
      label: "Initial Request Date"
    },
    InspectionOrderProcessedDate: {
      fieldKey: ["PropertyInspection", "InspectionOrderProcessedDate"],
      fieldType: FieldType.Date,
      label: "Inspection Processed Date"
    },
    InspectionOrderReceivedDate: {
      fieldKey: ["PropertyInspection", "InspectionOrderReceivedDate"],
      fieldType: FieldType.Date,
      label: "Inspection Received Date"
    },
    InspectionOrderType: {
      fieldKey: ["PropertyInspection", "InspectionOrderType"],
      fieldType: FieldType.SingleSelect,
      label: "Inspection Order Type",
      options: createOptionsFromEnum(InspectionOrderType)
    },
    InspectionReportDueDate: {
      fieldKey: ["PropertyInspection", "InspectionReportDueDate"],
      fieldType: FieldType.Date,
      label: "Report Due Date"
    },
    InspectionRequestOrderedDate: {
      fieldKey: ["PropertyInspection", "InspectionRequestOrderedDate"],
      fieldType: FieldType.Date,
      label: "Date Order Requested PruVan"
    },
    InspectorFullName: {
      fieldKey: ["PropertyInspection", "InspectorFullName"],
      fieldType: FieldType.String,
      label: "Inspector Name"
    }
  },
  PropertyInsurance: {
    DeductibleAmount: {
      fieldKey: ["PropertyInsurance", "DeductibleAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Deductible"
    },
    DwellingCoverageAmount: {
      fieldKey: ["PropertyInsurance", "DwellingCoverageAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Dwelling Coverage Amount"
    },
    InsurancePremiumAmount: {
      fieldKey: ["PropertyInsurance", "InsurancePremiumAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Premium Amount"
    },
    InsurancePremiumOutstandingAmount: {
      fieldKey: ["PropertyInsurance", "InsurancePremiumOutstandingAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Premium Amount Outstanding"
    },
    InsurancePremiumPaymentType: {
      fieldKey: ["PropertyInsurance", "InsurancePremiumPaymentType"],
      fieldType: FieldType.SingleSelect,
      label: "Property Insurance Premium Payment",
      options: createOptionsFromEnum(PropertyInsurancePremiumPaymentType)
    },
    LiabilityCoverageAmount: {
      fieldKey: ["PropertyInsurance", "LiabilityCoverageAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Liability Coverage Amount"
    },
    NextPaymentDueDate: {
      fieldKey: ["PropertyInsurance", "NextPaymentDueDate"],
      fieldType: FieldType.Date,
      label: "Property Insurance Next Payment Due Date"
    },
    PolicyExpirationDate: {
      fieldKey: ["PropertyInsurance", "PolicyExpirationDate"],
      fieldType: FieldType.Date,
      label: "Property Insurance Expiration Date"
    },
    PropertyInsurancePolicyIdentifier: {
      fieldKey: ["PropertyInsurance", "PropertyInsurancePolicyIdentifier"],
      fieldType: FieldType.String,
      label: "Property Insurance Policy Number"
    },
    RentalLossCoverageAmount: {
      fieldKey: ["PropertyInsurance", "RentalLossCoverageAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Loss of Rents Coverage Amount"
    },
    ReplacementCoverageIndicator: {
      fieldKey: ["PropertyInsurance", "ReplacementCoverageIndicator"],
      fieldType: FieldType.Boolean,
      label: "Property Insurance Replacement Coverage",
      options: booleanOptions
    },
    BuilderRiskInsurance: {
      fieldKey: ["PropertyInsurance", "BuilderRiskInsurance"],
      fieldType: FieldType.Money,
      label: "Builder's Risk Insurance"
    }
  },
  PropertyInsuranceAgency: {
    InsuranceAgentFullName: {
      fieldKey: ["PropertyInsuranceAgency", "InsuranceAgentFullName"],
      fieldType: FieldType.String,
      label: "Property Insurance Agent"
    },
    ContactPointEmailValue: {
      fieldKey: ["PropertyInsuranceAgency", "ContactPointEmailValue"],
      fieldType: FieldType.Email,
      label: "Property Insurance Agency Email Address"
    },
    ContactPointTelephoneValue: {
      fieldKey: ["PropertyInsuranceAgency", "ContactPointTelephoneValue"],
      fieldType: FieldType.Phone,
      label: "Property Insurance Agency Phone Number"
    },
    FullName: {
      fieldKey: ["PropertyInsuranceAgency", "FullName"],
      fieldType: FieldType.String,
      label: "Property Insurance Agency"
    },

    AddressLineText: {
      fieldKey: ["PropertyInsuranceAgency", "AddressLineText"],
      fieldType: FieldType.String,
      label: "Property Insurance Agency Address Line 1"
    },
    AddressUnitIdentifier: {
      fieldKey: ["PropertyInsuranceAgency", "AddressUnitIdentifier"],
      fieldType: FieldType.String,
      label: "Property Insurance Agency Address Line 2"
    },
    CityName: {
      fieldKey: ["PropertyInsuranceAgency", "CityName"],
      fieldType: FieldType.String,
      label: "Property Insurance Agency City"
    },
    CountyName: {
      fieldKey: ["PropertyInsuranceAgency", "CountyName"],
      fieldType: FieldType.String,
      label: "Property Insurance Agency County Name"
    },
    PostalCode: {
      fieldKey: ["PropertyInsuranceAgency", "PostalCode"],
      fieldType: FieldType.String,
      label: "Property Insurance Agency Zip Code"
    },
    StateCode: {
      fieldKey: ["PropertyInsuranceAgency", "StateCode"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromEnum(StateCode),
      label: "Property Insurance Agency State Code"
    },
    StateName: {
      fieldKey: ["PropertyInsuranceAgency", "StateName"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromEnum(StateName),
      label: "Property Insurance Agency State Name"
    }
  },
  PropertyLoanPurpose: {
    fieldKey: ["PropertyLoanPurpose"],
    fieldType: FieldType.SingleSelect,
    label: "Loan Purpose",
    options: createOptionsFromEnum(PropertyLoanPurpose)
  },
  PropertyRightsOwnershipType: {
    fieldKey: ["PropertyRightsOwnershipType"],
    fieldType: FieldType.SingleSelect,
    label: "Ownership (Property Rights)",
    options: createOptionsFromEnum(PropertyRightsOwnershipType)
  },
  PropertyType: {
    fieldKey: ["PropertyType"],
    fieldType: FieldType.SingleSelect,
    label: "Property Type",
    options: createOptionsFromEnum(PropertyType)
  },
  PSAAddendumIndicator: {
    fieldKey: ["PSAAddendumIndicator"],
    fieldType: FieldType.Boolean,
    label: "PSA Addendum",
    options: booleanOptions
  },
  PSAAssignmentContractIndicator: {
    fieldKey: ["PSAAssignmentContractIndicator"],
    fieldType: FieldType.Boolean,
    label: "PSA Assignment Contract",
    options: booleanOptions
  },
  AssignmentFeeAmount: {
    fieldKey: ["AssignmentFeeAmount"],
    fieldType: FieldType.Money,
    label: "Assignment Fee Amount"
  },
  PSABuyerBorrowerMismatchIndicator: {
    fieldKey: ["PSABuyerBorrowerMismatchIndicator"],
    fieldType: FieldType.Boolean,
    label: "Buyer and Borrower Mismatch?",
    options: booleanOptions
  },
  PSAExtensionFormIndicator: {
    fieldKey: ["PSAExtensionFormIndicator"],
    fieldType: FieldType.Boolean,
    label: "Extension Form",
    options: booleanOptions
  },
  PSAExtensionRequestedIndicator: {
    fieldKey: ["PSAExtensionRequestedIndicator"],
    fieldType: FieldType.Boolean,
    label: "Extension Requested?",
    options: booleanOptions
  },
  PSASellerOwnerRecordMismatchIndicator: {
    fieldKey: ["PSASellerOwnerRecordMismatchIndicator"],
    fieldType: FieldType.Boolean,
    label: "Seller and Owner of record mismatch?",
    options: booleanOptions
  },
  PSASignedIndicator: {
    fieldKey: ["PSASignedIndicator"],
    fieldType: FieldType.Boolean,
    label: "PSA Signed?",
    options: booleanOptions
  },
  PSAExpirationDate: {
    fieldKey: ["PSAExpirationDate"],
    fieldType: FieldType.Date,
    label: "PSA Expiration Date"
  },
  PurchasePriceAmount: {
    fieldKey: ["PurchasePriceAmount"],
    fieldType: FieldType.Money,
    label: "Purchase Price"
  },
  RenovationCostAmount: {
    fieldKey: ["RenovationCostAmount"],
    fieldType: FieldType.Money,
    label: "Renovation Budget"
  },
  RentReportConfidenceScorePercent: {
    fieldKey: ["RentReportConfidenceScorePercent"],
    fieldType: FieldType.Percentage,
    label: "Rent Report Confidence Score"
  },
  SpecialAssessmentAnnualTaxAmount: {
    fieldKey: ["SpecialAssessmentAnnualTaxAmount"],
    fieldType: FieldType.Money,
    label: "Special Assessment Annual Taxes"
  },
  SpecialAssessmentTaxBillingFrequencyType: {
    fieldKey: ["SpecialAssessmentTaxBillingFrequencyType"],
    fieldType: FieldType.SingleSelect,
    label: "Special Assessment Tax Billing Frequency",
    options: createOptionsFromEnum(BillingFrequencyType)
  },
  SpecialAssessmentTaxDueDate: {
    fieldKey: ["SpecialAssessmentTaxDueDate"],
    fieldType: FieldType.Date,
    label: "Special Assessment Tax Due Date"
  },
  SpecialAssessmentTaxingAuthority: {
    fieldKey: ["SpecialAssessmentTaxingAuthority"],
    fieldType: FieldType.String,
    label: "Special Assessment Taxing Authority"
  },
  TaxDueDate: {
    fieldKey: ["TaxDueDate"],
    fieldType: FieldType.Date,
    label: "Tax Due Date"
  },
  TotalRentalIncomeAmount: {
    fieldKey: ["TotalRentalIncomeAmount"],
    fieldType: FieldType.Money,
    label: "Total Rental Income"
  },
  BorrowerRenovationCostsAfterPurchaseAmount: {
    fieldType: FieldType.Money,
    label: "Borrower Renovation Costs After Purchase",
    fieldKey: ["BorrowerRenovationCostsAfterPurchaseAmount"]
  },
  InternalL1CRefinanceIndicator: {
    fieldKey: ["InternalL1CRefinanceIndicator"],
    fieldType: FieldType.Boolean,
    label: "Internal L1C Refinance?",
    options: booleanOptions
  },
  LeaseStrategy: {
    fieldKey: ["LeaseStrategy"],
    fieldType: FieldType.SingleSelect,
    label: "Lease Strategy",
    options: createOptionsFromEnum(LeaseStrategyType)
  },
  RentalLTVPercent: {
    fieldKey: ["RentalLTVPercent"],
    fieldType: FieldType.Percentage,
    label: "Rental - Loan to Value (%)"
  },
  ConstructionBudgetAssessment: {
    fieldKey: ["ConstructionBudgetAssessment"],
    fieldType: FieldType.SingleSelect,
    label: "Construction Budget Assessment",
    options: createOptionsFromEnum(ConstructionBudgetAssessmentType)
  },
  Integrations: {
    [IntegrationType.FloodCertificate]: {
      [FloodCertificateVendorType.ServiceLink]: {
        UsersEmailAddress: {
          fieldKey: [
            "Integrations",
            IntegrationType.FloodCertificate,
            FloodCertificateVendorType.ServiceLink,
            "UsersEmailAddress"
          ],
          fieldType: FieldType.String,
          label: "User(s) Email Address"
        }
      }
    }
  },
  defaultInterestPaymentsOrFeesNotedIndicator: {
    fieldKey: ["defaultInterestPaymentsOrFeesNotedIndicator"],
    fieldType: FieldType.Boolean,
    label: "Default Interest Payments or Fees Noted?",
    options: booleanOptions
  }
};

export const propertySpecFields = createSpecWithFieldMeta({
  spec: propertyBaseSpecFields
});
