import { ElphiEntityType } from "@elphi/types";
import { merge } from "lodash";
import { SectionsLoader } from "../types/dynamicSchema.types";
import { injectSpecToBuilder } from "../utils/formBuilder.utils";
import {
  assetSectionBuilders,
  dealPartyRelationSectionBuilders,
  dealSectionBuilders,
  dealUnderwritingSectionBuilders,
  entityPartySectionBuilders,
  individualPartySectionBuilders,
  partyRelationSectionBuilders,
  partySectionBuilders,
  propertySectionBuilders,
  statementSectionBuilders
} from "./sections";
import { insurancePolicyBuilders } from "./sections/insurancePolicy.sections";
import { taskSectionsBuilders } from "./sections/task.sections";
import { BaseSections, BaseSpecs } from "./types";

export const baseSectionsLoader: SectionsLoader = (r: {
  specs: Partial<BaseSpecs>;
}) => ({
  loadSections: () => {
    const { specs } = r;
    if (!specs) {
      return {};
    }

    const sections = {
      party: buildPartySections(specs),
      asset: buildAssetSections(specs),
      deal: buildDealSections(specs),
      dealPartyRelation: buildDealPartyRelationSections(specs),
      partyRelation: buildPartyRelationSections(specs),
      property: buildPropertySections(specs),
      statement: buildStatementSections(specs),
      task: buildTaskSections(specs),
      insurancePolicy: buildInsurancePolicySections(specs)
    };

    return sections;
  }
});

const buildPartySections = (specs: Partial<BaseSpecs>) =>
  injectSpecToBuilder<BaseSections["party"]>({
    builders: merge(
      entityPartySectionBuilders,
      individualPartySectionBuilders,
      partySectionBuilders
    ),
    spec: specs.party?.entitySpecs
  });

const buildAssetSections = (specs: Partial<BaseSpecs>) =>
  injectSpecToBuilder<BaseSections["asset"]>({
    builders: assetSectionBuilders,
    spec: specs.asset?.entitySpecs
  });

const buildDealSections = (specs: Partial<BaseSpecs>) =>
  injectSpecToBuilder<BaseSections["deal"]>({
    builders: merge(dealUnderwritingSectionBuilders, dealSectionBuilders),
    spec: specs.deal?.entitySpecs
  });

const buildDealPartyRelationSections = (specs: Partial<BaseSpecs>) =>
  injectSpecToBuilder<BaseSections["dealPartyRelation"]>({
    builders: dealPartyRelationSectionBuilders,
    spec: specs[ElphiEntityType.dealParty]?.specsParts
  });

const buildPartyRelationSections = (specs: Partial<BaseSpecs>) =>
  injectSpecToBuilder<BaseSections["partyRelation"]>({
    builders: partyRelationSectionBuilders,
    spec: specs[ElphiEntityType.partyRelation]?.specsParts
  });

const buildPropertySections = (specs: Partial<BaseSpecs>) =>
  injectSpecToBuilder<BaseSections["property"]>({
    builders: propertySectionBuilders,
    spec: specs.property?.entitySpecs
  });

const buildStatementSections = (specs: Partial<BaseSpecs>) =>
  injectSpecToBuilder<BaseSections["statement"]>({
    builders: statementSectionBuilders,
    spec: specs.statement?.entitySpecs
  });

const buildTaskSections = (specs: Partial<BaseSpecs>) =>
  injectSpecToBuilder<BaseSections["task"]>({
    builders: taskSectionsBuilders,
    spec: specs.task?.entitySpecs
  });

const buildInsurancePolicySections = (specs: Partial<BaseSpecs>) =>
  injectSpecToBuilder<BaseSections["insurancePolicy"]>({
    builders: insurancePolicyBuilders,
    spec: specs[ElphiEntityType.insurancePolicy]?.entitySpecs
  });
