import { TaskWorkflow } from "./workflow.types";

export interface TaskTypes {
  [x: string]: VersionTask;
}

export enum VersionTaskColumns {
  AutomaticGeneration = "Automatic Generation",
  EditableBy = "Editable By",
  TaskRelation = "Task Relation",
  Hash = "Hash",
  KeyClosingTask = "KCT",
  AssignableBy = "Assignable By",
  TaskName = "Task Name",
  TaskType = "Task Type",
  ChecklistOf = "Checklist Of",
  TaskId = "id",
  Workflow = "Workflow"
}

export type VersionTask = {
  [VersionTaskColumns.AutomaticGeneration]: string;
  [VersionTaskColumns.EditableBy]: string[];
  [VersionTaskColumns.TaskRelation]: string;
  [VersionTaskColumns.Hash]: string;
  [VersionTaskColumns.KeyClosingTask]: string;
  [VersionTaskColumns.AssignableBy]: string[];
  [VersionTaskColumns.TaskName]: string;
  [VersionTaskColumns.TaskType]: string;
  [VersionTaskColumns.ChecklistOf]: string[];
  [VersionTaskColumns.Workflow]: TaskWorkflow;
  [VersionTaskColumns.TaskId]: string;
};
export type VersionCondition = {
  [x: string]: {
    Hash: string;
    Tasks: string[];
    Group: Group[];
    id: string;
    operator?: string;
  };
};

export type Group = {
  Collection: string;
  Label: string;
  ExpectedValue: string[];
  ComparatorType: string;
  Operator: string;
  FieldPath: string;
};
