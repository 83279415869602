import { InfoIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import { BusinessFeatureFlag, FeatureFlag } from "@elphi/utils";
import {
  IconDefinition,
  faColumns,
  faFlag,
  faUserCog
} from "@fortawesome/free-solid-svg-icons";
import { isArray } from "lodash";
import { lazy } from "react";
import { Route, Routes } from "react-router";
import { ApplicationPageContainer } from "../components/application/ApplicationPage";
import { CheckListPage } from "../components/check-list/CheckListPage";
import DocumentPackageOrderPage from "../components/document-package-order/DocumentPackageOrderPage";
import PortfolioPageContainer from "../components/portfolio/PortfolioPageContainer";
import { WorksheetTab } from "../features/worksheet/worksheet.types";
import { PLATFORM_URL, useOrgHooks } from "../hooks/org.hooks";
import { useStatsigGate } from "../hooks/statsig.hooks";
import { RouteData } from "../shared";
import { GuardRoute } from "./GuardRoute";
import PathVariables, { NestedMortgageWorksheet } from "./pathVariables";

const WorksheetLayoutContainer = lazy(
  () => import("../features/worksheet/WorksheetLayoutContainer")
);
const DealPage = lazy(() => import("../components/deal/DealPage"));
const PartyPage = lazy(() => import("../components/party/PartyPage"));
const PropertyPage = lazy(() => import("../components/property/PropertyPage"));
const TaskManagementPage = lazy(
  () => import("../components/task-management/taskManagementPage")
);
const AdminPage = lazy(() => import("../components/admin/home/AdminHomePage"));
const DocumentManagementPage = lazy(
  () => import("../components/document-management/DocumentManagementPage")
);
const PlatformPage = lazy(() => import("../components/platform/PlatformPage"));
const SelectOrganizationList = lazy(
  () => import("../components/platform/tabs/organization/SelectOrganization")
);
const RolodexManagementPage = lazy(
  () => import("../components/rolodex/configuration/RolodexConfigurationPage")
);
const OrganizationManagementPage = lazy(
  () =>
    import("../components/organization-management/OrganizationManagementPage")
);
const ServiceProviderPage = lazy(
  () => import("../components/rolodex/pipeline/ServiceProviderPage")
);
const NotificationManagementPage = lazy(
  () =>
    import("../components/notification/management/NotificationManagementPage")
);

export type RouteSpec = {
  redirect?: boolean;
  path: string;
  name: string;
  mini: IconDefinition;
  component: JSX.Element;
  invisible: boolean;
  index?: boolean;
  nested?: RouteSpec[];
  withGuard?: boolean;
  featureFlag?: FeatureFlag;
};

export type ElphiRouteConfig = {
  name: string;
  roles: string[];
  routes: RouteSpec[];
};
export type ElphiAppRouteConfig = ElphiRouteConfig[];
export const routeConfig: ElphiAppRouteConfig = [
  {
    name: "tenant",
    roles: [],
    routes: [
      {
        redirect: true,
        path: PathVariables.LoanPipeline,
        name: "Loan Pipeline",
        mini: faColumns,
        component: <DealPage />,
        invisible: false,
        withGuard: true
      },
      {
        path: PathVariables.PartiesPipeline,
        name: "Parties Pipeline",
        mini: faFlag,
        component: <PartyPage />,
        invisible: false,
        withGuard: true
      },
      {
        path: PathVariables.PropertyPipeline,
        name: "Properties Pipeline",
        mini: faFlag,
        component: <PropertyPage />,
        invisible: false,
        withGuard: true
      },
      {
        path: PathVariables.ServiceProvider,
        name: "Service Providers Pipeline",
        mini: faFlag,
        component: <ServiceProviderPage />,
        invisible: false,
        withGuard: true
      },
      {
        path: PathVariables.MortgageWorksheet,
        name: "Mortgage Worksheet",
        mini: faColumns,
        component: <WorksheetLayoutContainer />,
        invisible: false,
        nested: [
          {
            path: "",
            index: true,
            name: WorksheetTab.Application,
            mini: faColumns,
            component: <ApplicationPageContainer />,
            invisible: false
          },
          {
            path: NestedMortgageWorksheet.Application,
            name: WorksheetTab.Application,
            mini: faColumns,
            component: <ApplicationPageContainer />,
            invisible: false
          },
          {
            path: NestedMortgageWorksheet.Checklist,
            name: WorksheetTab.Checklist,
            mini: faColumns,
            component: <CheckListPage />,
            invisible: false
          },
          {
            path: NestedMortgageWorksheet.Portfolio,
            name: WorksheetTab.Portfolio,
            mini: faColumns,
            component: <PortfolioPageContainer />,
            invisible: false
          },
          {
            path: NestedMortgageWorksheet.Closing,
            name: WorksheetTab.Closing,
            mini: faColumns,
            component: <DocumentPackageOrderPage />,
            invisible: false
          }
        ]
      },
      {
        path: PathVariables.SelectOrganization,
        name: "Select Organization",
        mini: faFlag,
        component: <SelectOrganizationList op="current-tab" />,
        invisible: false
      }
    ]
  },
  {
    name: "platform",
    roles: ["platform_admin"],
    routes: [
      {
        path: PathVariables.PlatformManagement,
        name: "Platform Management",
        mini: faFlag,
        component: <PlatformPage />,
        invisible: false,
        featureFlag: BusinessFeatureFlag.Platform_Management
      }
    ]
  },
  {
    name: "admin",
    roles: ["admin", "platform_admin"],
    routes: [
      {
        path: PathVariables.UserManagement,
        name: "User Management",
        mini: faUserCog,
        component: <AdminPage />,
        invisible: false
      }
    ]
  },
  {
    name: "orgAdmin",
    roles: ["organization_admin", "admin", "platform_admin"],
    routes: [
      {
        path: PathVariables.TaskManagement,
        name: "Task Management",
        mini: faFlag,
        component: <TaskManagementPage />,
        invisible: false
      },
      {
        path: PathVariables.DocumentManagement,
        name: "Document Management",
        mini: faFlag,
        component: <DocumentManagementPage />,
        invisible: false
      },
      {
        path: PathVariables.NotificationManagement,
        name: "Notification Management",
        mini: faFlag,
        component: <NotificationManagementPage />,
        invisible: false
      },
      {
        name: "Service Providers Management",
        path: PathVariables.RolodexManagement,
        mini: faFlag,
        component: <RolodexManagementPage />,
        invisible: false
      },
      {
        path: PathVariables.OrganizationManagement,
        name: "Organization Management",
        mini: faFlag,
        component: <OrganizationManagementPage />,
        invisible: false
      }
    ]
  }
];

export const NoPermissionComponent = (props: { requiredRoles: string[] }) => {
  return (
    <Flex
      direction="column"
      position="absolute"
      right="50%"
      top={"50%"}
      transform="translate(50%, -50%)"
      p={6}
    >
      <Box
        maxW="xl"
        p={8}
        bg="white"
        borderRadius="md"
        borderWidth="1px"
        borderColor={"gray.200"}
        boxShadow="xl"
        textAlign="center"
      >
        <Flex direction="column" align="center" mb={6}>
          <Text
            fontSize="3xl"
            lineHeight={8}
            overflowWrap="break-word"
            fontWeight="bold"
          >
            It seems that you are missing some roles
          </Text>
        </Flex>
        <Text fontSize="xl" mb={6}>
          The following roles are allowed:
        </Text>
        <VStack spacing={4} align="start" mb={6}>
          {props.requiredRoles.map((role) => (
            <Flex justifyContent="center" alignItems="center" key={role}>
              <InfoIcon boxSize={4} color="blue.500" mr={3} />
              <Text fontSize="xl" fontWeight="bold">
                {role}
              </Text>
            </Flex>
          ))}
        </VStack>
        <Text mt={4} fontSize="lg" color="gray.600">
          Please contact your administrator if you need access.
        </Text>
      </Box>
    </Flex>
  );
};

const noPermission = (
  routes: RouteSpec[],
  requiredRoles: string[]
): RouteSpec[] => {
  return routes.map((r, key) => {
    return {
      ...r,
      component: (
        <NoPermissionComponent key={key} requiredRoles={requiredRoles} />
      )
    };
  });
};

export const ElphiRoutes = (props: { roles?: string[] | null }) => {
  const { roles } = props;
  const { isPlatformOrg } = useOrgHooks();
  const routes = routeConfig
    .map((rc) => {
      const hasRoles = rc.roles.some((role) => roles?.includes(role));
      if (rc.roles.length && !hasRoles) {
        return noPermission(rc.routes, rc.roles);
      }
      return rc.routes;
    })
    .flat()
    .filter((route) => {
      const { featureFlag } = route;
      if (!featureFlag) {
        return true;
      }
      return useStatsigGate(featureFlag).value;
    });

  return (
    <Routes>
      {routes?.map((prop: RouteData, key: number) => {
        if (prop.collapse) {
          return prop.views?.map((prop: RouteData, key: number) => (
            <Route path={prop.path} element={<>{prop.component}</>} key={key} />
          ));
        }

        return isArray(prop?.nested) ? (
          <Route path={prop.path} element={<>{prop.component}</>} key={key}>
            {prop?.nested.map((nestedProp: RouteData, nestedKey: number) => (
              <Route
                index={!!nestedProp?.index}
                path={nestedProp.path}
                element={<>{nestedProp.component}</>}
                key={`n${nestedKey}`}
              />
            ))}
          </Route>
        ) : (
          <Route
            path={prop.path}
            element={
              <>
                {prop.withGuard ? (
                  <GuardRoute
                    isAllowed={!isPlatformOrg}
                    redirectPath={PLATFORM_URL}
                  >
                    {prop.component}
                  </GuardRoute>
                ) : (
                  prop.component
                )}
              </>
            }
            key={key}
          />
        );
      })}
    </Routes>
  );
};

//should be deprectated near future
export default (roles?: string[] | null) => {
  // const routes: RouteSpec[] = [];
  const routes = routeConfig
    .map((rc) => {
      const hasRoles = rc.roles.some((role) => roles?.includes(role));
      if (rc.roles.length && !hasRoles) {
        return noPermission(rc.routes, rc.roles);
      }
      return rc.routes;
    })
    .flat();

  return routes;
};
