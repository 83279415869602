import { baseSectionsLoader } from "../base/baseSectionsLoader";
import { limaSectionsLoader } from "../lima/limaSectionsLoader";
import { DomainOwner } from "../types/dynamicSchema.types";
import { getDomainOwner } from "./formCommon.utils";
import { fieldSpecsMap } from "./formsSpec.utils";

export const getSectionsLoader = (owner?: DomainOwner) => {
  const specsOwner = owner || getDomainOwner();
  switch (specsOwner) {
    case DomainOwner.Lima:
      return limaSectionsLoader({ specs: fieldSpecsMap[DomainOwner.Lima] });
    default:
      return baseSectionsLoader({ specs: fieldSpecsMap[DomainOwner.Elphi] });
  }
};
