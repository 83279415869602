import { getPropertyInsurancePolicyTemplate } from "@elphi/types";
import { keys, pick } from "lodash";
import { useEffect, useState } from "react";
import { EMPTY } from "../../../constants/common";
import { useInsurancePolicyHooks } from "../../../hooks/insurance-policy/insurancePolicy.hooks";
import { usePropertyInsurancePolicyHooks } from "../../../hooks/insurance-policy/propertyInsurancePolicy.hooks";
import { useRolodexBranchRepRelation } from "../../../hooks/rolodexBranchRepRelation.hooks";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import {
  getBranchTemplate,
  getCompanyTemplate,
  getRepTemplate
} from "../../rolodex/service-provider/wizard/steps/provider-step-content/providersFormStep.utils";
import { WizardContainer } from "../../wizard-container/WizardContainer";
import { PropertyInsurancePolicyModalProps } from "./PropertyInsurancePolicyModal";
import { usePropertyInsuranceProvidersModalHooks } from "./propertyInsuranceProvidersModal.hooks";
import { CoverageDataStep } from "./wizard/steps/coverages-data-step/CoverageDataStep";
import { DomainConfigurationStep } from "./wizard/steps/domain-configuration-step/DomainConfigurationStep";
import { PolicyStep } from "./wizard/steps/policy-step/PolicyStep";
import { PropertiesSelectStep } from "./wizard/steps/properties-select-step/PropertiesSelectStep";
import { ProvidersStep } from "./wizard/steps/providers-step/ProvidersStep";
import { PropertyInsurancePolicyState } from "./wizard/steps/types/insurancePolicySteps.types";

export type PropertyInsurancePolicyModalContentProps = Omit<
  PropertyInsurancePolicyModalProps,
  "isOpen"
>;

const propertyInsurancePolicyTemplate = getPropertyInsurancePolicyTemplate();
export const PropertyInsurancePolicyModalContent = (
  props: PropertyInsurancePolicyModalContentProps
) => {
  const { onClose, showDomainStep, dealId, branchRepId } = props;
  const { upsertHandler, isSubmitting } = usePropertyInsurancePolicyHooks();
  const { selectPolicy } = useInsurancePolicyHooks();
  const { branchRepState } = useRolodexBranchRepRelation();

  const {
    onChange: insurancePolicyOnChange,
    state: insurancePolicyState,
    setState: setInsurancePolicy
  } = useFormBuilderStateHandler<PropertyInsurancePolicyState>({
    initialState: {}
  });
  const {
    branchFormState,
    companyFormState,
    repFormState,
    domainConfigurationId,
    isProviderFormValid,
    setIsProviderFormValid,
    resetProviderStates,
    resetDomainConfiguration
  } = usePropertyInsuranceProvidersModalHooks();

  const [isPolicyFormValid, setIsPolicyFormValid] = useState<boolean>(false);

  useEffect(() => {
    return () => resetStates();
  }, []);

  useEffect(() => {
    const branchRepRelationId = branchRepId || insurancePolicyState.branchRepId;
    if (!branchRepRelationId) {
      resetProviderStates();
      return;
    }
    const [branchId, repId] = branchRepRelationId?.split("_");
    const companyId =
      branchRepState.entities[branchRepRelationId]?.companyId ||
      companyFormState.state.id ||
      insurancePolicyState.policyProviderInfo?.id;
    companyFormState.setSelectedProviderId(companyId || EMPTY);
    branchFormState.setSelectedProviderId(branchId || EMPTY);
    repFormState.setSelectedProviderId(repId || EMPTY);

    if (companyId && branchId && repId) {
      setIsProviderFormValid(true);
    }
  }, [insurancePolicyState.branchRepId, branchRepId]);

  const handleOnSubmit = async () => {
    const response = await upsertHandler({
      dealId,
      insurancePolicy: pick(
        insurancePolicyState,
        keys(propertyInsurancePolicyTemplate)
      ),
      serviceProvider: {
        domainConfigurationId,
        company: getCompanyTemplate(companyFormState.state),
        branch: getBranchTemplate(branchFormState.state),
        rep: getRepTemplate(repFormState.state)
      }
    });
    !!response && onClose();
  };

  const resetPolicyState = () => {
    setInsurancePolicy({});
    selectPolicy({ id: undefined });
  };

  const resetPolicyAndProviderStates = () => {
    resetPolicyState();
    resetProviderStates();
  };

  const resetStates = () => {
    resetPolicyAndProviderStates();
    resetDomainConfiguration();
  };

  const domainStep = {
    label: "Domain",
    isValid: !!domainConfigurationId,
    content: (
      <DomainConfigurationStep
        currentConfigurationId={companyFormState.state.domainConfigurationId}
        onChange={companyFormState.onChange}
        onSelect={resetPolicyAndProviderStates}
      />
    )
  };

  const policyStep = {
    label: "Policy",
    isValid: isPolicyFormValid,
    content: (
      <PolicyStep
        onChange={insurancePolicyOnChange}
        state={insurancePolicyState}
        setState={setInsurancePolicy}
        domainConfigurationId={domainConfigurationId}
        setStepValidity={(isValid) => setIsPolicyFormValid(isValid)}
        filter={(insurancePolicy) => {
          return (
            (!branchRepId || insurancePolicy?.branchRepId === branchRepId) &&
            insurancePolicy?.domainConfigurationId === domainConfigurationId
          );
        }}
      />
    )
  };

  const providersStep = {
    label: "Providers",
    isValid: isProviderFormValid,
    content: (
      <ProvidersStep
        companyFormState={companyFormState}
        branchFormState={branchFormState}
        repFormState={repFormState}
        branchRepRelationId={branchRepId || insurancePolicyState.branchRepId}
        setStepValidity={(isValid) => setIsProviderFormValid(isValid)}
      />
    )
  };

  const propertiesStep = {
    label: "Properties",
    isValid: true,
    content: (
      <PropertiesSelectStep
        onChange={insurancePolicyOnChange}
        state={insurancePolicyState}
        dealId={dealId}
      />
    )
  };

  const CoverageStep = {
    label: "Coverage",
    isValid: true,
    content: (
      <CoverageDataStep
        onChange={insurancePolicyOnChange}
        state={insurancePolicyState}
        dealId={dealId}
      />
    )
  };

  const steps = [policyStep, providersStep, propertiesStep, CoverageStep];
  if (showDomainStep) {
    steps.unshift(domainStep);
  }

  return (
    <WizardContainer
      height={500}
      steps={steps}
      onCancel={onClose}
      onSubmit={handleOnSubmit}
      isLoading={isSubmitting}
    />
  );
};
