import { BaseEntity } from "@elphi/types";
import { FeatureFlag } from "@elphi/utils";
import {
  OnChangeInput,
  Section
} from "../../../components/form-builder/FormBuilder";

import { BaseSchema } from "../base/types/baseSchema.types";
import { IntegrationSections } from "../integrations/types/integrations.types";
import { LimaSchema } from "../lima/types";

export enum DomainOwner {
  Lima = "lima",
  Mofin = "mofin",
  Elphi = "elphi"
}

export type DynamicSections = Partial<
  BaseSchema["sections"] & LimaSchema["sections"]
>;
export type DynamicSchema = BaseSchema | LimaSchema;

export type UserSchema<
  TOwner extends DomainOwner,
  TSections extends DynamicSchema["sections"],
  TSpecs extends DynamicSchema["specs"]
> = {
  schemaOwner: TOwner;
  sections: TSections;
  specs: TSpecs;
};

export type SectionsWithIntegrations<T> = Partial<IntegrationSections> & T;

export type SpecsEntry<
  TEntitySpecs extends object,
  TSpecsParts extends object = {}
> = {
  entitySpecs: TEntitySpecs;
  specsParts?: TSpecsParts;
};

export type SectionsLoader = (r: {
  specs: Partial<DynamicSchema["specs"]>;
}) => {
  loadSections: () => DynamicSchema["sections"];
};

export type SectionGenerator<TModify extends object = {}> = (
  r: Omit<SectionGeneratorArgs, keyof TModify> & TModify
) => Section;

type SectionGeneratorArgs = {
  state: Partial<BaseEntity<object>>;
  onChange: (v: OnChangeInput) => void;
  options?: {
    header?: string;
    hideAttachedComponent?: boolean;
    isReadOnly?: boolean;
    elphiView?: "accordion" | "form";
    gates?: Partial<{ [key in FeatureFlag]: boolean }>;
    isDisabled?: boolean;
  };
};
