import {
  DealPartyRelation,
  DealPartyRelationType,
  DealToEntityRelation,
  DealToIndividualRelation,
  LoanPartyEntityRoleType,
  LoanPartyIndividualRoleType
} from "@elphi/types";

import { createOptionsFromEnum } from "../../../utils/formUtils";
import { FieldType } from "../../fieldFormat.types";
import { baseEntityFormFieldSpecs } from "../baseEntity.fields";
import { EntityFormFieldSpecs, SpecsBaseOmit } from "../fields.types";
import { createSpecWithFieldMeta } from "../utils/fieldMeta.utils";

export type DealToIndividualFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<DealToIndividualRelation>
>;
export type DealToEntityFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<DealToEntityRelation>
>;
type BaseDealPartyFieldSpecs = EntityFormFieldSpecs<
  Omit<SpecsBaseOmit<DealPartyRelation>, "relationRoleType">
>;

const baseDealPartyFieldSpecs: BaseDealPartyFieldSpecs = {
  ...baseEntityFormFieldSpecs,
  type: {
    fieldKey: ["type"],
    fieldType: FieldType.SingleSelect,
    options: createOptionsFromEnum(DealPartyRelationType),
    label: "Deal-Party Relation Type"
  },
  dealId: {
    fieldKey: ["dealId"],
    fieldType: FieldType.String,
    label: "Deal ID"
  },
  partyId: {
    fieldKey: ["partyId"],
    fieldType: FieldType.String,
    label: "Party ID"
  }
};

const dealToIndividualFieldBaseSpecs: DealToIndividualFieldSpecs = {
  ...baseDealPartyFieldSpecs,
  relationRoleType: {
    fieldKey: ["relationRoleType"],
    fieldType: FieldType.MultiSelect,
    options: createOptionsFromEnum(LoanPartyIndividualRoleType),
    label: "Deal-Party Role"
  }
};

const dealToEntityFieldBaseSpecs: DealToEntityFieldSpecs = {
  ...baseDealPartyFieldSpecs,
  relationRoleType: {
    fieldKey: ["relationRoleType"],
    fieldType: FieldType.MultiSelect,
    options: createOptionsFromEnum(LoanPartyEntityRoleType),
    label: "Deal-Party Role"
  }
};

export const dealToIndividualFieldSpecs = createSpecWithFieldMeta({
  spec: dealToIndividualFieldBaseSpecs
});

export const dealToEntityFieldSpecs = createSpecWithFieldMeta({
  spec: dealToEntityFieldBaseSpecs
});
