import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { InsurancePolicyCoverages, InsurancePolicyView } from "@elphi/types";
import { Card } from "../../../../common-components/card";
import { CardsContainer } from "../../../../common-components/card/CardsContainer";
import { NOT_AVAILABLE } from "../../../../constants/common";
import { UnplugIcon } from "../../../icons/UnplugIcon";
import { InsurancePolicyMinimizedView } from "../../card/InsurancePolicyMinimizedView";
import { DealInsurancePolicyMaximizedView } from "./InsuranceMaximizedView";

const PROPERTY_INSURANCE_CARD_HEIGHT = "230px";

type OperationActions = {
  onEdit: (view: InsurancePolicyView<InsurancePolicyCoverages>) => void;
  onRemove: (view: InsurancePolicyView<InsurancePolicyCoverages>) => void;
  onDelete: (view: InsurancePolicyView<InsurancePolicyCoverages>) => void;
};

type InsurancePropertyCardProps = OperationActions & {
  policyView: InsurancePolicyView<InsurancePolicyCoverages>;
};

const DealInsurancePolicyCard = (props: InsurancePropertyCardProps) => {
  const { policyView, onEdit, onRemove, onDelete } = props;
  return (
    <Card
      key={policyView.id}
      header={`Policy #${policyView.policy.number || NOT_AVAILABLE}`}
      cardOperations={[
        {
          label: "Edit insurance policy",
          action: () => onEdit(policyView),
          icon: <EditIcon h={4} w={4} color="gray.600" />
        },
        {
          label: "Remove Insurance Policy from Deal",
          action: () => onRemove(policyView),
          icon: <UnplugIcon h={4} w={4} color={"gray.600"} />
        },
        {
          label: "Delete Insurance Policy",
          action: () => onDelete(policyView),
          icon: <DeleteIcon h={4} w={4} color={"gray.600"} />
        }
      ]}
      minimizedView={
        <InsurancePolicyMinimizedView providers={policyView.providers} />
      }
      maximizedView={<DealInsurancePolicyMaximizedView view={policyView} />}
      collapseHeight={PROPERTY_INSURANCE_CARD_HEIGHT}
    />
  );
};

type DealInsurancePolicyCardsProps = OperationActions & {
  insurancePolicyViews: InsurancePolicyView<InsurancePolicyCoverages>[];
  isLoading: boolean;
};

export const DealInsurancePolicyCards = (
  props: DealInsurancePolicyCardsProps
) => {
  const { insurancePolicyViews } = props;
  return (
    <CardsContainer isLoading={props.isLoading}>
      <>
        {insurancePolicyViews?.map((policyView, index) => (
          <DealInsurancePolicyCard
            key={index}
            policyView={policyView}
            {...props}
          />
        ))}
      </>
    </CardsContainer>
  );
};
