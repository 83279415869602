// Need to use the React-specific entry point to allow generating React hooks
import {
  Deal,
  DocumentConfiguration,
  DocumentPackageOrder,
  DocumentRuleTemplate,
  LOSUser,
  Party,
  Property
} from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { RootState } from "../../store";
import serviceBuilder from "../builders/api.builder";
import { dealEntityAdapter } from "../deal/deal.adapter";
import { documentConfigurationEntityAdapter } from "../document-configuration/documentConfiguration.adapter";
import { documentRuleEntityAdapter } from "../document-rule/documentRule.adapter";
import { losUserEntityAdapter } from "../los-user/losUser.adapter";
import { partyEntityAdapter } from "../party/party.adapter";
import { propertyEntityAdapter } from "../property/property.adapter";
import { documentPackageOrderEntityAdapter as entityAdapter } from "./documentPackageOrder.adapter";

export const documentPackageOrderSelector =
  entityAdapter.getSelectors<RootState>((state) => state.documentPackageOrder);

// Define a service using a base URL and expected endpoints
export const baseDocumentPackageOrderApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "documentPackageOrderApi",
  tagTypes: ["DocumentPackageOrder", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "document-package-order"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter
  })
});

export const documentPackageOrderApi =
  baseDocumentPackageOrderApi.injectEndpoints({
    endpoints: (builder) => ({
      batchUpdate: builder.mutation<
        { batch: string[] },
        {
          orders: ({ id: string } & Partial<
            Omit<DocumentPackageOrder, "userId">
          >)[];
        }
      >({
        query: (r) => {
          return {
            url: `/update-batch`,
            method: "PUT",
            body: r
          };
        }
      }),
      getEntitiesData: builder.mutation<
        {
          losUsers: EntityState<LOSUser>;
          deals: EntityState<Deal>;
          parties: EntityState<Party>;
          properties: EntityState<Property>;
          documentConfigurations: EntityState<DocumentConfiguration>;
          documentRules: EntityState<DocumentRuleTemplate>;
        },
        { orders: ({ id: string } & Partial<DocumentPackageOrder>)[] }
      >({
        query: (r) => {
          return {
            url: `/get-entities-data`,
            method: "POST",
            body: r
          };
        },
        transformResponse: (response: {
          losUsers: LOSUser[];
          deals: Deal[];
          parties: Party[];
          properties: Property[];
          documentConfigurations: DocumentConfiguration[];
          documentRules: DocumentRuleTemplate[];
        }) => {
          const partyAdapter = partyEntityAdapter.addMany(
            partyEntityAdapter.getInitialState(),
            response.parties
          );
          const losUserAdapter = losUserEntityAdapter.addMany(
            losUserEntityAdapter.getInitialState(),
            response.losUsers
          );
          const dealAdapter = dealEntityAdapter.addMany(
            dealEntityAdapter.getInitialState(),
            response.deals
          );
          const propertyAdapter = propertyEntityAdapter.addMany(
            propertyEntityAdapter.getInitialState(),
            response.properties
          );
          const documentConfigurationAdapter =
            documentConfigurationEntityAdapter.addMany(
              documentConfigurationEntityAdapter.getInitialState(),
              response.documentConfigurations
            );
          const documentRuleAdapter = documentRuleEntityAdapter.addMany(
            documentRuleEntityAdapter.getInitialState(),
            response.documentRules
          );

          return {
            parties: partyAdapter,
            losUsers: losUserAdapter,
            deals: dealAdapter,
            properties: propertyAdapter,
            documentConfigurations: documentConfigurationAdapter,
            documentRules: documentRuleAdapter
          };
        }
      })
    })
  });
