import { AggregationType } from "@elphi/types";

import {
  booleanValidation,
  dateValidation,
  emailValidation,
  moneyValidation,
  multiSelectValidation,
  numberValidation,
  percentValidation,
  singleSelectValidation,
  stringValidation
} from "../../../../components/utils/validationUtils";
import { buildInput } from "../../utils/formBuilder.utils";
import { baseElphiFieldsSpecs } from "../spec-files/baseElphiFieldsSpecs";

export const propertyAddressLineText = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address?.AddressLineText
      ?.fieldKey,
  validation: stringValidation
});

export const propertyAddressUnitIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address?.AddressUnitIdentifier
      ?.fieldKey,
  validation: stringValidation
});

export const propertyCityName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address.CityName?.fieldKey,
  validation: stringValidation
});

export const propertyCountyName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address?.CountyName?.fieldKey,
  validation: stringValidation
});

export const propertyStateName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address?.StateName?.fieldKey,
  validation: singleSelectValidation
});

export const propertyStateCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address?.StateCode?.fieldKey,
  validation: singleSelectValidation
});

export const propertyPostalCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address?.PostalCode.fieldKey,
  validation: numberValidation
});

export const propertyType = buildInput({
  fieldKey: baseElphiFieldsSpecs.property?.entitySpecs.PropertyType?.fieldKey,
  validation: singleSelectValidation
});

export const financedUnitCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FinancedUnitCount?.fieldKey,
  validation: numberValidation
});

export const parcelNumber = buildInput({
  fieldKey: baseElphiFieldsSpecs.property?.entitySpecs.ParcelNumber?.fieldKey,
  validation: stringValidation
});

export const legalDescription = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address.LegalDescription
      ?.fieldKey,
  validation: stringValidation
});

export const propertyLotIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address.PropertyLotIdentifier
      ?.fieldKey,
  validation: stringValidation
});

export const propertyBlockIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address.PropertyBlockIdentifier
      ?.fieldKey,
  validation: stringValidation
});

export const propertySubdivisionIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Address
      .PropertySubdivisionIdentifier?.fieldKey,
  validation: stringValidation
});

export const propertyRightsOwnershipType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyRightsOwnershipType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const condoWarrantableIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.CondoWarrantableIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const condoCertificateFeesCollectedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs
      .CondoCertificateFeesCollectedIndicator?.fieldKey,
  validation: booleanValidation
});

export const condoCertificateComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.CondoCertificateComments
      ?.fieldKey,
  validation: stringValidation
});

export const propertyLoanPurpose = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyLoanPurpose?.fieldKey,
  validation: singleSelectValidation
});

export const purchasePriceAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PurchasePriceAmount?.fieldKey,
  validation: moneyValidation
});

export const outstandingLoanPayoffAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.OutstandingLoanPayoffAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const defaultInterestPaymentsOrFeesNotedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs
      .defaultInterestPaymentsOrFeesNotedIndicator?.fieldKey,
  validation: booleanValidation
});

export const allocatedLoanAmount = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations.AllocatedLoanAmount
      ?.focused?.fieldKey,
  validation: moneyValidation
});

export const bridgeLTVPercent = buildInput({
  calculationType: AggregationType.ThirdParty,
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations.BridgeLTVPercent
      ?.focused?.fieldKey,
  validation: percentValidation
});

export const rentalLTVPercent = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.RentalLTVPercent?.fieldKey,
  validation: percentValidation
});

export const pricingEngineLTARVRatePercent = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations
      .PricingEngineLTARVRatePercent?.focused?.fieldKey,
  validation: percentValidation
});

export const constructionCostAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.ConstructionCostAmount?.fieldKey,
  validation: moneyValidation
});

export const constructionCostComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.ConstructionCostComments
      ?.fieldKey,
  validation: stringValidation
});

export const leaseStatusType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.LeaseStatusType?.fieldKey,
  validation: singleSelectValidation
});

export const originalPurchaseDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.OriginalPurchaseDate?.fieldKey,
  validation: dateValidation
});

export const originalPurchasePriceAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.OriginalPurchasePriceAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const borrowerRenovationCostsAfterPurchaseAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs
      .BorrowerRenovationCostsAfterPurchaseAmount?.fieldKey,
  validation: moneyValidation
});

export const ltcRatePercent = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations.LTCRatePercent
      ?.focused?.fieldKey,
  validation: moneyValidation
});

export const annualTaxAmount = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations.AnnualTaxAmount
      ?.focused?.fieldKey,
  validation: moneyValidation
});

export const annualHomeownersAssociationFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs
      .AnnualHomeownersAssociationFeeAmount?.fieldKey,
  validation: moneyValidation
});

export const annualCapitalExpenditure = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.AnnualCapitalExpenditure
      ?.fieldKey,
  validation: moneyValidation
});

export const totalCostAmount = buildInput({
  calculationType: AggregationType.Aggregation,
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.aggregations.TotalCostAmount
      ?.focused?.fieldKey,
  validation: moneyValidation
});

export const propertyDetailComments = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyDetailComments?.fieldKey,
  validation: stringValidation
});

export const internalL1CRefinance = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.InternalL1CRefinanceIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const leaseStrategy = buildInput({
  fieldKey: baseElphiFieldsSpecs.property?.entitySpecs.LeaseStrategy?.fieldKey,
  validation: singleSelectValidation
});

export const constructionBudgetAssessment = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.ConstructionBudgetAssessment
      ?.fieldKey,
  validation: singleSelectValidation
});

export const contractExecutionDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.ContractExecutionDate?.fieldKey,
  validation: dateValidation
});

export const contractClosingDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.ContractClosingDate?.fieldKey,
  validation: dateValidation
});

export const PSAExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PSAExpirationDate?.fieldKey,
  validation: dateValidation
});

export const psaExtensionRequestedIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PSAExtensionRequestedIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const psaExtensionFormIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PSAExtensionFormIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const psaAssignmentContractIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PSAAssignmentContractIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const assignmentFeeAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.AssignmentFeeAmount?.fieldKey,
  validation: moneyValidation
});

export const psaAddendumIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PSAAddendumIndicator?.fieldKey,
  validation: booleanValidation
});

export const psaSellerOwnerRecordMismatchIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs
      .PSASellerOwnerRecordMismatchIndicator?.fieldKey,
  validation: booleanValidation
});

export const PSABuyerBorrowerMismatch = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PSABuyerBorrowerMismatchIndicator
      ?.fieldKey,
  validation: booleanValidation
});

export const payoffExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PayoffExpirationDate?.fieldKey,
  validation: dateValidation
});

export const mortgageeFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.MortgageeFullName?.fieldKey,
  validation: stringValidation
});

export const mortgageeContactFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.MortgageeContactFullName
      ?.fieldKey,
  validation: stringValidation
});

export const mortgageeContactPointTelephone = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs
      .MortgageeContactPointTelephoneValue?.fieldKey,
  validation: stringValidation
});

export const mortgageeContactPointEmailValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.MortgageeContactPointEmailValue
      ?.fieldKey,
  validation: emailValidation
});

export const cemaRefinanceIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.CEMARefinanceIndicator?.fieldKey,
  validation: booleanValidation
});

export const appraisalValueDerivationType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .AppraisalValueDerivationType?.fieldKey,
  validation: singleSelectValidation
});

export const propertyValuationAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.PropertyValuationAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const subjectToPropertyValuationAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .SubjectToPropertyValuationAmount?.fieldKey,
  validation: moneyValidation
});

export const appraisalOrderedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.AppraisalOrderedDate
      ?.fieldKey,
  validation: dateValidation
});

export const appraisalCompletedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.AppraisalCompletedDate
      ?.fieldKey,
  validation: dateValidation
});

export const appraisalEffectiveDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.AppraisalEffectiveDate
      ?.fieldKey,
  validation: dateValidation
});

export const appraisalExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.AppraisalExpirationDate
      ?.fieldKey,
  validation: dateValidation
});

export const appraisalType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.AppraisalType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const qualityRatingType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.QualityRatingType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const conditionRatingType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.ConditionRatingType
      ?.fieldKey,
  validation: singleSelectValidation
});

export const propertyAreaValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.PropertyAreaValue
      ?.fieldKey,
  validation: numberValidation
});

export const totalBedroomCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.TotalBedroomCount
      ?.fieldKey,
  validation: numberValidation
});

export const totalBathroomCount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.TotalBathroomCount
      ?.fieldKey,
  validation: numberValidation
});

export const propertyStructureBuiltYear = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .PropertyStructureBuiltYear?.fieldKey,
  validation: numberValidation
});

export const specificZoningClassification = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .SpecificZoningClassification?.fieldKey,
  validation: stringValidation
});

export const propertyZoningDescription = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .PropertyZoningDescription?.fieldKey,
  validation: stringValidation
});

export const propertyZoningComplianceRatingType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .PropertyZoningComplianceRatingType?.fieldKey,
  validation: singleSelectValidation
});

export const appraisalCoordinator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal.AppraisalCoordinator
      ?.fieldKey,
  validation: singleSelectValidation
});

export const internalValuationEligibilityIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .InternalValuationEligibilityIndicator?.fieldKey,
  validation: booleanValidation
});

export const appraisalForm1007RequiredIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .AppraisalForm1007RequiredIndicator?.fieldKey,
  validation: booleanValidation
});

export const appraisalManagementRepresentativeFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.AppraisalManagementCompany
      .RepresentativeFullName?.fieldKey,
  validation: stringValidation
});

export const appraisalManagementContactPointTelephoneValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.AppraisalManagementCompany
      .ContactPointTelephoneValue?.fieldKey,
  validation: stringValidation
});

export const appraisalManagementContactPointEmailValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.AppraisalManagementCompany
      .ContactPointEmailValue?.fieldKey,
  validation: emailValidation
});

export const appraisalManagementCompanyName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.AppraisalManagementCompany
      .AppraisalManagementCompanyName?.fieldKey,
  validation: stringValidation
});

export const propertyAccessInformation = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.Appraisal
      .PropertyAccessInformation?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceDwellingCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsurance
      .DwellingCoverageAmount?.fieldKey,
  validation: moneyValidation
});

export const propertyInsuranceLiabilityCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsurance
      .LiabilityCoverageAmount?.fieldKey,
  validation: moneyValidation
});

export const propertyInsuranceReplacementCoverageIndicator = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsurance
      .ReplacementCoverageIndicator?.fieldKey,
  validation: booleanValidation
});

export const propertyInsuranceRentalLossCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsurance
      .RentalLossCoverageAmount?.fieldKey,
  validation: moneyValidation
});

export const propertyInsurancePremiumOutstandingAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsurance
      .InsurancePremiumOutstandingAmount?.fieldKey,
  validation: moneyValidation
});

export const propertyInsurancePremiumAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsurance
      .InsurancePremiumAmount?.fieldKey,
  validation: moneyValidation
});

export const propertyInsurancePremiumPaymentType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsurance
      .InsurancePremiumPaymentType?.fieldKey,
  validation: singleSelectValidation
});

export const propertyInsurancePolicyIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsurance
      .PropertyInsurancePolicyIdentifier?.fieldKey,
  validation: stringValidation
});

export const propertyInsurancePolicyExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsurance
      .PolicyExpirationDate?.fieldKey,
  validation: dateValidation
});

export const propertyInsuranceNextPaymentDueDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsurance
      .NextPaymentDueDate?.fieldKey,
  validation: dateValidation
});

export const propertyInsuranceDeductibleAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsurance
      .DeductibleAmount?.fieldKey,
  validation: moneyValidation
});

export const propertyInsuranceAgentFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsuranceAgency
      .InsuranceAgentFullName?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsuranceAgency.FullName
      ?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceContactPointTelephone = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsuranceAgency
      .ContactPointTelephoneValue?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceContactPointEmail = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsuranceAgency
      .ContactPointEmailValue?.fieldKey,
  validation: emailValidation
});

export const propertyInsuranceAddressLineText = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsuranceAgency
      .AddressLineText?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceAddressUnitIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsuranceAgency
      .AddressUnitIdentifier?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceCityName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsuranceAgency.CityName
      ?.fieldKey,
  validation: stringValidation
});

export const propertyInsuranceStateCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsuranceAgency.StateCode
      ?.fieldKey,
  validation: singleSelectValidation
});

export const propertyInsurancePostalCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsuranceAgency
      .PostalCode?.fieldKey,
  validation: numberValidation
});

export const propertyInsuranceBuilderRiskInsurance = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInsurance
      .BuilderRiskInsurance?.fieldKey,
  validation: moneyValidation
});

export const floodInsuranceDwellingCoverageAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsurance
      .DwellingCoverageAmount?.fieldKey,
  validation: moneyValidation
});
export const floodInsurancePremiumAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsurance
      .InsurancePremiumAmount?.fieldKey,
  validation: moneyValidation
});

export const floodInsurancePremiumPaymentType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsurance
      .InsurancePremiumPaymentType?.fieldKey,
  validation: singleSelectValidation
});

export const floodInsurancePremiumOutstandingAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsurance
      .InsurancePremiumOutstandingAmount?.fieldKey,
  validation: moneyValidation
});

export const floodInsurancePolicyNumber = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsurance.PolicyNumber
      ?.fieldKey,
  validation: stringValidation
});

export const floodInsurancePolicyExpirationDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsurance
      .PolicyExpirationDate?.fieldKey,
  validation: dateValidation
});

export const floodInsuranceNextPaymentDueDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsurance.NextPaymentDueDate
      ?.fieldKey,
  validation: dateValidation
});

export const floodInsuranceDeductibleAmount = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsurance.DeductibleAmount
      ?.fieldKey,
  validation: moneyValidation
});

export const floodInsuranceFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsuranceAgency.FullName
      ?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceRepresentativeFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsuranceAgency
      .RepresentativeFullName?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceContactPointTelephoneValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsuranceAgency
      .ContactPointTelephoneValue?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceContactPointEmailValue = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsuranceAgency
      .ContactPointEmailValue?.fieldKey,
  validation: emailValidation
});

export const floodInsuranceAddressLineText = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsuranceAgency
      .AddressLineText?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceAddressUnitIdentifier = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsuranceAgency
      .AddressUnitIdentifier?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceCityName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsuranceAgency.CityName
      ?.fieldKey,
  validation: stringValidation
});

export const floodInsuranceStateCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsuranceAgency.StateCode
      ?.fieldKey,
  validation: singleSelectValidation
});

export const floodInsurancePostalCode = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.FloodInsuranceAgency.PostalCode
      ?.fieldKey,
  validation: numberValidation
});

export const inspectionInitialRequestDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionInitialRequestDate?.fieldKey,
  validation: dateValidation
});

export const inspectionRequestOrderedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionRequestOrderedDate?.fieldKey,
  validation: dateValidation
});

export const inspectionReportDueDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionReportDueDate?.fieldKey,
  validation: dateValidation
});

export const inspectionOrderType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionOrderType?.fieldKey,
  validation: singleSelectValidation
});

export const inspectorFullName = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectorFullName?.fieldKey,
  validation: stringValidation
});

export const inspectionOrderReceivedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionOrderReceivedDate?.fieldKey,
  validation: dateValidation
});

export const inspectionOrderProcessedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionOrderProcessedDate?.fieldKey,
  validation: dateValidation
});

export const inspectionDocumentsReceivedDate = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionDocumentsReceivedDate?.fieldKey,
  validation: dateValidation
});

export const inspectionInitialDelayReasonStatusType = buildInput({
  fieldKey:
    baseElphiFieldsSpecs.property?.entitySpecs.PropertyInspection
      .InspectionInitialDelayReasonStatusType?.fieldKey,
  validation: multiSelectValidation
});
