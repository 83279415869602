import { Status } from "../../common";
import { PartyGroup } from "../partyGroup.types";

export const getPartyGroupTemplate = (): Partial<PartyGroup> => {
  return {
    name: "",
    description: "",
    status: Status.Disabled
  };
};
