import { LOSSettings } from "@elphi/types";
import { createContext, useContext, useState } from "react";
import { auth } from "../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import { losSettingsApi } from "../../../../redux/v2/los-settings/losSettings.service";

export const useLosSettingsContextHooks = () => {
  return useContext(LosSettingsContext);
};

export const LosSettingsContext = createContext<Partial<LOSSettings>>({});

export const LOSSettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState<Partial<LOSSettings>>({});
  const [losSettingsGetApi, _] = losSettingsApi.useLazyGetQuery();

  useAuthStateChangeHook({
    onAuthStateChange: (user) => {
      if (user?.uid) {
        losSettingsGetApi({})
          .then((res) => {
            setSettings(res.data?.result || {});
          })
          .catch((e) => {
            console.error("Error fetching los settings", e);
          });
      }
    },
    deps: [auth.currentUser]
  });

  return (
    <LosSettingsContext.Provider value={settings}>
      {children}
    </LosSettingsContext.Provider>
  );
};
