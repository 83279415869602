import {
  PolicyEntityBaseData,
  PropertyBuildings,
  PropertyCoveragesFields,
  PropertyInsurancePolicyCoverageType
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { getSectionsLoader } from "../../../../../../../forms/schemas/utils/sectionsLoaderFactory";
import { OnChangeInput } from "../../../../../../form-builder/FormBuilder";
import { PropertyInsurancePolicyState } from "../../types/insurancePolicySteps.types";

export const buildingsPath: DotNestedKeys<PropertyBuildings> = "buildings";
export const coveragesTypesToFieldsMapping: {
  [key in PropertyInsurancePolicyCoverageType]: DotNestedKeys<
    Required<Pick<PolicyEntityBaseData<PropertyCoveragesFields>, "coverages">>
  >[];
} = {
  [PropertyInsurancePolicyCoverageType.Hazard]: [
    "coverages.hazardCoverageAmount",
    "coverages.hazardReplacementCoverage"
  ],
  [PropertyInsurancePolicyCoverageType.Flood]: [
    "coverages.floodCoverageAmount"
  ],
  [PropertyInsurancePolicyCoverageType.Liability]: [
    "coverages.liabilityCoverageAmount"
  ],
  [PropertyInsurancePolicyCoverageType.BuildersRisk]: [
    "coverages.buildersRiskCoverageAmount"
  ],

  [PropertyInsurancePolicyCoverageType.RentLoss]: [
    "coverages.rentLossCoverageAmount"
  ],

  [PropertyInsurancePolicyCoverageType.Earthquake]: [
    "coverages.earthquakeCoverageAmount"
  ],

  [PropertyInsurancePolicyCoverageType.Wind]: ["coverages.windCoverageAmount"],
  [PropertyInsurancePolicyCoverageType.CondoMasterHazard]: [
    "coverages.condoMasterHazardCoverageAmount"
  ],

  [PropertyInsurancePolicyCoverageType.HO6]: ["coverages.HO6CoverageAmount"],
  [PropertyInsurancePolicyCoverageType.CondoMasterFlood]: [
    "coverages.condoMasterFloodCoverageAmount"
  ],
  [PropertyInsurancePolicyCoverageType.CAFairPlan]: [
    "coverages.CAFairPlanCoverageAmount"
  ],
  [PropertyInsurancePolicyCoverageType.ExcessFlood]: ["coverages.excessFlood"],
  [PropertyInsurancePolicyCoverageType.Lava]: ["coverages.lavaCoverageAmount"]
};

export type CoveragesSectionProps = {
  propertyId: string;
  state: PropertyInsurancePolicyState;
  isReadOnly: boolean;
  onChange: (e: OnChangeInput) => void;
  prefix: string[];
};

export const getPropertyCoveragesSection = (props: CoveragesSectionProps) => {
  const { state, onChange, isReadOnly, prefix } = props;
  const selectedCoverageTypes = state?.coverageTypes || [];
  const visibleFields = selectedCoverageTypes
    .map((c) => {
      const pathArr = coveragesTypesToFieldsMapping[c];
      return pathArr.map((p) => p);
    })
    .flat();
  const sections = getSectionsLoader().loadSections();
  const coveragesSection =
    sections?.insurancePolicy?.propertyInsurancePolicyCoveragesDataSection?.({
      onChange,
      state,
      isReadOnly,
      prefix,
      visibleFields
    });

  return coveragesSection;
};
