import { ElphiEntityType } from "@elphi/types";
import { merge } from "lodash";
import { accountAssetFieldSpecs } from "../../../../components/form-builder/field-specs/asset/asset.fields";
import {
  dealToEntityFieldSpecs,
  dealToIndividualFieldSpecs
} from "../../../../components/form-builder/field-specs/deal-party/deal-party.fields";
import { dealPropertyFieldSpecs } from "../../../../components/form-builder/field-specs/deal-property/deal-property.fields";
import { dealFieldSpecs } from "../../../../components/form-builder/field-specs/deal/deal.fields";
import { fullBaseInsurancePolicyFieldFieldSpecs } from "../../../../components/form-builder/field-specs/insurance-policy/baseInsurancePolicy.fields";
import {
  basePropertyInsurancePolicyFieldSpecs,
  propertyBuildingsFieldSpecs,
  propertyInsurancePolicyCoveragesFieldBaseSpecs
} from "../../../../components/form-builder/field-specs/insurance-policy/property";
import {
  entityToEntityRelationFieldSpecs,
  entityToIndividualRelationFieldSpecs
} from "../../../../components/form-builder/field-specs/party-relation/party-relation.fields";
import {
  basePartyFieldSpecs,
  entityPartyFieldSpecs,
  individualPartyFieldSpecs
} from "../../../../components/form-builder/field-specs/party/party.fields";
import { propertySpecFields } from "../../../../components/form-builder/field-specs/property/property.fields";
import {
  branchServiceProviderFieldsSpecs,
  companyServiceProviderFieldsSpecs,
  fullBranchServiceProviderFieldsSpecs,
  fullCompanyServiceProviderFieldsSpecs,
  fullRepServiceProviderFieldsSpecs,
  repServiceProviderFieldsSpecs
} from "../../../../components/form-builder/field-specs/service-provider/serviceProvider.fields";
import { statementFieldSpecs } from "../../../../components/form-builder/field-specs/statement/statement.fields";
import {
  taskBaseSpec,
  taskWithOrderStatusFieldSpecs
} from "../../../../components/form-builder/field-specs/task/task.fields";
import { BaseSpecs } from "../types/baseSchema.types";

export const baseElphiFieldsSpecs: BaseSpecs = {
  [ElphiEntityType.deal]: {
    entitySpecs: dealFieldSpecs
  },
  [ElphiEntityType.party]: {
    entitySpecs: merge(
      {},
      entityPartyFieldSpecs,
      individualPartyFieldSpecs,
      basePartyFieldSpecs
    ),
    specsParts: {
      entityPartyFieldSpecs,
      individualPartyFieldSpecs,
      basePartyFieldSpecs
    }
  },
  [ElphiEntityType.property]: {
    entitySpecs: propertySpecFields
  },
  [ElphiEntityType.statement]: {
    entitySpecs: statementFieldSpecs
  },
  [ElphiEntityType.asset]: {
    entitySpecs: accountAssetFieldSpecs
  },
  [ElphiEntityType.partyRelation]: {
    entitySpecs: merge(
      {},
      entityToEntityRelationFieldSpecs,
      entityToIndividualRelationFieldSpecs
    ),
    specsParts: {
      entityToEntityRelationFieldSpecs,
      entityToIndividualRelationFieldSpecs
    }
  },
  [ElphiEntityType.dealParty]: {
    entitySpecs: merge({}, dealToEntityFieldSpecs, dealToIndividualFieldSpecs),
    specsParts: {
      dealToIndividualFieldSpecs,
      dealToEntityFieldSpecs
    }
  },
  [ElphiEntityType.task]: {
    entitySpecs: merge({}, taskBaseSpec, taskWithOrderStatusFieldSpecs)
  },
  [ElphiEntityType.serviceProvider]: {
    entitySpecs: merge(
      {},
      fullCompanyServiceProviderFieldsSpecs,
      fullBranchServiceProviderFieldsSpecs,
      fullRepServiceProviderFieldsSpecs
    ),
    specsParts: {
      fullCompanyServiceProviderFieldsSpecs,
      fullBranchServiceProviderFieldsSpecs,
      fullRepServiceProviderFieldsSpecs,
      companyServiceProviderFieldsSpecs,
      branchServiceProviderFieldsSpecs,
      repServiceProviderFieldsSpecs
    }
  },
  [ElphiEntityType.insurancePolicy]: {
    entitySpecs: merge(
      {},
      fullBaseInsurancePolicyFieldFieldSpecs,
      basePropertyInsurancePolicyFieldSpecs,
      propertyBuildingsFieldSpecs,
      propertyInsurancePolicyCoveragesFieldBaseSpecs
    ),
    specsParts: {
      property: {
        basePropertyInsurancePolicyFieldSpecs,
        propertyBuildingsFieldSpecs,
        propertyInsurancePolicyCoveragesFieldBaseSpecs
      },
      fullBaseInsurancePolicyFieldFieldSpecs
    }
  },
  [ElphiEntityType.dealProperty]: {
    entitySpecs: dealPropertyFieldSpecs
  }
};
