import { Deal } from "@elphi/types";

import { hideFixNFlip } from "../../../../components/application/sections/sections.utils";
import { OnChangeInput } from "../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../components/form-builder/field-specs/fields.types";
import { addRulesToInputs, buildSection } from "../../utils/formBuilder.utils";
import {
  applicationRequestedLoanAmount,
  arvAdjuster,
  associatedPropertiesDescription,
  blendedLTCAdjuster,
  buildingEnvelopeExpandedIndicator,
  buildingPermitsExpectedDate,
  buildingPermitsIndicator,
  constructionBudgetRemainingAmount,
  creditMemoApprovedDate,
  creditMemoDraftedDate,
  currentLotMarketValueAmount,
  developmentStrategy,
  exceptionApprovedBy,
  exceptionCommentary,
  exceptionType,
  exitStrategy,
  externalApprovalDate,
  externalSubmissionDate,
  feeLLPAs,
  hoaContactEmail,
  hoaContactName,
  hoaContactPhone,
  interestReserveEscrowOverrideAmount,
  interestReserveEscrowOverrideMonthCount,
  internalApprovalDate,
  internalL1CRefinanceIndicator,
  internalSubmissionDate,
  lOIAcceptedDate,
  lOIIssuedDate,
  lenderPropertyInsuranceProviderIndicator,
  lenderTitleProviderIndicator,
  lienDollarAmount,
  limaOriginationFeeToBorrowerPercent,
  limaRateToBorrowerPercent,
  loadBearingWallsRemoveIntendedIndicator,
  lotStatus,
  lotUtilitiesIndicator,
  lotZonedParcelIdIndicator,
  ltvEligibility,
  occupancy,
  occupationIndicator,
  policySection,
  pricingEngineMaxBlendedLoanToCostPercent,
  pricingEngineMaxLoanToAfterRepairValuePercent,
  pricingEngineMaxPurchaseLoanToCostPercent,
  pricingEngineNoteRateOverridePercent,
  pricingEngineOverrides,
  pricingEngineTotalBlendedLoanToCostOverridePercent,
  pricingEngineTotalLoanToAfterRepairValueOverridePercent,
  pricingEngineTotalPurchaseLoanToCostOverridePercent,
  pricingEngineWarnings,
  processingFeeOverride,
  programDirectorApprovalIndicator,
  programDirectorComments,
  projectAnticipatedCompletionTimeline,
  propertyLeasePurchaseOptionIndicator,
  propertyRentReadyConditionIndicator,
  propertyTypeChangingIndicator,
  propertyZoningChangesIndicator,
  purchaseLTCAdjuster,
  quoteId,
  rateLLPAs,
  sellerRelationshipIndicator,
  squareFootageAddedIndicator,
  subdividePropertyPartialReleasesIndicator,
  submissionNotes,
  targetCloseDate,
  underwriterApprovalComments,
  underwriterApprovalStatus,
  validationErrors,
  verifiableConstructionCompletedAmount
} from "../inputs/deal.inputs";

const underwritingDealApprovalSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Underwriting Deal Approval",
      inputs: [
        underwriterApprovalStatus,
        underwriterApprovalComments,
        programDirectorApprovalIndicator,
        programDirectorComments,
        submissionNotes
      ],
      fieldSpecs
    });

const creditReviewCommitteeApprovalSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Credit Review Committee Approval",
      inputs: [
        creditMemoDraftedDate,
        creditMemoApprovedDate,
        internalSubmissionDate,
        internalApprovalDate,
        externalSubmissionDate,
        externalApprovalDate,
        lOIIssuedDate,
        lOIAcceptedDate
      ],
      fieldSpecs
    });

const pricingEngineOverridesAndLLPAsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Pricing Engine Overrides and LLPAs",
      inputs: [
        quoteId,
        validationErrors,
        ltvEligibility,
        pricingEngineWarnings,
        pricingEngineOverrides,
        feeLLPAs,
        rateLLPAs,
        internalL1CRefinanceIndicator,
        limaRateToBorrowerPercent,
        limaOriginationFeeToBorrowerPercent,
        ...addRulesToInputs({
          inputs: [
            pricingEngineTotalLoanToAfterRepairValueOverridePercent,
            pricingEngineTotalBlendedLoanToCostOverridePercent,
            pricingEngineTotalPurchaseLoanToCostOverridePercent
          ],
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () => hideFixNFlip(r.state)
            }
          ]
        }),
        pricingEngineNoteRateOverridePercent,
        ...addRulesToInputs({
          inputs: [
            pricingEngineMaxLoanToAfterRepairValuePercent,
            pricingEngineMaxBlendedLoanToCostPercent,
            pricingEngineMaxPurchaseLoanToCostPercent,
            blendedLTCAdjuster,
            purchaseLTCAdjuster,
            arvAdjuster
          ],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        interestReserveEscrowOverrideMonthCount,
        interestReserveEscrowOverrideAmount,
        processingFeeOverride
      ],
      fieldSpecs
    });

const exceptionSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Exceptions",
      inputs: [
        exceptionType,
        policySection,
        exceptionCommentary,
        exceptionApprovedBy
      ],
      fieldSpecs
    });

const miscellaneousApplicationInformationSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: { state: Partial<Deal>; onChange: (v: OnChangeInput) => void }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Miscellaneous Application Information",
      inputs: [
        exitStrategy,
        targetCloseDate,
        applicationRequestedLoanAmount,
        projectAnticipatedCompletionTimeline,
        constructionBudgetRemainingAmount,
        verifiableConstructionCompletedAmount,
        lienDollarAmount,
        propertyTypeChangingIndicator,
        propertyZoningChangesIndicator,
        lenderPropertyInsuranceProviderIndicator,
        lenderTitleProviderIndicator,
        hoaContactName,
        hoaContactPhone,
        hoaContactEmail,
        occupancy,
        subdividePropertyPartialReleasesIndicator,
        propertyRentReadyConditionIndicator,
        propertyLeasePurchaseOptionIndicator,
        occupationIndicator,
        sellerRelationshipIndicator,
        associatedPropertiesDescription,
        developmentStrategy,
        lotStatus,
        currentLotMarketValueAmount,
        lotZonedParcelIdIndicator,
        buildingPermitsIndicator,
        buildingPermitsExpectedDate,
        lotUtilitiesIndicator,
        buildingEnvelopeExpandedIndicator,
        loadBearingWallsRemoveIntendedIndicator,
        squareFootageAddedIndicator
      ],
      fieldSpecs
    });
export const dealUnderwritingSectionBuilders = {
  underwritingDealApprovalSection,
  creditReviewCommitteeApprovalSection,
  pricingEngineOverridesAndLLPAsSection,
  exceptionSection,
  miscellaneousApplicationInformationSection
};
