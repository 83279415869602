import {
  BranchServiceProvider,
  BranchServiceProviderFields,
  CompanyServiceProvider,
  CompanyServiceProviderFields,
  FieldType,
  RepresentativeServiceProvider,
  RepresentativeServiceProviderFields,
  ServiceProviderLendingPartnerType,
  ServiceProviderStatusType,
  ServiceProviderType,
  StateName,
  Status
} from "@elphi/types";
import { ConfigurationSearch } from "../../../rolodex/configuration/tabs/service-domain-configuration/search/ConfigurationSearch";
import {
  createOptionsFromEnum,
  createSelectOptions
} from "../../../utils/formUtils";
import { createCustomComponentConfig } from "../../FormBuilder";
import { einInputFormatter } from "../../formatters/inputs.formatter";
import { nestedAddressFieldSpecs } from "../address/address.fields";
import { baseEntityFormFieldSpecs } from "../baseEntity.fields";
import { EntityFormFieldSpecs, SpecsBaseOmit } from "../fields.types";
import { createSpecWithFieldMeta } from "../utils/fieldMeta.utils";

export const serviceProviderLendingPartnerOptions: {
  [s in ServiceProviderLendingPartnerType]: string;
} = {
  [ServiceProviderLendingPartnerType.Broker]: "Broker",
  [ServiceProviderLendingPartnerType.CorrespondantLender]:
    "Correspondant Lender",
  [ServiceProviderLendingPartnerType.Wholesale]: "Wholesale"
};

export const companyServiceProviderFieldsSpecs: EntityFormFieldSpecs<CompanyServiceProviderFields> =
  {
    name: {
      fieldKey: ["name"],
      fieldType: FieldType.String,
      label: "Name"
    },
    status: {
      fieldKey: ["status"],
      fieldType: FieldType.SingleSelect,
      label: "Status",
      options: createOptionsFromEnum(ServiceProviderStatusType)
    },
    ein: {
      fieldKey: ["ein"],
      fieldType: FieldType.String,
      label: "EIN",
      formatter: einInputFormatter
    },
    experienceLevel: {
      fieldKey: ["experienceLevel"],
      fieldType: FieldType.Number,
      label: "Experience Level"
    },
    notes: {
      fieldKey: ["notes"],
      fieldType: FieldType.RichText,
      label: "Notes"
    },
    crmId: {
      fieldKey: ["crmId"],
      fieldType: FieldType.String,
      label: "CRM ID"
    }
  };

export const branchServiceProviderFieldsSpecs: EntityFormFieldSpecs<BranchServiceProviderFields> =
  {
    ...nestedAddressFieldSpecs,
    name: {
      fieldKey: ["name"],
      fieldType: FieldType.String,
      label: "Name"
    },
    status: {
      fieldKey: ["status"],
      fieldType: FieldType.SingleSelect,
      label: "Status",
      options: createOptionsFromEnum(ServiceProviderStatusType)
    },
    ein: {
      fieldKey: ["ein"],
      fieldType: FieldType.String,
      label: "EIN",
      formatter: einInputFormatter
    },
    phoneNumber: {
      fieldKey: ["phoneNumber"],
      fieldType: FieldType.Phone,
      label: "Phone Number"
    },
    email: {
      fieldKey: ["email"],
      fieldType: FieldType.Email,
      label: "Email"
    },
    coverageArea: {
      fieldKey: ["coverageArea"],
      fieldType: FieldType.String,
      label: "Coverage Area"
    },
    numberOfEmployees: {
      fieldKey: ["numberOfEmployees"],
      fieldType: FieldType.Number,
      label: "Number Of Employees"
    },
    nmlsLicenseNumber: {
      fieldKey: ["nmlsLicenseNumber"],
      fieldType: FieldType.String,
      label: "NMLS License Number"
    },
    lendingPartnerType: {
      fieldKey: ["lendingPartnerType"],
      fieldType: FieldType.SingleSelect,
      label: "Lending Partner Type",
      options: createSelectOptions(
        ServiceProviderLendingPartnerType,
        serviceProviderLendingPartnerOptions
      )
    },
    wireRoutingNumber: {
      fieldKey: ["wireRoutingNumber"],
      fieldType: FieldType.String,
      label: "Wire Routing Number"
    },
    wireAccountNumber: {
      fieldKey: ["wireAccountNumber"],
      fieldType: FieldType.String,
      label: "Wire Account Number"
    },
    wireNameOnAccount: {
      fieldKey: ["wireNameOnAccount"],
      fieldType: FieldType.String,
      label: "Wire Name On Account"
    },
    experienceLevel: {
      fieldKey: ["experienceLevel"],
      fieldType: FieldType.Number,
      label: "Experience Level"
    },
    notes: {
      fieldKey: ["notes"],
      fieldType: FieldType.String,
      label: "Notes"
    },
    crmId: {
      fieldKey: ["crmId"],
      fieldType: FieldType.String,
      label: "CRM ID"
    }
  };

export const repServiceProviderFieldsSpecs: EntityFormFieldSpecs<RepresentativeServiceProviderFields> =
  {
    ...nestedAddressFieldSpecs,
    status: {
      fieldKey: ["status"],
      fieldType: FieldType.SingleSelect,
      label: "Status",
      options: createOptionsFromEnum(ServiceProviderStatusType)
    },
    firstName: {
      fieldKey: ["firstName"],
      fieldType: FieldType.String,
      label: "First Name"
    },
    middleName: {
      fieldKey: ["middleName"],
      fieldType: FieldType.String,
      label: "Middle Name"
    },
    lastName: {
      fieldKey: ["lastName"],
      fieldType: FieldType.String,
      label: "Last Name"
    },
    phoneNumber: {
      fieldKey: ["phoneNumber"],
      fieldType: FieldType.Phone,
      label: "Phone Number"
    },
    email: {
      fieldKey: ["email"],
      fieldType: FieldType.Email,
      label: "Email"
    },
    licenseNumber: {
      fieldKey: ["licenseNumber"],
      fieldType: FieldType.String,
      label: "License Number"
    },
    driversLicenseNumber: {
      fieldKey: ["driversLicenseNumber"],
      fieldType: FieldType.String,
      label: "Drivers License Number"
    },
    nmlsLicenseNumber: {
      fieldKey: ["nmlsLicenseNumber"],
      fieldType: FieldType.String,
      label: "NMLS License Number"
    },
    birthDate: {
      fieldKey: ["birthDate"],
      fieldType: FieldType.Date,
      label: "Birth Date"
    },
    businessRegistrationStates: {
      fieldKey: ["businessRegistrationStates"],
      fieldType: FieldType.MultiSelect,
      label: "Business Registration States",
      options: createOptionsFromEnum(StateName)
    },
    experienceLevel: {
      fieldKey: ["experienceLevel"],
      fieldType: FieldType.Number,
      label: "Experience Level"
    },
    notes: {
      fieldKey: ["notes"],
      fieldType: FieldType.String,
      label: "Notes"
    },
    crmId: {
      fieldKey: ["crmId"],
      fieldType: FieldType.String,
      label: "CRM ID"
    }
  };

export type FullCompanyServiceProviderFieldsSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<CompanyServiceProvider>
>;

export type FullBranchServiceProviderFieldsSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<BranchServiceProvider>
>;

export type FullRepServiceProviderFieldsSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<RepresentativeServiceProvider>
>;

const buildFullCompanyServiceProviderFieldsSpecs = () => {
  const specs: FullCompanyServiceProviderFieldsSpecs = {
    ...baseEntityFormFieldSpecs,
    ...companyServiceProviderFieldsSpecs,
    partyIds: {
      fieldKey: ["partyIds"],
      fieldType: FieldType.SingleSelect,
      label: "Party Ids"
    },
    domainConfigurationId: {
      fieldKey: ["domainConfigurationId"],
      fieldType: FieldType.String,
      label: "Domain Configuration Id"
    },
    type: {
      fieldKey: ["type"],
      fieldType: FieldType.SingleSelect,
      label: "Type",
      options: createOptionsFromEnum(ServiceProviderType)
    }
  };

  return createSpecWithFieldMeta({
    spec: specs
  });
};

const buildFullBranchServiceProviderFieldsSpecs = () => {
  const specs: FullBranchServiceProviderFieldsSpecs = {
    ...baseEntityFormFieldSpecs,
    ...branchServiceProviderFieldsSpecs,
    partyIds: {
      fieldKey: ["partyIds"],
      fieldType: FieldType.SingleSelect,
      label: "Party Ids"
    },
    companyId: {
      fieldKey: ["companyId"],
      fieldType: FieldType.String,
      label: "Company Id"
    },
    type: {
      fieldKey: ["type"],
      fieldType: FieldType.SingleSelect,
      label: "Type",
      options: createOptionsFromEnum(ServiceProviderType)
    },
    domainConfigurationId: {
      fieldKey: ["domainConfigurationId"],
      fieldType: FieldType.String,
      label: "Domain Configuration Id"
    }
  };

  return createSpecWithFieldMeta({
    spec: specs
  });
};

const buildFullRepServiceProviderFieldsSpecs = () => {
  const specs: FullRepServiceProviderFieldsSpecs = {
    ...baseEntityFormFieldSpecs,
    ...repServiceProviderFieldsSpecs,
    partyIds: {
      fieldKey: ["partyIds"],
      fieldType: FieldType.SingleSelect,
      label: "Party Ids"
    },
    companyId: {
      fieldKey: ["companyId"],
      fieldType: FieldType.String,
      label: "Company Id"
    },
    type: {
      fieldKey: ["type"],
      fieldType: FieldType.SingleSelect,
      label: "Type",
      options: createOptionsFromEnum(ServiceProviderType)
    },
    skipped: {
      fieldKey: ["skipped"],
      fieldType: FieldType.Boolean,
      label: "Skipped"
    },
    domainConfigurationId: {
      fieldKey: ["domainConfigurationId"],
      fieldType: FieldType.SingleSelect,
      label: "Domain Configuration Id",
      componentOverride: createCustomComponentConfig({
        component: ConfigurationSearch,
        props: {
          filter: (x) => x.status === Status.Active
        }
      })
    }
  };

  return createSpecWithFieldMeta({
    spec: specs
  });
};

export const fullCompanyServiceProviderFieldsSpecs =
  buildFullCompanyServiceProviderFieldsSpecs();

export const fullBranchServiceProviderFieldsSpecs =
  buildFullBranchServiceProviderFieldsSpecs();

export const fullRepServiceProviderFieldsSpecs =
  buildFullRepServiceProviderFieldsSpecs();
