import { TObject, TProperties, Type } from "@sinclair/typebox";
import { Asset, AssetType, getAssetTemplate } from "../entities";
import { AggregationFieldSchema } from "./aggregation.typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { PartyAssetFieldsSchema } from "./partyAsset.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import { StatementCreate, StatementSchema } from "./statement.typebox";
import {
  ArrayField,
  EnumField,
  MoneyAmountField,
  PercentField,
  StringField
} from "./utils.typebox";
import { FieldMetaTarget, buildFieldMetadata } from "./utils/fieldMeta.utils";

export const AssetAggregationsSchema = Type.Object({
  finalEstimationAssetValue: AggregationFieldSchema(StringField)
});

const fieldMetaSchema = buildFieldMetadata<Asset, TObject<TProperties>>({
  entityTemplate: getAssetTemplate(AssetType.Account),
  target: FieldMetaTarget.Schema
});

export const AssetFieldsSchema = Type.Object({
  fieldMeta: Type.Partial(fieldMetaSchema),
  type: EnumField(AssetType),
  statements: ArrayField(StringField),
  estimatedValueAmount: MoneyAmountField,
  estimatedValueConsideredPercentage: PercentField,
  appraisalValueAmount: MoneyAmountField,
  finalEstimatedValueAmount: MoneyAmountField,
  aggregations: Type.Partial(AssetAggregationsSchema)
});

export const AssetSchema = Type.Intersect([
  BaseEntitySchema,
  AssetFieldsSchema
]);

export const AssetUpdate = OmitUpdateSchema(AssetSchema);
export const AssetCreate = OmitCreateSchema(AssetSchema);

export const AddAssetToPartyRequeset = Type.Object({
  asset: AssetCreate,
  partyId: StringField
});
export const AddStatementToAssetRequest = Type.Object({
  statement: StatementCreate,
  assetId: StringField
});

export const AddAssetToPartyResponse = Type.Object({
  asset: AssetCreate,
  partyId: StringField
});
export const AddStatementToAssetResponse = Type.Object({
  statement: StatementSchema,
  partyId: StringField,
  relation: PartyAssetFieldsSchema
});

export const getOperationAssetSchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  if (op === "create") {
    return AssetCreate;
  } else if (op === "update") {
    return AssetUpdate;
  }
  throw `${op} is not supported`;
};
