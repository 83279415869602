import { Button, Flex } from "@chakra-ui/react";
import { InsurancePolicyEntityType } from "@elphi/types";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { FilterMenuOptions } from "../../../common-components/menu-options/FilterMenuOptions";
import { useFilterMenuHook } from "../../../common-components/menu-options/filter-menu-hook";
import { auth } from "../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../hooks/authStateChange.hooks";
import { useInsurancePolicyViewHooks } from "../../../hooks/insurance-policy/InsurancePolicyView.hooks";
import { useInsurancePolicyHooks } from "../../../hooks/insurance-policy/insurancePolicy.hooks";
import ModalContainer from "../../modal-container/ModalContainer";
import { statusOptions } from "../../rolodex/service-provider/list-card/card/utils.ts/card.utils";
import { serviceProviderListHooks } from "../../rolodex/service-provider/list-card/serviceProviderList.hooks";
import { useInsurancePolicyModalsHooks } from "../insurancePolicyModal.hooks";
import { PropertyInsurancePolicyModal } from "../property/PropertyInsurancePolicyModal";
import { useInsurancePolicyContainerHooks } from "./InsurancePolicyContainer.hooks";
import { InsurancePolicyContainerHeader } from "./InsurancePolicyContainerHeader";
import { DealInsurancePolicyCards } from "./card/DealInsurancePolicyCards";
import {
  DeleteModalContent,
  RemoveModalContent
} from "./modals/ContainerModalsContent";

export const InsurancePolicyContainer = (props: { dealId: string }) => {
  const { dealId } = props;
  const { getAllResponse, getBatchHandler } = useInsurancePolicyViewHooks();
  const { selectedInsurancePolicy } = useInsurancePolicyHooks();

  const { currentFilter, setCurrentFilter, coveragesOptions, filteredItems } =
    useInsurancePolicyContainerHooks();
  const {
    getPaginateConfigurations,
    getPaginateConfigurationsResponse,
    configurationOptions
  } = serviceProviderListHooks();
  const {
    onEdit,
    isInsurancePolicyEditModalOpen,
    onToggleInsurancePolicyEditModal,

    isInsurancePolicyDeleteModalOpen,
    handleInsurancePolicyDeleteModalToggle,
    onInsurancePolicyDelete,

    isInsurancePolicyRemoveModalOpen,
    handleInsurancePolicyRemoveModalToggle,
    onInsuredDealRemove
  } = useInsurancePolicyModalsHooks();

  const filterMenuHook = useFilterMenuHook();
  const { clearFilters } = filterMenuHook;

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (auth.currentUser) {
        getPaginateConfigurations(
          {
            limit: 300
          },
          true
        );
      }
    },
    deps: []
  });

  useEffect(() => {
    dealId && getBatchHandler({ dealId });
  }, [dealId]);

  const handleClearClick = () => {
    clearFilters();
    setCurrentFilter({
      ...currentFilter,
      domainConfigurations: [],
      statuses: [],
      coverageTypes: []
    });
  };

  const isLoading =
    getPaginateConfigurationsResponse.isFetching || getAllResponse.isFetching;
  const isPropertyInsurance =
    selectedInsurancePolicy?.entityType === InsurancePolicyEntityType.Property;
  return (
    <>
      <InsurancePolicyContainerHeader dealId={dealId} />
      <Flex minWidth="max-content" alignItems="center" gap={2} mt={2}>
        <FilterMenuOptions
          customKey={"domainFilter"}
          maxHeight={"300px"}
          scrollHeight={"300px"}
          filterMenuHook={filterMenuHook}
          title={"Service Domain"}
          options={configurationOptions}
          showSearchBar={true}
          onChange={(items: string[]) => {
            setCurrentFilter({
              ...currentFilter,
              domainConfigurations: items
            });
          }}
          selected={currentFilter?.domainConfigurations}
          showClearAll
        />
        <FilterMenuOptions
          customKey={"statusFilter"}
          maxHeight={"300px"}
          scrollHeight={"300px"}
          filterMenuHook={filterMenuHook}
          title={"Service Provider Status"}
          options={statusOptions}
          showSearchBar={true}
          onChange={(items: string[]) => {
            setCurrentFilter({
              ...currentFilter,
              statuses: items
            });
          }}
          selected={currentFilter?.statuses}
          showClearAll
        />
        <FilterMenuOptions
          customKey={"coverageTypeFilter"}
          maxHeight={"300px"}
          scrollHeight={"300px"}
          filterMenuHook={filterMenuHook}
          title={"Coverage Type"}
          options={coveragesOptions}
          showSearchBar={true}
          onChange={(items: string[]) => {
            setCurrentFilter({
              ...currentFilter,
              coverageTypes: items
            });
          }}
          selected={currentFilter?.coverageTypes}
          showClearAll
        />
        <Button
          mr={1}
          background={"transparent"}
          fontSize={14}
          fontWeight={"normal"}
          onClick={handleClearClick}
          isDisabled={
            isEmpty(currentFilter?.domainConfigurations) &&
            isEmpty(currentFilter?.statuses) &&
            isEmpty(currentFilter?.coverageTypes)
          }
        >
          Clear filters
        </Button>
      </Flex>
      <Flex>
        <DealInsurancePolicyCards
          onEdit={onEdit}
          onRemove={handleInsurancePolicyRemoveModalToggle}
          onDelete={handleInsurancePolicyDeleteModalToggle}
          insurancePolicyViews={filteredItems}
          isLoading={isLoading}
        />
      </Flex>
      {isPropertyInsurance && (
        <PropertyInsurancePolicyModal
          showDomainStep={false}
          isOpen={isInsurancePolicyEditModalOpen}
          onClose={onToggleInsurancePolicyEditModal}
          dealId={dealId}
        />
      )}
      <ModalContainer
        isShow={isInsurancePolicyDeleteModalOpen}
        onCloseModal={handleInsurancePolicyDeleteModalToggle}
        header="Deleting an Insurance Policy"
        submit={{ onConfirm: onInsurancePolicyDelete }}
        body={<DeleteModalContent />}
      />
      <ModalContainer
        isShow={isInsurancePolicyRemoveModalOpen}
        onCloseModal={handleInsurancePolicyRemoveModalToggle}
        header="Removing an Insurance Policy"
        submit={{ onConfirm: () => onInsuredDealRemove({ dealId }) }}
        body={<RemoveModalContent dealId={dealId} />}
      />
    </>
  );
};
